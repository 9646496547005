import { $, React, Axios, validator, ImageUploader, Modal, Select, dt } from '../../../Headers';
// import Header from '../../Header'


export default class CreateJournals extends React.Component {
    constructor() {
        super();
        this.state = {
            JournalsData: [],
            formAction: "ADD",
            journal_id: "",
            jd_id:"",
            modelIsOpenForSubmit: false,
            modelIsOpenForInactive: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            articleType: '',
            editRecordId: '',
            server: '',
            articleTypeforvalid: '',
            journal: '',
            selectedjournal: "",
            selectedjournalforvalid: "",
        }
       
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let journal = this.state.selectedjournalforvalid;
        let aims_scope = this.aims_scope.value;
        let apc = this.apc.value;
        let colour_charges = this.colour_charges.value;
        let page_charges = this.page_charges.value;
        let submission_fees = this.submission_fees.value;
        // //console.log(journal);
        //article name

        if(validator.isEmpty(journal))
        {
            formIsValid = false;
            errors["journal"] = "Please Select Journal";
        }
        if (validator.isEmpty(aims_scope, ['en-GB'], { ignore: " " })) {
            // if (!apc.match(/[a-zA-Z0-9][():,./]/)) {
            formIsValid = false;
            errors["aims_scope"] = "Only letters";
            // }
        }
        if(!validator.isLength(aims_scope, { max: 1000 }))
        {
            formIsValid = false;
            errors["aims_scope"] = "Max 1000 Word";
        }
        if(!validator.isLength(apc, { max: 1000 }))
        {
            formIsValid = false;
            errors["apc"] = "Max 1000 Word";
        }
        if(!validator.isLength(colour_charges, { max: 1000 }))
        {
            formIsValid = false;
            errors["colour_charges"] = "Max 1000 Word";
        }
        if(!validator.isLength(page_charges, { max: 1000 }))
        {
            formIsValid = false;
            errors["page_charges"] = "Max 1000 Word";
        }
        if(!validator.isLength(submission_fees, { max: 1000 }))
        {
            formIsValid = false;
            errors["submission_fees"] = "Max 1000 Word";
        }
        if (!validator.isAlphanumeric(apc, ['en-GB'], { ignore: " " })) {
            if (!apc.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["apc"] = "Only letters";
            }
        }
        if (!validator.isAlphanumeric(colour_charges, ['en-GB'], { ignore: " " })) {
            if (!colour_charges.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["colour_charges"] = "Only letters";
            }
        }
        if (!validator.isAlphanumeric(page_charges, ['en-GB'], { ignore: " " })) {
            if (!page_charges.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["page_charges"] = "Only letters";
            }
        }
        if (!validator.isAlphanumeric(submission_fees, ['en-GB'], { ignore: " " })) {
            if (!submission_fees.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["submission_fees"] = "Only letters";
            }
        }
       
        this.setState({ errors: errors });
        return formIsValid;
    }

    EditJournalDetailsData = (JournalData) => {
        this.setState({ formAction: "EDIT" });
        this.setState({ jd_id: JournalData.jd_id });
        //console.log(JournalData.aims_scope);



        // document.getElementById("aims_scope").setAttribute('value', JournalData.aims_scope);
        document.getElementById("aims_scope").innerHTML = JournalData.aims_scope;
        document.getElementById("apc").innerHTML = JournalData.apc;
        document.getElementById("colour_charges").innerHTML = JournalData.colour_charges;
        document.getElementById("page_charges").innerHTML = JournalData.page_charges;
        document.getElementById("submission_fees").innerHTML = JournalData.submission_fees;
        // document.getElementById("journals_description").setAttribute('value', JournalData.description);
        // document.getElementById("journals_subject").setAttribute('value', JournalData.subject_id,'label',JournalData.subject_name);
        this.setState({ selectedjournal: { value: JournalData.journal_id, label: JournalData.journal_name } });
        // document.getElementById("DGDescription").setAttribute('value', ArticleData.dg_description);

        // To set the existing values into the form...
        //this.setState({selectedDestinations:JSON.parse(ExistingCombo.dg_destinations)});
    }
    handlechangejournal = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedjournal: selectedvalue,selectedjournalforvalid:"selected" })
        // this.setState({ selectedjournalforvalid: "selected" })
    }

    ResetForm = () => {
        this.setState({ formAction: 'ADD' });
        document.getElementById("aims_scope").innerHTML="";
        document.getElementById("apc").innerHTML="";
        document.getElementById("colour_charges").innerHTML="";
        document.getElementById("page_charges").innerHTML="";
        document.getElementById("submission_fees").innerHTML="";
        this.setState({selectedjournal:""});
    }

    SubmitJournals = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false });
        // //console.log(this.state.selectedoption);
        if (this.state.formAction == "ADD") {
            Axios.post('//' + this.state.server + '/controller/AddJournalsDetailsController.php',
                {
                    journal_id: this.state.selectedjournal.value,
                    aims_scope: this.aims_scope.value,
                    apc: this.apc.value,
                    page_charges: this.page_charges.value,
                    colour_charges: this.colour_charges.value,
                    submission_fees: this.submission_fees.value,
                }
            )
                .then(function (res) {
                    //console.log(res);
                    if (res.data.Success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    // //console.log(res)
                    // //console.log(res.data.value.Msg)
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
        }
        else if (this.state.formAction == "EDIT") {
            Axios.put('//' + this.state.server + '/controller/EditJournalDetailsController.php',
                {
                    jd_id:this.state.jd_id,
                    journal_id: this.state.selectedjournal.value,
                    aims_scope: this.aims_scope.value,
                    apc: this.apc.value,
                    page_charges: this.page_charges.value,
                    colour_charges: this.colour_charges.value,
                    submission_fees: this.submission_fees.value,
                })
                .then(function (res) {
                    if (res.data.success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
            this.setState({ formAction: "ADD" });
            this.setState({ articleid: "" });
        }
    }

    // InactiveArticle = () => {
    //     this.setState({ modelIsOpenForInactive: false });
    //     // alert(ArticleConfigToInactive);
    //     // event.preventDefault();
    //     // event.persist();
    //     Axios.post('//' + this.state.server + '/controller/InactiveJournalController.php',
    //         {
    //             journal_id: this.state.journal_id,
    //         })
    //         .then(function (res) {
    //             if (res.data.success == 1) {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //                 //console.log(res);
    //             }
    //             else {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //                 //console.log(res);
    //             }
    //         }
    //             .bind(this))
    //         .catch(function (error) {
    //             //console.log(error);
    //         });
    // };

    ToOpenModel = (event, button, idForInactive) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
            this.setState({
                modelIsOpenForSubmit: true
            });
            }
        }
        else if (button == "inactive") {
            this.setState({
                modelIsOpenForInactive: true,
                journal_id: idForInactive
            })
        }
    }

    componentDidMount() {
        const server = window.$name;
        ////console.log(server);
        this.setState({ server: server })
        // //console.log(this.state.formAction);

        Axios.all(
            [
                Axios.post("//" + window.$name + "/controller/GetJournalsDetailsForTableController.php",
                    {

                    }),
                Axios.post('//' + window.$name + '/controller/GetJournalsController.php'),
            ])
            .then((res) => {
                this.setState({ JournalsData: res[0].data });
                this.setState({ journal: res[1].data })
                //console.log(this.state.JournalsData);
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
            })
            .catch((error) => {
                //console.log(error[0]);
                // //console.log(error[1]);
            });

        // //console.log(res)
        // this.setState({name:res.data})
    }
    render() {

        return (
            <>
                <section class="content ">
                    <div class="container d-flex justify-content-center">
                        <div className="col-6" id="ss-sad-login-007">
                            <div id="ss-sad-login-008" class="card-header text-left">
                                <div class="ss-sad-login-009" >Journal Details</div>
                            </div>
                            <div className="container">
                                <form onSubmit={(event) => this.ToOpenModel(event, "submit", "")} onReset={this.ResetForm}>
                                    {/* <form onSubmit={this.SubmitJournals} onReset={this.ResetForm}> */}
                                    <center>
                                        <div className="form">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Article Name</label> */}
                                                        <Select
                                                            onChange={this.handlechangejournal}
                                                            options={this.state.journal}
                                                            value={this.state.selectedjournal}
                                                            placeholder="Select journal"
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["journal"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Article Name</label> */}
                                                        <textarea id='aims_scope' type="text" name="aims_scope" ref={(val) => this.aims_scope = val} className="form-control" placeholder="Aims & Scope" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["aims_scope"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">

                                                        <textarea id='apc' type="text" name="apc" ref={(val) => this.apc = val} className="form-control" placeholder="APC" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["apc"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">

                                                        <textarea id='colour_charges' type="text" name="colour_charges" ref={(val) => this.colour_charges = val} className="form-control" placeholder="Colour Charges" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["colour_charges"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">

                                                        <textarea id='page_charges' type="text" name="page_charges" ref={(val) => this.page_charges = val} className="form-control" placeholder="Page Charges" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["page_charges"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        <textarea id='submission_fees' type="text" name="submission_fees" ref={(val) => this.submission_fees = val} className="form-control" placeholder="Submission Fees" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["submission_fees"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row d-flex justify-content-center">
                                                <button type="submit" id="ss-sad-login-014" className="btn btn-block col-3">Submit</button>
                                                <button type="reset" className="btn btn-block col-3" id="reset">Reset</button>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </center>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid mt-3">
                        <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Journal Name</th>
                                    <th>Aims & Scope</th>
                                    <th>APC</th>
                                    <th>Colour Charges</th>
                                    <th>Page Charges</th>
                                    <th>Submission Fees</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.JournalsData.map((data) =>
                                    <tr>
                                        <td></td>
                                        <td>{data.journal_name}</td>
                                        <td>{data.aims_scope}</td>
                                        <td>{data.apc}</td>
                                        <td>{data.colour_charges}</td>
                                        <td>{data.page_charges}</td>
                                        <td>{data.submission_fees}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button type="button" class="btn-lg  responsive_btn m-2" id="edit" title="Edit" value="Edit" onClick={() => this.EditJournalDetailsData(data)} ><i class="far fa-edit"></i></button>
                                                {/* <button type="button" class="btn-lg   responsive_btn m-2" title="Inactive" id="inactive" value="Inactive" onClick={(event) => this.ToOpenModel(event, "inactive", data.journal_id)} ><i class="far fa-eye-slash"></i></button> */}
                                                {/* <button type="button" class="btn-lg  responsive_btn m-2" title="Delete" id="delete" value="Delete" onClick={() => this.DeleteArticle(data.journal_id)} ><i class="far fa-trash-alt"></i></button> */}
                                            </div></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
                {/* </div> */}
                {/* <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <h2>Do you want to submit this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.SubmitJournals}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.SubmitJournals}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>

                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Remark</p>
                            {/* <p id="popup-content">{this.state.response}</p> */}
                            <p id="popup-content">{this.state.response}</p>
                        </div>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <h2>Do you want to inactive this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.InactiveArticle}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForInactive: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
            </>
        );


    }

}