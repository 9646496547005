import { useState } from "react";
import { NavLink } from "react-router-dom";
import { React, Link } from "../../../Headers";
import "../../../js/headerDropdown";

const AppHeader = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg sort_01">
        <Link className="navbar-brand" to="/home">
          <img src={"../../uploads/logo-white-text.png"} />
        </Link>
        <button
          className="navbar-toggler bar-icon"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          onClick={() => {
            setCollapsed(false);
          }}
          aria-label="Toggle navigation"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <i className="fa-solid fa-bars"></i>
        </button>

        <div
          className={`collapse navbar-collapse ${collapsed ? "" : "show"}`}
          id="navbarSupportedContent"
          style={{ justifyContent: "end" }}
        >
          <ul className="navbar-nav">
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="sort-nav-active-menu"
                onClick={() => {
                  setCollapsed(true);
                }}
                to="/home"
              >
                Home <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link sort-nav-menu dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                Home
              </a>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to="/journalhome/home">
                    Journal
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/our_vision"
                onClick={toggleNavbar}
              >
                Our Vision
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/author_and_reviewer"
                onClick={toggleNavbar}
              >
                Authors and Reviewers{" "}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/peer_review_process"
                onClick={toggleNavbar}
              >
                Peer Review Process{" "}
              </NavLink>
            </li>
            {/* <li className="nav-item d-flex align-items-center">
        <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/sortsubscription">Subscription</NavLink>
      </li> */}
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/technology"
                onClick={toggleNavbar}
              >
                Technology
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/sortmanuscript"
                onClick={toggleNavbar}
              >
                Submit Manuscript
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link sort-nav-menu"
                activeClassName="nav-link sort-nav-active-menu"
                to="/author_form"
                onClick={toggleNavbar}
              >
                Author's Form
              </NavLink>
            </li>
            {/* <li className="nav-item d-flex align-items-center">
        <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/contact_form">Contact Us</NavLink>
      </li> */}
            {/* <li className="nav-item d-flex align-items-center">
        <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/author_register">Author Registration</NavLink>
      </li>
      <li className="nav-item d-flex align-items-center">
        <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/sortsollogin">Login</NavLink>
      </li> */}
            {/* <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" to="#">Action</a>
          <a className="dropdown-item" to="#">Another action</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" to="#">Something else here</a>
        </div>
      </li> */}
            {/* <li className="nav-item">
        <a className="nav-link disabled" to="#">Disabled</a>
      </li> */}
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> */}
        </div>
      </nav>
    </>
  );
};

export default AppHeader;
