import { NavLink } from 'react-router-dom'
import {React,Link} from '../../../Headers'

const AppHeader = () => {
 
  
    return (
      <>
       
        <nav className="navbar navbar-expand-lg sort_01" style={{ background: "#000000", position: "sticky", top: "0", zIndex: "1" }}>
          {/* <Link className="navbar-brand" to="/journalhome"><img src={"../../uploads/logo-white-text.png"} /></Link> */}
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: "start" }}>
            <ul class="navbar-nav">
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/home">Journals<span className="sr-only">(current)</span></NavLink>
              </li>
              {/* <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/sortmanuscript">Manuscript</NavLink>
              </li> */}
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/guideline">Guide for Authors</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/policy">OA Policy</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/copyright">Copyright Terms</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/licensing">Licensing</NavLink>
              </li>
              {/* <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/journalmanuscript">Upload Manuscript</NavLink>
              </li> */}
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu" activeClassName="nav-link sort-nav-active-menu" to="/journalhome/contactform">Contact Us</NavLink>
              </li>
              
              {/* <li class="dropdown nav-item" >
              <li><NavLink className="nav-link sort-nav-menu dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" to="#">Author Charges
              </NavLink>
                <ul role="menu" className="dropdown-menu">
                  <li><NavLink className="dropdown-item" to="/submissionfee">SUBMISSION FEE</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/apc">APC</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/pagecharge">PAGE CHARGES</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/colourcharge">COLOUR CHARGES</NavLink></li>
                </ul>
              </li>
              </li> */}
              {/* <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
              {/* <li className="nav-item">
        <a className="nav-link disabled" href="#">Disabled</a>
      </li> */}
            </ul>
            {/* <form className="form-inline my-2 my-lg-0">
      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> */}
          </div>
        </nav>
       
      </>
    )
  }
export default AppHeader



