import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.$name="3.109.33.135:3000/office/SORTSOL/backend";
window.$file="http://3.109.33.135:3000/office/SORTSOL/frontend/public/uploads/";
window.$pdf="http://3.109.33.135:3000/office/SORTSOL/backend/pdf_library/upload/";

window.$website="http://sortsol.in";

// window.$name="localhost:443/office/SORTSOL/backend";
// window.$file="http://localhost:443/office/SORTSOL/frontend/public/uploads/";
// window.$pdf="http://localhost:443/office/SORTSOL/backend/pdf_library/upload/";

// window.$name="localhost:443/office/SORTSOL/backend";
// window.$file="http://localhost:443/office/SORTSOL/frontend/public/uploads/";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
