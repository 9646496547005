// ---Packages---
export { default as React } from "react";
export { default as ReactDOM } from "react-dom";
export * from "react-router-dom";
export { default as Axios } from "axios";
export { default as $ } from "jquery";
export { default as Select } from "react-select";
export { default as ImageUploader } from "react-images-upload";
export { default as validator } from "validator";
export { default as Modal } from "react-modal";
export { default as dt } from "datatables.net-responsive-dt";

// ---CSS--
export * from "./css/inline.css";
export * from "./css/adminlte.min.css";
export * from "./css/journal_card.css";
export * from "./css/sort.css";
export * from "./css/website.css";
export * from "./css/mobile.css";
// export * from './css/sort_web.css'

export const API_IMG = "//localhost/SortSol_web/SORTSOL/backend/FormExcel";

// --Custom_JS---
// export * from './components/main';
