import { React, Axios, Link } from "../../../Headers"

export default class SuperAdminLogOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      server: ""
    }
  }
  componentDidMount() {
    const server = window.$name;
    //console.log(server);
    this.setState({ server: server })

    Axios.get('//' + window.$name + '/controller/LogoutController.php')
    localStorage.clear();
  }
  render() {
    return (
      <body >
      <div style={{ backgroundImage: "url('../../uploads/logo-black.png')" }} id="ss-sad-login-001"></div>
      <div className="container-fluid">
        <div className="row" id="ss-sad-login-002">
          <div className="col-7" id="ss-sad-login-003">
            <img src={"../../uploads/banner1.png"} className="ss-sad-login-004" />
            <img src={"../../uploads/banner2.png"} className="ss-sad-login-004" />
            <div id="ss-sad-login-005" style={{ backgroundImage: "url('../../uploads/logo-with-text-white.png')" }}></div>
          </div>

          <div class="login-box" id="ss-sad-login-006">
            <div class="card-header text-center">
              <div id="ss-sad-login-007" class="card" >
                <div id="ss-sad-login-008" class="card-header text-left"></div>
                <div class="login-logo ss-sad-login-009">
                  <p><b>User</b></p>
                  {/* <h1>You're Successfully Logged Out!!!</h1> */}
                </div>
                <div className="row">
                  <div class="col-12">
                    <div class="card bg-danger">
                      <div class="card-body text-center" style={{ height: "12vh" }}>
                        You're Successfully Logged Out!!!
                      </div>
                      {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                  </div>
                </div>
                <div class="card-header text-center ss-sad-login-009">
                  <p class="h5">Click Here to Login Again!!!</p>
                </div>
               
                <div className="text-center ">
                  <Link className="btn ui-btn ss-sad-login-009" to="super_login">Log In</Link>
                </div> 
              </div>
            </div>
          </div>

        </div>
      </div>

    </body>
    )
  }
}