import { React, Link } from '../../../Headers'

//  for inside wrapper... In AppContent.js set .content-wrapper height to 92vh and for fixed footer height will be 85vh...
import './FooterInsideWrapper.css';

const AppFooter = () => {
  return (
    <>
      {/* <footer class="main-footer"> */}

      {/* for inside wrapper */}
      <div>
        <footer id="footer" style={{ backgroundColor: '#000000' }}>
          {/*Footer*/}
          <div className="footer-widget" style={{ backgroundColor: '#000000' }}>
            <div className="container-fluid">
              <div className="row" style={{ backgroundColor: '#000000' }}>
                <div className="col-sm-6" style={{ padding: "35px" }}>
                  <div id="footer-logo">
                    <a class="navbar-brand" href="#"><img src={"../../uploads/logo-white-text.png"} /></a>
                  </div>
                  <p id="footer-sub-heading">"Publication" is a technical term in legal contexts and especially important in copyright legislation. An author of a work generally is the initial owner of the copyright on the work. One of the copyrights granted to the author of a work is the exclusive right to publish the work.</p>
                  <p id="footer-copyright">©SORTSOL PTY LTD 2020. All rights reserved</p>
                </div>
                <div className="col-sm-6" style={{ padding: "35px" }}>
                  <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="col-4">
                      {/* <div className="single-widget"> */}
                      <p id="footer-title">Company</p>
                      <ul style={{ listStyle: "none", display: "flex", flexDirection: "column", padding: "0px" }}>
                        <li><a class="footer-link" href="">About</a></li>
                        <li><a class="footer-link" href="">Authors</a></li>
                        <li><a class="footer-link" href="">Subscription</a></li>
                        <li><a class="footer-link" href="">Advertisement</a></li>
                      </ul>
                      {/* </div> */}
                    </div>
                    <div className="col-4">
                      {/* <div className="single-widget"> */}
                      <p id="footer-title">Region</p>
                      <ul style={{ listStyle: "none", display: "flex", flexDirection: "column", padding: "0px" }}>
                        <li><a class="footer-link" href="">Indonesia</a></li>
                        <li><a class="footer-link" href="">Singapore</a></li>
                        <li><a class="footer-link" href="">Hongkong</a></li>
                        <li><a class="footer-link" href="">Canada</a></li>
                      </ul>
                      {/* </div> */}
                    </div>
                    <div className="col-4">
                      {/* <div className="single-widget"> */}
                      <p id="footer-title">Help</p>
                      <ul style={{ listStyle: "none", display: "flex", flexDirection: "column", padding: "0px" }}>
                        <li><a class="footer-link" href="">Help center</a></li>
                        <li><a class="footer-link" href="">Contact support</a></li>
                        <li><a class="footer-link" href="">Instructions</a></li>
                        <li><a class="footer-link" href="">How it works</a></li>
                      </ul>
                      {/* </div> */}
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-6">
                      <Link><img style={{ marginLeft: "20px" }} src={"../../uploads/Facebook.png"} /></Link>
                      <Link><img style={{ marginLeft: "20px" }} src={"../../uploads/Twitter.png"} /></Link>
                      <Link><img style={{ marginLeft: "20px" }} src={"../../uploads/Instagram.png"} /></Link>
                      <Link><img style={{ marginLeft: "20px" }} src={"../../uploads/LinkedIn.png"} /></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
             <a id="scrollUp" href="#top" style={{position: "fixed", zIndex: "2147483647"}}><i class="fa fa-angle-up"></i></a>
          </div>
         </footer>
         </div>
     
     
  </>
  )
}

export default React.memo(AppFooter)
