// import React from 'react'

import Dashboard from './components/Super_Dashboard'
import CreateUser from './components/SuperCreateUser'
import SuperInstituteRegister from './components/SuperInstituteRegister'
// import CreateJournals from './components/CreateJournals'
import Country from './components/CountryForm'
import CreateSubject from './components/CreateSubject'
import UserInfo from './components/UserInfo'
import SuperAdmin from './components/SuperAdmin'
import SuperAdminApprove from './components/SuperAdminApprove'
import SuperAdminHistory from './components/SuperAdminHistory'
import SuperAdminReject from './components/SuperAdminReject'
import SuperArticle from './components/SuperArticle'
import SuperAllArticle from './components/SuperAllArticle'
import SuperArticleDetails from './components/SuperArticleDetails'
// import EditorialBoard from './components/EditorialBoard'
import Contact_us from './components/Contact_us'

const routes = [

  { path: '/super_dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/super_create_user', exact: true, name: 'CreateUser', component: CreateUser },
  { path: '/super_institute_register', exact: true, name: 'InstituteRegister', component: SuperInstituteRegister},
  // { path: '/create_journals', exact: true, name: 'CreateJournals', component: CreateJournals },
  { path: '/country', exact: true, name: 'Country', component: Country },
  { path: '/create_subject', exact: true, name: 'CreateSubject', component: CreateSubject },
  { path: '/view_user', exact: true, name: 'UserInfo', component:UserInfo},
  { path:'/manuscript/superadmin/pending', exact: true, name: 'SuperAdmin', component:SuperAdmin},
  { path:'/superarticle/:article_id', exact: true, name: 'SuperArticle', component:SuperArticle},
  { path:'/manuscript/superadmin/approve', exact: true, name: 'SuperAdminApprove', component:SuperAdminApprove},
  { path:'/manuscript/superadmin/reject', exact: true, name: 'SuperAdminReject', component:SuperAdminReject},
  { path:'/manuscript/superadmin/all', exact: true, name: 'SuperAdminReject', component:SuperAllArticle},
  { path:'/superarticlehistory/:article_id/:status', exact: true, name: 'SuperAdminHistory', component:SuperAdminHistory},
  // { path:'/editorialboard', exact: true, name: 'EditorialBoard', component:EditorialBoard},
  { path: '/superarticledetails/:article_id', exact: true, name:'SuperArticleDetails', component:SuperArticleDetails},
  { path: '/contact_us', exact: true,name:'ContactUs', component:Contact_us},
]

export default routes
