import { React, Axios } from '../../../Headers';
import JournalNav from "./JournalNav";
export default class JournalCurrentIssue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      response: '',
      errors: {},
      server: '',
      upload: "",
      filetype: "",
      filesize: "",
      filename: "",
      // ArticleData: "",
      ArticleData: [],
      Journal_id: "",
      JournalName: "",
      JournalData: "",

    }
  }

  componentDidMount() {
    const server = window.$name;
    // //console.log(server);
    this.setState({ server: server })

    const journal_id = this.props.match.params.journal_id;
    this.setState({Journal_id: journal_id})

    Axios.all([
      Axios.post('//' + window.$name + '/controller/GetArticlePublishController.php',
    {
      journal_id:journal_id,
    }),
    Axios.post('//' + window.$name + '/controller/GetJournalByIdController.php',
    {
        journal_id: journal_id
    })
  ])
      .then((res) => {
        // //console.log(res);
        // //console.log(res.data[0]);
        //console.log(res[0].data[0]);
        // this.setState({ ArticleData: res.data[0] })
        this.setState({ ArticleData: res[0].data })
        this.setState({ JournalData: res[1].data[0] });
        this.setState({ JournalName: res[1].data[0].journal_name})
        // this.setState({ file: "//localhost/office/SORTSOL/frontend/public/uploads/" + res.data[0].upload });
        this.setState({ file: window.$file });
      })
      .catch((err) => {
        //console.log(err);
      })
  }
  render() {
    return (
      <>
      {/* <JournalNav JournalName={this.state.JournalName} JournalId={this.state.Journal_id} /> */}
      <div className="row mb-5" style={{ width: "100%" }}>
      <div className="col-4" style={{ padding: "35px" }}>
          <div className="sort-card">
            {/* <div class="hover_color_bubble"></div> */}
            {/* <div class="so_top_icon" style={{width:"100%",height:"45%",borderRadius:"15px 15px 0 0",backgroundImage:"url(../../uploads/journals/"+data.journal_image+")",backgroundSize:"cover"}}> */}
            <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + this.state.JournalData.journal_image + ")", backgroundSize: "cover" }}>
            </div>
            <div style={{ padding: "25px", height: "55%" }}>
              <div class="sort-card-title">
                {/* {data.journal_name} */}
                {this.state.JournalData.journal_name}
              </div>

              <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                {/* {data.journal_description} */}
                {this.state.JournalData.journal_description}
              </div>
              {/* <button  type="button">
              <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
              <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
            </div>
          </div>
        </div>

        <div className="col-8" style={{ padding: "35px 0 35px 35px" }}>
          <div>
            <p id="home-heading" style={{fontSize:"35px"}}>Current Issues</p>
            <div style={{ borderTop: "2px solid black", width: "10%", marginBottom: "20px" }}></div>

            {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
            <button id="journal-view-btn">Learn More</button>
           </div> */}
          </div>
          {this.state.ArticleData.map((data) =>
          <div className="container-fluid" style={{ border: "1px solid black", minHeight: "30%", maxHeight: "fit-content",marginBottom: "1rem" }}>
           
              <div style={{ padding: "25px", height: "55%" }}>
                <div class="sort-card-title">

                  {data.manuscript_title}
                </div>

                <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>

                  {data.abstract}
                </div>

                <div className="row">
                  <div className="col-6" style={{ paddingTop: "5px" }}>Last Updated on {data.last_update}</div>
                  <div className="col-6 d-flex justify-content-end">
                    <button className="sort-download-btn" type="button">

                      <a target="_blank" href={this.state.file + data.upload} ><i class="fas fa-arrow-down"></i><span> </span>
                        Download</a></button>

                  </div>
                </div>
              </div>
              </div>
            )}
          </div>
        {/* </div> */}
      </div>
      </>
    );
  }
}
