import { React, Axios, Link, Redirect, Modal, $, dt } from "../../../Headers"
// import '../layout/FooterInsideWrapper.css'

const loading = (
    <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
)

export default class CreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            manuscript_title: "",
            org_name: "",
            reviewer: "",
            abstract: "",
            email: "",
            firstname: "",
            lastname: "",
            date: "",
            file: "",
            fileType: "",
            fileForPDF: "",
            fileForDOC: "",
            modelIsOpenForAssign: false,
            article_id: "",
            server: "",
            modelIsOpenForResponse: false,
            modelIsOpenForDetails: false,
            modelIsOpenForTry: false,
            modelIsOpenForSuccessResponse: false,
            modelIsOpenForPreloader:false,
            redirect: false,
            selectedReviewerId: '',
            HistoryData: [],
            doc_type: "",
            pdfshow: "none",
            docshow: "none",
            preloader: "block",
            price:"",
        }

    }

    doc_show = () => {
        if (this.state.fileType == "pdf") {
            this.setState({ pdfshow: "contents", fileForPDF: window.$file + this.state.file, preloader: "none" })
        }
        if (this.state.fileType == "doc" || this.state.fileType == "docx") {
            this.setState({ docshow: "contents", fileForDOC: window.$file + this.state.file, preloader: "none" })
        }
    }

    handlechange = (selected) => {
        // //console.log(selected.value);
        this.setState({ selectedReviewerId: selected });
    }
    comment = (event) => {
        event.preventDefault();
        event.persist();

        Axios.post("//" + this.state.server + "/controller/CommentController.php",
            {
                user_id: localStorage.getItem('user'),
                remark: this.remark.value,
                article_id: this.state.article_id,
                role_id: localStorage.getItem('role')
            }
        )
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForComment: false,
                        modelIsOpenForResponse: true
                    })

                    // alert(res.data.Msg);
                    // this.sendmail();
                    // window.location.reload();
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForComment: false,
                        modelIsOpenForResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    }


    componentDidMount() {
        
     
        var i_frame = document.getElementById('myiframe');
        if (i_frame) {
            var gview = document.getElementsByClassName("ndfHFb-c4YZDc-qbOKL-OEVmcd");
            // alert(gview);
            //console.log(gview);
            document.getElementById('myiframe').onload = function () {
                return function () {
                    alert("Done.");
                    var gview = document.getElementsByClassName("ndfHFb-c4YZDc-qbOKL-OEVmcd");
                    //console.log(gview);
                    // this.setState({docshow:"contents"});
                    // this.state.docshow = "contents";
                    // document.getElementById('parent').style.display="contents";
                }
            }();
        }
        else {

        }
        //console.log(i_frame.contentWindow.document);
        // let xhr = XMLHttpRequest();
        // //console.log(xhr);
        document.getElementById('myiframe').onload = function () {
            return function () {
                //console.log("Done.");
                // this.setState({docshow:"contents"});
                // this.state.docshow = "contents";
                // document.getElementById('parent').style.display="contents";
            }
        }();

        //console.log(window.parent.location);
        
        const server = window.$name;
        // //console.log(server);
        const article = this.props.match.params.article_id;
        //console.log(article);
        this.setState({ article_id: article });
        //console.log(localStorage.getItem('user'));
        //console.log(localStorage.getItem('role'));
        this.setState({ server: server })

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetArticleController.php', {
                article: article,
                status: 1,
            }),
            Axios.post('//' + window.$name + '/controller/GetArticleHistoryController.php',
                {
                    article: article,
                }),
                Axios.post('//'+window.$name+'/controller/GetArticleConfigController.php',
                {
                    type:'INITIAL',
                })
        ])
            .then((res) => {
                //console.log(res[0]);
                //console.log(res[1]);
                // //console.log(res[2]);
                this.setState({ price: res[2].data[0].price})
                // //console.log(res.data.value.Article[0].manuscript_title);
                this.setState({ manuscript_title: res[0].data.value.Article[0].manuscript_title });
                this.setState({ org_name: res[0].data.value.Article[0].organization });
                this.setState({ abstract: res[0].data.value.Article[0].abstract });
                this.setState({ email: res[0].data.value.Article[0].email });
                this.setState({ firstname: res[0].data.value.Article[0].firstname });
                this.setState({ lastname: res[0].data.value.Article[0].lastname });
                this.setState({ date: res[0].data.value.Article[0].entrydate });
                // this.setState({ doc_type: res[0].data.value.Article[0].upload_type })
                // this.setState({ file: res.data.value.Article[0].upload });
                // this.setState({ file: "//" + window.$file + "/office/SORTSOL/frontend/public/uploads/" + res[0].data.value.Article[0].upload });
                this.setState({ file:  res[0].data.value.Article[0].upload });
                this.setState({ fileType: res[0].data.value.Article[0].upload_type });
                // //console.log(this.state.fileType);
                // this.setState({ fileForDOC: "//3.109.142.119/office/SORTSOL/frontend/public/uploads/16_upload.docx"  });

                // this.setState({ fileForPDF: "//3.109.142.119/office/SORTSOL/frontend/public/uploads/10_upload.pdf"  });

                this.setState({ reviewer: res[0].data.value.Reviewer })
                this.setState({ HistoryData: res[1].data })
                //console.log(res[0].data.value.Reviewer);
                //console.log(res[1].data)
                this.doc_show();
                // this.setState({role: res.data})
                // this.doc_show();
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
            })
            .catch((err) => {
                //console.log(err);
            })
    }
    MakePayment=()=>{
        Axios.post('//' + this.state.server + '/controller/TransactionController.php',
        {
            article_id:this.state.article_id,
            unique_user_id:localStorage.getItem('unique_user_id'),
            price:this.state.price,
            payment_type:"INITIAL",
        }
        )
        .then((res)=>{
            //console.log(res);
            if(res.data.Success==1)
            {
                // this.setState({
                //     response: res.data.Msg,
                //     modelIsOpenForResponse: true,
                //     // redirect:true,
                // })
                window.location.assign("//" + window.$name + "/cashfree/checkout/index.php?a_id=" + this.state.article_id +"&t_id="+res.data.tranx_id);
            }
            else {
                // this.setState({
                //     response: res.data.Msg,
                //     modelIsOpenForResponse: true
                // })
                // alert(res.data.Msg);
                // window.location.reload();
                //console.log(res);

            }
                
        })
        .catch((err)=>{
            //console.log(err);
        })
    }
    
    Redirecttohome = () => {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to={'/manuscript/author/p_pending'} />
        }
        return (
            <>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <Link to={'/manuscript/author/p_pending'}><div id="ss-uad-article-03" style={{ backgroundImage: "url('../../uploads/back.png')", borderRadius: "50px" }}></div></Link>
                            {/* <h1 className="" type="button" onClick={this.ForDetails} style={{ textAlign: 'left' }} id="card_header">{this.state.manuscript_title}</h1> */}
                            <div id="ss-uad-article-04">
                                <p id="ss-uad-article-05" type="button" onClick={this.ForDetails}>{this.state.manuscript_title}</p>
                                <p id="ss-uad-article-06">{this.state.org_name}</p>
                            </div>
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {/* <button id="ss-uad-article-08" className="btn btn-primary mr-2" onClick={() => this.setState({ modelIsOpenForReject: true })}>Reject</button> */}
                            <button id="ss-uad-article-09" className="btn btn-danger" onClick={() => this.MakePayment()}>Pay</button>
                        </div>
                    </div>
                    <div>
                        <div className="container-fluid" style={{ padding: "0 39px" }} >

                            <div className="row" style={{ height: "72vh" }}>
                                <div className="col-9" id="ss-uad-article-17">

                                    {/* <div className="mt-2 p-2" id="ss-uad-article-13">
                                        <button id="ss-uad-article-14" className="btn btn-warning" onClick={() => this.setState({ modelIsOpenForComment: true })}><i class="fas fa-comment-alt" id="ss-uad-article-15"></i></button>
                                    </div> */}
                                    <div style={{ display: this.state.pdfshow }}>
                                        <iframe src={this.state.fileForPDF} height="100%" width="100%" frameBorder="0" style={{ borderRadius: "12px" }}></iframe>
                                    </div>
                                    <div id="parent" style={{ display: this.state.docshow }}>
                                        <iframe id="myiframe" src={"https://docs.google.com/gview?url=" + this.state.fileForDOC + "&embedded=true"} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%">
                                            {/* <iframe style={{display:"none",src:"none"}} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%"> */}
                                        </iframe>
                                    </div>

                                </div>

                                <div className="col-3" style={{ borderRadius: "12px", padding: "0px", background: "#F4F5F7", height: "inherit" }} >
                                    <div className="card" style={{ borderRadius: "12px 12px 0 0", margin: "0" }}>
                                        <div className="card-header" id="ss-uad-article-10">
                                            <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i>
                                            <span id="ss-uad-article-12">Comments</span>
                                        </div>
                                    </div>
                                    <div id="ss-uad-article-18" style={{ height: "90%", overflowY: "auto", padding: "2vh 2vh 0 2vh" }}>
                                        {this.state.HistoryData.map((data, i) =>
                                            <div class="card" style={{ borderRadius: "12px" }}>
                                                <div class="card-header d-flex" style={{ padding: ".8vw" }}>
                                                    {/* <div className="row"> */}
                                                        <div className="ss-uad-article-16" style={{ paddingRight: "2px",width:"-webkit-fill-available"}}>
                                                            {/* <span className="ss-uad-article-16"><i class="fas fa-comment-alt mr-2"></i></span> */}
                                                            <span className="ss-uad-article-16" style={{paddingRight:"8px",color:"#6c757d"}}>{i + 1}.</span>
                                                            {/* <span className="ss-uad-article-16">by</span> */}
                                                            {/* {data.firstname + " "}{data.lastname} */}
                                                            {data.user_name}
                                                        </div>
                                                        <div className="col ss-uad-article-16" style={{fontSize:"xx-small"}}>
                                                            {data.action_taken}
                                                            {/* {new Date(data.action_taken).getDate() + "/" + new Date(data.action_taken).getMonth() + "/" + new Date(data.action_taken).getFullYear() + " " + new Date(data.action_taken).toLocaleTimeString()} */}
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                                <div class="card-body" style={{padding:"1rem"}}>
                                                    {/* <h5 class="card-title">Special title treatment</h5> */}
                                                    <p class="card-text" id="ss-uad-article-17">{data.remark}</p>
                                                    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modelIsOpenForPreloader} className="popup-modal-preloader" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForPreloader: false })}>
                    {loading}
                    </Modal>
               
                <Modal isOpen={this.state.modelIsOpenForSuccessResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSuccessResponse: false })}>
                    <span class="closed" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={this.Redirecttohome}>&times;</span>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className="popup-circle-check">
                                <i class="fas fa-check" style={{ color: "#6FCF97", fontSize: "x-large" }}></i>
                            </div>
                        </div>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Success</p>
                            <p id="popup-content">{this.state.response}</p>
                            {/* <p id="popup-content">Sucessfully approved and assigined to a reviewer and an email alert has been sent to other users.</p> */}
                        </div>
                    </div>

                </Modal>
               
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.Redirecttohome}>

                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Remark</p>
                            {/* <p id="popup-content">{this.state.response}</p> */}
                            <p id="popup-content">{this.state.response}</p>

                        </div>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={this.Redirecttohome}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>

                        </div>
                    </div>

                </Modal>
                
               
            </>
        );
    }
}