import { React, Link } from "../../../Headers";
import ScrollToTop from "react-scroll-to-top";
//  for inside wrapper... In AppContent.js set .content-wrapper height to 92vh and for fixed footer height will be 85vh...
import "./FooterInsideWrapper.css";
// import { NavLink } from "react-router-dom";

const AppFooter = () => {
  return (
    <>
      <div>
        <footer id="footer" style={{ backgroundColor: "#000000" }}>
          <div className="footer-widget" style={{ backgroundColor: "#000000" }}>
            <div className="container-fluid">
              <div className="row" style={{ backgroundColor: "#000000" }}>
                <div className="col-sm-6" style={{ padding: "35px" }}>
                  <div id="footer-logo">
                    <a class="navbar-brand" href="#">
                      <img src={"../../uploads/logo-white-text.png"} />
                    </a>
                  </div>
                  {/* <p id="footer-sub-heading">
                    "Publication" is a technical term in legal contexts and
                    especially important in copyright legislation. An author of
                    a work generally is the initial owner of the copyright on
                    the work. One of the copyrights granted to the author of a
                    work is the exclusive right to publish the work.
                  </p> */}
                  <p id="footer-copyright">
                    ©SORTSOL Enterprise. All rights reserved
                  </p>
                </div>
                <div className="col-sm-6">
                  <img
                    style={{ width: "25%", borderRadius: "10px",marginTop:"4%",marginLeft:"60%" }}
                    src={"../../uploads/make-in-india.png"}
                  />
                </div>
                {/* <div className="col-sm-4" style={{ padding: "50px",marginLeft:"-60px"}}> */}
                  {/* <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="col-4">
                      <p id="footer-title">Company</p>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <li>
                          <a className="nav-link sort-nav-menu" href="">
                            About
                          </a>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link sort-nav-menu"
                            activeClassName="sort-nav-active-menu"
                            to="/sortguideline"
                          >
                            Guidelines
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link sort-nav-menu"
                            activeClassName="sort-nav-active-menu"
                            to="/wkconference"
                          >
                            WKConference
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link sort-nav-menu"
                            activeClassName="sort-nav-active-menu"
                            to="/customerservices"
                          >
                            Customer Services
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="col-4">
                      <p id="footer-title">Region</p>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <li>
                          <a class="footer-link" href="">
                            Indonesia
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            Singapore
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            Hongkong
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            Canada
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-4">
                      <p id="footer-title">Help</p>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <li>
                          <a class="footer-link" href="">
                            Help center
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            Contact support
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            Instructions
                          </a>
                        </li>
                        <li>
                          <a class="footer-link" href="">
                            How it works
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  {/* <div className="row justify-content-end" >
                      <Link>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={"../../uploads/Facebook.png"}
                        />
                      </Link>
                      <Link>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={"../../uploads/Twitter.png"}
                        />
                      </Link>
                      <Link>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={"../../uploads/Instagram.png"}
                        />
                      </Link>
                      <Link>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={"../../uploads/LinkedIn.png"}
                        />
                      </Link>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div>
            <ScrollToTop smooth />
          </div>
        </footer>
      </div>
    </>
  );
};

export default React.memo(AppFooter);
