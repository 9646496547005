// import React from 'react'

import JournalHome from './components/JournalHome'
import JournalArchive from './components/JournalArchive'
import JournalCurrentIssue from './components/JournalCurrentIssue'
import JournalEditorialBoard from './components/JournalEditorialBoard'
import JournalEditorial from './components/JournalEditorial'
// import JournalList from './components/JournalList'
// import JournalArticle from './components/JournalArticle'
// import JournalManuscript from './components/JournalManuscript'
// import JournalJ from './components/JournalJ'
import ColourCharges from './components/ColourCharges'
import JournalAimScope from './components/JournalAimScope'
import JournalPolicy from './components/JournalPolicy'
import PageCharges from './components/PageCharges'
import JournalApc from './components/JournalApc'
import SubmissionFee from './components/SubmissionFee'
import ContactForm from './components/ContactForm';
import JournalCopyright from './components/JournalCopyright';
import JournalLicense from './components/JournalLicense';
import JournalSpecific from './components/JournalSpecific'
import JournalGuideline from './components/Guideline'


const routes = [

  { path: '/journalhome/home', exact: true, name: 'JournalHome', component: JournalHome},
  { path: '/journalhome/:journal_id/archive', exact:true,name:'JournalArchive',component: JournalArchive},
  { path: '/journalhome/:journal_id/currentissue', exact:true, name:'JournalCurrentIssue', component: JournalCurrentIssue},
  { path: '/journalhome/:journal_id/editorialboard', exact:true, name:'JournalEditorialBoard', component: JournalEditorialBoard},
 
  // { path: '/journallist', exact: true, name:'JournalList', component: JournalList},
  // { path: '/journalarticle', exact:true, name:'JournalArticle',component: JournalArticle},
  // { path: '/journalhome/journalmanuscript', exact:true, name:'JournalManuscript',component: JournalManuscript},
  // { path: '/journalj', exact:true, name:'JournalJ',component: JournalJ},

  { path: '/journalhome/guideline', exact:true, name:'Guideline', component:JournalGuideline},
  { path: '/journalhome/:journal_id/colourcharge', exact: true, name:'ColourCharges', component: ColourCharges},
  { path: '/journalhome/:journal_id/aimscope', exact:true, name:'JournalAimScope',component: JournalAimScope},
  { path: '/journalhome/policy', exact:true, name:'JournalPolicy',component: JournalPolicy},
  { path: '/journalhome/:journal_id/editorial', exact:true, name:'JournalEditorial',component: JournalEditorial},
  { path: '/journalhome/:journal_id/pagecharge', exact:true, name:'PageCharges',component: PageCharges},
  { path: '/journalhome/:journal_id/apc', exact:true, name:'JournalApc',component: JournalApc},
  { path: '/journalhome/:journal_id/submissionfee', exact:true, name:'SubmissionFee',component: SubmissionFee},
  { path: '/journalhome/:journal_id/journalspecific', exact:true,name:'JournalSpecfic',component: JournalSpecific},
  { path: '/journalhome/contactform', exact:true, name:'ContactForm',component: ContactForm},
  { path: '/journalhome/copyright', exact:true, name:'Copyright',component: JournalCopyright},
  { path: '/journalhome/licensing', exact:true, name:'Licensing',component:JournalLicense}
]

export default routes
