import { Link } from "../../../Headers";
import React from "react";
import ContactUs from "../../ContactUs";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mb-5 mobile-flex-reverse">
            <div className="col-lg-6 col-md-12 sort_04">
              <div>
                <p id="home-heading">
                  Fostering Excellence in Academic Publishing
                </p>
                <p id="home-subheading">
                  SortSol is a startup targeting to be a leading publisher
                  committed to advancing scholarly research and promoting
                  academic excellence across diverse disciplines. Our motive is
                  to take the academic research to a stage which it deserves, as
                  there is a big gap in the market in a authors demands and what
                  publishers are doing, we are here to bridge that gap and
                  foster the research with the best of our efforts.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src={"../../uploads/Frame 5.png"}
                class="sort_14"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6 col-md-12 sort_04">
              <img src={"../../uploads/vision.png"} class="sort_14" />
            </div>
            <div className="col-lg-6 col-md-12 sort_04">
              <div className="row justify-content-start sort_18">
                <p id="journal-title">Our Vision</p>
                <div class="sort_23"></div>
                <p id="prp-content" style={{ marginTop: "25px" }}>
                  At Sort Sol, our vision is to be at the forefront of academic
                  publishing, empowering researchers to share their discoveries,
                  insights, and innovations with the global community. We aim to
                  foster intellectual exchange, inspire new ideas, and
                  contribute to the advancement of knowledge in collaboration
                  with esteemed authors, editors, and reviewers.
                </p>
                <div className="row justify-content-start sort_21">
                  <Link to="/our_vision/" class="sort_30">
                    <button id="journal-view-btn">Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row mobile-flex-reverse">
            <div className="col-lg-6 col-md-12 sort_10">
              <div className="row justify-content-start sort_11">
                <p id="journal-title">For Authors and Reviewers</p>
                <div class="sort_12"></div>
                <p id="prp-content">
                  We value the contributions of authors and reviewers who play a
                  vital role in the publication process. We provide
                  comprehensive guidelines and resources to support authors in
                  preparing and submitting their manuscripts. For
                  reviewers/editors and authors, we offer detailed guidelines to
                  ensure fair and constructive evaluations that enhance the
                  quality of published research. We guide our authors and
                  researchers so that they can follow the guidelines suggested
                  by COPE and WAME, (give links to COPE AND WAME )
                  NMC,DOAJ,PUBMED,SCOPUS,WEB OF SCIENCE,NAAS RATINGS and many
                  other agencies.
                </p>
                <div className="row justify-content-start sort_21">
                  <Link to="/author_and_reviewer/">
                    <button id="journal-view-btn">Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 sort_13">
              <img src={"../../uploads/bro.png"} class="sort_14" />
            </div>
          </div>

          <ContactUs />
        </div>
      </>
    );
  }
}
