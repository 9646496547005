import { React, Axios, Link, Modal, $, dt } from "../../../Headers"
// import '../layout/FooterInsideWrapper.css'

export default class AdminHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            manuscript_title: "",
            org_name: "",
            reviewer: "",
            abstract: "",
            email: "",
            firstname: "",
            lastname: "",
            date: "",
            file: "",
            fileType: "",
            fileForPDF: "",
            fileForDOC: "",
            modelIsOpenForReject: false,
            modelIsOpenForAssign: false,
            modelIsOpenForDetails: false,
            article_id: "",
            server: "",
            modelIsOpenForResponse: false,
            redirect: false,
            selectedReviewerId: '',
            HistoryData: [],
            pdfshow: "none",
            docshow: "none",
            preloader: "block",
            url:"",
        }

    }

    doc_show = () => {
        if (this.state.fileType == "pdf") {
            this.setState({ pdfshow: "contents", fileForPDF: window.$file + this.state.file, preloader: "none" })
        }
        if (this.state.fileType == "doc" || this.state.fileType == "docx") {
            this.setState({ docshow: "contents", fileForDOC: window.$file + this.state.file, preloader: "none" })
        }
    }

    componentDidMount() {
        const server = window.$name;
        // console.log(server);
        const article = this.props.match.params.article_id;
        const status = this.props.match.params.status;
        if(status == 1)
        {
            this.setState({url:"/user_dashboard"});
        }
        if(status == 2)
        {
            this.setState({url:"/manuscript/admin/approve"});
        }
        if(status == 3)
        {
            this.setState({url:"/manuscript/admin/reject"});
        }
        else{
            this.setState({url:"/manuscript/admin/all"});
        }
        //console.log(article);
        this.setState({ article_id: article });
        //console.log(localStorage.getItem('user'));
        //console.log(localStorage.getItem('role'));
        this.setState({ server: server })

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetArticleForHistoryController.php',
                {
                    article: article,
                    status: status,
                }),
            Axios.post('//' + window.$name + '/controller/GetArticleHistoryController.php',
                {
                    article: article,
                })
        ])
            .then((res) => {
                //console.log(res[0]);
                // console.log(res[0].data.value.Article[0].manuscript_title);
                //console.log(res[1]);
                // console.log(res[0].data.value.Article[0].manuscript_title);
                this.setState({ manuscript_title: res[0].data.value.Article[0].manuscript_title });
                this.setState({ org_name: res[0].data.value.Article[0].organization });
                this.setState({ abstract: res[0].data.value.Article[0].abstract });
                this.setState({ email: res[0].data.value.Article[0].email });
                this.setState({ firstname: res[0].data.value.Article[0].firstname });
                this.setState({ lastname: res[0].data.value.Article[0].lastname });
                this.setState({ date: res[0].data.value.Article[0].entrydate });
                // this.setState({ file: res.data.value.Article[0].upload });
                // this.setState({ file:"//localhost/office/SORTSOL/frontend/public/uploads/"+res[0].data.value.Article[0].upload });
                this.setState({ file: res[0].data.value.Article[0].upload });
                this.setState({ fileType: res[0].data.value.Article[0].upload_type });


                this.setState({ HistoryData: res[1].data })

                // this.setState({role: res.data})
                this.doc_show();
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
            })
            .catch((err) => {
                // console.log(err);
            })
    }

    ForDetails = () => {
        this.setState({ modelIsOpenForDetails: true });
    }

    render() {

        return (
            <div>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <Link to={this.state.url}><div id="ss-uad-article-03" style={{ backgroundImage: "url('../../uploads/back.png')" }}></div></Link>
                            <div id="ss-uad-article-04">
                                <p id="ss-uad-article-05" type="button" onClick={this.ForDetails}>{this.state.manuscript_title}</p>
                                <p id="ss-uad-article-06">{this.state.org_name}</p>
                            </div>
                            {/* <h1 className="card-header  text-uppercase h1"  type="button" onClick={this.ForDetails} style={{ textAlign: 'left' }} id="card_header">{this.state.manuscript_title}</h1> */}
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {/* <button id="ss-uad-article-08" className="btn btn-primary mr-2" onClick={() => this.setState({ modelIsOpenForReject: true })}>Reject</button>
                                    <button id="ss-uad-article-09" className="btn btn-danger" onClick={() => this.setState({ modelIsOpenForAssign: true })}>Approve & Assign</button> */}
                        </div>
                    </div>
                    <div>
                        <div className="container-fluid" style={{ padding: "0 39px" }} >

                            <div className="row" style={{ height: "72vh" }}>
                                <div className="col-12" id="ss-uad-article-17">
                                    <div style={{ display: this.state.preloader }} class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    {/* <iframe src={this.state.file} height="100%" width="100%" frameBorder="0" style={{borderRadius:"12px"}}></iframe> */}
                                    <div style={{ display: this.state.pdfshow }}>
                                        <iframe src={this.state.fileForPDF} height="100%" width="100%" frameBorder="0" style={{ borderRadius: "12px" }}></iframe>
                                    </div>
                                    <div id="parent" style={{ display: this.state.docshow }}>
                                        <iframe id="myiframe" src={"https://docs.google.com/gview?url=" + this.state.fileForDOC + "&embedded=true"} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%">
                                            {/* <iframe style={{display:"none",src:"none"}} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%"> */}
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={this.state.modelIsOpenForDetails} className="popup-modal-content-details" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForDetails: false })}>
                        <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForDetails: false })}>&times;</span>
                        <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                            {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                            <span id="ss-uad-article-12" >Manuscript Details</span>
                        </div>
                        <div class="card-body">
                            {/* <center style={{ textAlign: 'center' }}> */}
                                <table className="table remove_border">
                                    <tr>
                                        <td className="text_new">First Name</td>
                                        <td className="text_new1">{this.state.firstname}</td>
                                    </tr>
                                    <tr>
                                        <td className="text_new">Last Name</td>
                                        <td className="text_new1">{this.state.lastname}</td>
                                    </tr>
                                    <tr>
                                        <td className="text_new">Email</td>
                                        <td className="text_new1">{this.state.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="text_new">Upload Date</td>
                                        {/* <td>{this.state.date}</td> */}
                                        <td className="text_new1">{new Date(this.state.date).getDate() + "/" + new Date(this.state.date).getMonth() + "/" + new Date(this.state.date).getFullYear() + " " + new Date(this.state.date).toLocaleTimeString()}</td>
                                    </tr>
                                    <tr>
                                        <td className="text_new">Abstract</td>
                                        <td className="text_new1">{this.state.abstract}</td>
                                    </tr>
                                    {/* <tr> */}
                                        <td colspan="2"  style={{ textAlign: "center" }}><a id="ss-uad-article-new" className="btn badge-info popup-abs-btn-center new_button" target="_blank" href={window.$file+this.state.file}>View/Download</a></td>
                                    {/* </tr> */}
                                </table>
                            {/* </center> */}
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}