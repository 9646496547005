import {React,Redirect} from '../../../Headers'
import AppHeader from './AppHeader'
//import AppSidebar from './AppSidebar'
import AppContent from './AppContent'
import AppFooter from './AppFooter'
import ScrollToTop from "react-scroll-to-top";
export default class DefaultLayout extends React.Component {
  constructor(){
    super();
    this.state = {
      Redirectstate: false
    }
  }

    componentDidMount(){
      const value=localStorage.getItem('user');
      // const value=localStorage.getItem('org_id');
      //console.log(localStorage.getItem('user'));
      //console.log(value);
      // this.setState({data:value});
    //  if(value==null)
    //  {
    //   this.setState({Redirectstate:true});
    //   //console.log(this.state.Redirectstate);
    //  }
    //  else{
    //    this.setState({Redirectstate:false});
    //  }
    //  //console.log(this.state.Redirectstate);
      }

  render(){
  return ((this.state.Redirectstate)?<Redirect to='/login'/>:
    <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
      <div class="wrapper sort_09">
         <AppHeader />
      {/* <AppSidebar /> */}
      {/* <div className="wrapper d-flex flex-column min-vh-100 bg-light"> */}
       
        {/* <div className="body flex-grow-1 px-3" style={{height:"92vh",overflowY:"scroll"}}> */}
          <div className="container-fluid">
          <AppContent />
          </div>
        {/* </div> */}
         {/* <AppFooter />  */}
      {/* </div> */}
      <ScrollToTop smooth />
    </div>
    // </body>
  );
}
}