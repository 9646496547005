import React from 'react';
export default class JournalLicense extends React.Component {
    render() {
	    return (
        <div className="row mb-5" style={{width:"100%"}}>
         <div className="col-6 d-flex justify-content-center">
               <img src={"../../uploads/licensing.png"} style={{filter:"drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",height:"450px",padding:"15px"}} />
           </div>
        <div className="col-6" style={{padding:"35px"}}>
           <div>
               <p id="journal-title">Licensing</p>
               <div class="sort_12"></div>
                        <p id="block-content">Before MRS can publish a journal article (or any other journal content type) we need a signed license-to-publish form. To find the right form, start by considering whether or not your article will be published as open access or non-open access and then select the most appropriate option.</p>
                        {/* <li><p id="block-content">For information about meeting the OA policies of funders and institutions when publishing with Springer Nature.</p></li> */}
                        <ul id="block-ul">
                        <li>
                        <p id="prp-subheading">Non-Open Access Articles</p>
                        <p id="prp-content">So that MRS has the necessary rights to publish your article, we ask you to grant an exclusive license-to-publish. (MRS does not ask you to transfer your copyright to us.) An exclusive license means that the rights needed to publish the article are granted to the journal owner on exclusive basis and the ownership of the copyright remains unchanged. </p>
                        </li>
                        <li>
                        <p id="prp-subheading">Open Access Articles</p>
                        <p id="prp-content">So that MRS has the necessary rights to publish your article, we ask you to grant a non-exclusive license to publish. (MRS does not ask you to transfer your copyright to us.) A non-exclusive license means that the rights needed to publish the paper are granted to the journal owner on a non-exclusive basis and the ownership of the copyright remains unchanged.  </p>
                        </li>
               
               </ul>
               {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
        <button id="journal-view-btn">Learn More</button> */}
               
           </div>
                   
           </div>
               </div>
      //  </div>
        );
    }   
}     