import { React, Axios, Link, Redirect, Modal, $, dt } from "../../../Headers"
// import Header from './Header'



export default class SubscriptionDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            response: '',
            redirect: false,
            redirecttype: "",
            server: '',
            Subdata: [],
        }

    }

    action = (Data) => {
        this.setState({ sub_id: Data.s_id });
        this.setState({ modelIsOpenForSubmit: true });
        // this.setState({ redirect: true });
        // this.setState({ redirecttype: "next" });
    }
    insertData = (event) => {
        event.preventDefault();
        event.persist();
        // //console.log(this.state.sub_id);
        this.setState({ modelIsOpenForSubmit: false });
        Axios.post('//' + window.$name + '/controller/InsertSubscription.php', {
            sub_id: this.state.sub_id,
        }).then((res) => {
            //console.log(res);
            // //console.log(res.data);
            this.setState({ response: res.data.Msg })
            this.setState({ modelIsOpenForResponse: true })
        })
            .catch((error) => {
                //console.log(error);
            })
    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })
        //console.log(localStorage.getItem('user'));
        //console.log(localStorage.getItem('role'));

        Axios.post('//' + window.$name + '/controller/GetSubscriptionDetailsController.php',
            {
                status: 0,
            }
        )
            .then((res) => {
                //console.log(res);
                this.setState({ Subdata: res.data })

                var dataTable = $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
                $("#searchbox").on("keyup search input paste cut", function () {
                    dataTable.search(this.value).draw();
                });
            })
            .catch((err) => {
                //console.log(err);
            })
    }

    render() {
        if (this.state.redirect == true && this.state.redirecttype == "next") {
            return <Redirect to={'/article/' + this.state.article_id} />
        }
        return (
            <div>

                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <p id="ss-uad-admin-02">Pending Subscription</p>
                        </div>
                        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <Link title="Pending" to={'/admin/subs/pending'}><button className="btn btn-outline-info m-2 active action-buttons" ><i class="nav-icon fas fa-exclamation"></i></button></Link>
                            <Link title="Approve" to={'/admin/subs/approve'}><button className="btn btn-outline-success m-2 action-buttons" > <i class="fas  fa-check"></i></button></Link>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    <div className="card-body" id="card_body">
                        <div className="container-fluid" >
                            <center>
                                <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Start Date</th>
                                            <th>End Data</th>
                                            <th>No Of Copy</th>
                                            <th>Entry Date</th>
                                            <th>Year Of Subscription</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Subdata.map((data) =>
                                            <tr>
                                                <td></td>
                                                <td>{data.email}</td>
                                                <td>{data.start_date}</td>
                                                <td>{data.end_date}</td>
                                                <td>{data.no_of_copy}</td>
                                                <td>{new Date(data.entrydate).toDateString()}</td>
                                                <td>{data.year_of_sub}</td>
                                                <td>
                                                    <button style={{ padding: "0" }} title="Approve" type="button" id="view" class="btn-lg   responsive_btn" onClick={() => this.action(data)} ><i class="far fa-paper-plane"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </center>
                        </div>

                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Are you Sure want to approve the Subscription?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.insertData}>Accept</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>Reject</button>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForResponse: false })}>
                   
                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                    <div className="col-9" style={{ paddingTop: "6px" }}>
                        <p id="popup-title">Remark</p>
                        {/* <p id="popup-content">{this.state.response}</p> */}
                        <p id="popup-content">{this.state.response}</p>

                    </div>
                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                    </div>
                    </div>
                                            
                </Modal>
            </div>
        );
    }
}