import InstituteDashboard from './components/InstituteDashboard';
import InstituteManuscript from './components/InstituteManuscriptUpload';
import InstituteAllManuscript from './components/InstituteAllManuscript'

const route=[
    { path:'/institute_dashboard', exact:true,name:"Author DashBoard",component:InstituteDashboard},
    { path:'/institute_manuscript', exact:true,name:"Author DashBoard",component:InstituteManuscript},
    { path:'/manuscript/institute/all', exact:true,name:"All Manuscript", component:InstituteAllManuscript},
]

export default route;