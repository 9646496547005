import { React } from "../../../Headers"
// import Header from './Header'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

// import Files from 'react-files'

import UploadManuscript from "../../UploadManuscript"

export default class SortManuscript extends React.Component {
    
    render() {
       
        return (
            <>
                  <UploadManuscript/>
            </>
        );
    }
}