import React from "react";
export default class Technology extends React.Component {
  render() {
    return (
      <div className="row mb-5 mobile-flex-reverse">
        <div className="col-lg-6 col-md-12 sort_04">
          <div className="row justify-content-start sort_18">
            <p id="journal-title">
              Introducing Our State-of-the-Art <br></br>Journal Publication
              Platform{" "}
            </p>
            <div class="sort_19"></div>
            <p id="prp-content" class="sort_20">
              At Sort Sol, we are proud to present our cutting-edge journal
              publication platform powered by our state-of-the-art technology,
              including our own Journal Management System. This advanced
              platform is designed to streamline the publishing process and
              enhance the author experience.
            </p>
            <p className="journal_subheading">
              Key Features and Benefits of Our Journal Publication Platform:
            </p>
            <ul>
              <li id="prp-content">
                <span className="list-title">
                  Efficient Manuscript Submission and Tracking :{" "}
                </span>{" "}
                Our intuitive and user-friendly interface enables authors to
                submit their manuscripts effortlessly. Authors can easily track
                the progress of their submissions throughout the editorial
                workflow, from initial submission to final publication.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Digital Object Identifier (DOI) Integration :{" "}
                </span>
                We integrate Digital Object Identifier (DOI) functionality into
                our platform, providing unique identifiers for published
                articles. DOIs enable persistent linking and citation tracking,
                ensuring the long-term accessibility and proper attribution of
                published works.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Comprehensive Analytics and Reporting :{" "}
                </span>
                Our platform offers detailed analytics and reporting
                capabilities, providing publishers, editors, and authors with
                valuable data on article performance, submission trends, and
                more. These insights empower data-driven decision-making and
                help optimize the publishing process.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Seamless Integration of Supplementary Materials:{" "}
                </span>
                Our platform allows for the seamless integration of
                supplementary materials such as data sets, multimedia files, and
                appendices. This ensures the comprehensive presentation of
                research findings and enhances the reader's understanding and
                engagement.
              </li>
            </ul>
            <p id="prp-content" class="sort_20">
              At Sort Sol, we continually invest in the development and
              enhancement of our journal publication platform to provide a
              technologically advanced and user-centric experience for authors,
              reviewers, and readers. Our commitment to innovation and
              excellence ensures that we deliver the highest quality
              publications while staying at the forefront of academic
              publishing.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 sort_04">
          <img
            src={"../../uploads/technology.svg"}
            class="sort_14"
            style={{
              width: "inherit",
              filter: "drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",
              position: "sticky",
              top: "115px",
            }}
          />
        </div>
      </div>
    );
  }
}
