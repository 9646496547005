import AuthorDashboard from './components/AuthorDashboard';
import AuthorManuscript from './components/AuthorManuscriptUpload';
// import Payment from './components/Payment';
import AuthorAllManuscript from './components/AuthorAllManuscript';
// import PaymentPendingManuscript from './components/AuthorPaymentPending';
import AuthorPayment from './components/AuthorPayment'
import Success from './components/Success'
import Fail from './components/Fail'
import AuthorArticle from './components/AuthorArticle'
import AcknowledgedReceipt from './components/AcknowledgedReceipt'

const route=[
    { path:'/author_dashboard', exact:true,name:"Author DashBoard", component:AuthorDashboard},
    { path:'/author_manuscript', exact:true,name:"Author Manuscript", component:AuthorManuscript},
    // { path:'/payment/:article_id', exact:true,name:'Payment', component:Payment},
    { path:'/manuscript/author/all', exact:true,name:'All Manuscript', component:AuthorAllManuscript},
    // { path:'/manuscript/author/p_pending', exact:true,name:'All Manuscript', component:PaymentPendingManuscript},
    { path:'/manuscript/author/f_pending', exact:true,name:'All Manuscript', component:AuthorPayment},
    { path:'/success/:p_id/:o_id', exact:true,name:'Success', component:Success},
    { path:'/fail/:p_id/:o_id', exact:true,name:'Fail', component:Fail},
    { path:'/author_article/:article_id', exact:true,name:'Author Article', component:AuthorArticle},
    { path:'/receipt/:article_id/:type', exact:true,name:'Receipt', component:AcknowledgedReceipt},
]

export default route;