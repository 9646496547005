import { React, Link, Axios, $, Redirect, Modal } from "../../../Headers";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      server: "",
      redirect: false,
      redirecttype: "",
      faildata: [],
    };
  }

  componentDidMount() {
    const server = window.$name;
    // //console.log(server);
    this.setState({ server: server });
    const p_id = this.props.match.params.p_id;
    const o_id = this.props.match.params.o_id;
    // //console.log(localStorage.getItem("user"));
    // //console.log(localStorage.getItem("role"));

    Axios.post("//" + window.$name + "/controller/GetTransactionFailController.php",
      {
        p_id: p_id,
        o_id: o_id,
      })
      .then((res) => {
        //console.log(res);
        this.setState({ faildata: res.data[0] });
        //     this.setState({ a_pending: res.data["pending"][0]["a_id"] });
        //     this.setState({ a_reject: res.data["reject"][0]["a_id"] });
        //     this.setState({ a_publish: res.data["publish"][0]["a_id"] });
        //     this.setState({ a_approve: res.data["approve"][0]["a_id"] });
        //     this.setState({
        //       a_pendingtopublish: res.data["pendingtopublish"][0]["a_id"],
        // });
        //     // //console.log(res.data[0].Submitted_on)
        //     // //console.log(new Date(res.data[0].Submitted_on).toDateString())
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  retry_payment=()=>{
    window.location.assign("//" + window.$name + "/cashfree/checkout/index.php?a_id=" + this.state.faildata.article_id +"&t_id="+this.state.faildata.t_id);
  }
  render() {
    return (
      <>
        <div class="container-fluid">
          <center><h3 class="mb-2">Payment Fail</h3></center>
          <div className="container">
          <div className="card">
          <div className="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td style={{textAlign:"center"}}>Transaction Id</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.unique_t_id}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Transaction Time</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.tranx_time}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Reference Id</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.reference_id}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Payment Mode</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.payment_mode}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Amount</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.price}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Email</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.email}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Mobile</td>
                  <td style={{textAlign:"center"}}>{this.state.faildata.mobile}</td>
                </tr>
                <tr>
                <center>
                  <td colSpan="2"><button className="btn btn-primary" onClick={this.retry_payment}>Retry Payment</button></td>
                </center>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          </div>
        </div>
      </>
    );
  }
}
