import { React, Axios, Link, Redirect, Modal, $, dt } from "../../../Headers"
// import Header from './Header'
import Menu from "./Menu"

const loading = (
    <div class="spinner-border text-dark" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  )


export default class AllArticle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            modalIsOpenForHistory: false,
            response: '',
            redirect: false,
            redirecttype: "",
            server: '',
            AuthorData: [],
            article_id: "",
            type: "Pending",
            HistoryData: [],
        }

    }

    GetAckReceipt = (Article_id,type) => {
        this.setState({ article_id: Article_id })
        // //console.log(ArticleData.article_id);
        this.setState({ redirect: true });
        this.setState({ redirecttype: type });
    }

    ViewHistory = (article_id) => {
        this.setState({ modalIsOpenForHistory: true });
        Axios.post('//' + window.$name + '/controller/GetArticleHistoryController.php',
            {
                article: article_id,
            })
            .then((res) => {
                //console.log(res);
                this.setState({ HistoryData: res.data })
            })
    }
    // GetAckReceipt=(article_id)=>{
    //     this.setState({article_id:article_id});
    // }


    componentDidMount() {

        // const add = document.getElementsByClassName('current')[0];
        // //console.log(add);
        // add.id = "active_page";
        // $('.current').attr('id','menu')
        // add.setAttribute('id','active_page');
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })
        // //console.log(localStorage.getItem('user'));
        // //console.log(localStorage.getItem('role'));

        Axios.post('//' + window.$name + '/controller/GetAllArticleForAuthorController.php',
            {
                unique_user_id: localStorage.getItem('unique_user_id'),
                type: localStorage.getItem('type'),
            })
            .then((res) => {
                //console.log(res);
                this.setState({ AuthorData: res.data })
                // //console.log(res.data[0].Submitted_on)
                // //console.log(new Date(res.data[0].Submitted_on).toDateString())

                var dataTable = $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
                $("#searchbox").on("keyup search input paste cut", function () {
                    dataTable.search(this.value).draw();
                });
            })
            .catch((err) => {
                //console.log(err);
            })
    }

    render() {
        if (this.state.redirect == true && this.state.redirecttype == "INITIAL") {
            return <Redirect to={'/receipt/' + this.state.article_id + "/" + "INITIAL"} />
        }
        if (this.state.redirect == true && this.state.redirecttype == "FINAL") {
            return <Redirect to={'/receipt/' + this.state.article_id + "/" + "FINAL"} />
        }
        return (
            <>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <p id="ss-uad-admin-02">All Manuscripts</p>
                        </div>
                        <Menu/>
                        <div class="col-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div class="input-group">
                                <i id="ss-sad-login-011" class="fa fa-search" ></i>
                                <input class="form-control" type="text" id="searchbox" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body" id="card_body">
                        <div className="container-fluid" >
                            <center>
                            {(this.state.AuthorData == null)?(<div id="center_preloader">{loading}</div>):
                                <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Journal Name</th>
                                            <th>Name Of Manuscript</th>
                                            <th>Submited On</th>
                                            <th>Last Updated</th>
                                            <th>Author / Institution</th>
                                            <th>Initial</th>
                                            <th>Final</th>
                                            <th>History</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.AuthorData.map((data) =>
                                            <tr>
                                                <td></td>
                                                <td>{data.journalname}</td>
                                                <td>{data.manuscript_title}</td>
                                                <td>{data.Submitted_on}</td>
                                                <td>{data.Last_Update}</td>
                                                {/* <td style={{ width: "20%" }}>{new Date(data.Submitted_on).getDate() + "/" + new Date(data.Submitted_on).getMonth() + "/" + new Date(data.Submitted_on).getFullYear() + " " + new Date(data.Submitted_on).toLocaleTimeString()}</td>
                                                <td style={{ width: "20%" }}>{new Date(data.Last_Update).getDate() + "/" + new Date(data.Last_Update).getMonth() + "/" + new Date(data.Last_Update).getFullYear() + " " + new Date(data.Last_Update).toLocaleTimeString()}</td> */}
                                                <td>{data.organization}</td>
                                                <td >
                                                <button type="button" disabled={!data.payment_flag} class="btn-lg  responsive_btn" id="history" title="Initial Payment Receipt" value="history" onClick={() => this.GetAckReceipt(data.article_id,"INITIAL")} ><i class="fas fa-check"></i></button>
                                                </td>
                                                <td >
                                                <button type="button" disabled={!data.final_payment} class="btn-lg  responsive_btn" id={data.final_payment ? "history" : "disabled_history" } title="Final Payment Receipt" value="history" onClick={() => this.GetAckReceipt(data.article_id,"FINAL")} ><i class="fas fa-check-double"></i></button>
                                                </td>
                                              
                                                
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" class="btn-lg  responsive_btn" id="history" title="history" value="history" onClick={() => this.ViewHistory(data.article_id)} ><i class="fas fa-history"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            </center>
                        </div>

                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                <Modal isOpen={this.state.modalIsOpenForHistory} className="popup-modal-content-details" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modalIsOpenForHistory: false })}>
                <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modalIsOpenForHistory: false })}>&times;</span>                    
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                       
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Manuscript History</span>
                    </div>
                    <center style={{ textAlign: 'center' }}>
                        <table id="table" className="table table-striped table-responsive table-in-popup" style={{ width: "100%" }}>
                            <thead class="ss-uad-admin-03">
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: "20%" }}>Remark</th>
                                    <th style={{ width: "40%" }}>Action Date</th>
                                    {/* <th style={{ width: "20%" }}>Current User Name</th> */}
                                    <th style={{ width: "40%" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.HistoryData.map((data,i) =>
                                    <tr>
                                        <td>{i+1}</td>
                                        <td>{data.remark}</td>
                                        <td>{data.action_taken}</td>
                                        {/* <td>{new Date(data.action_taken).getDate() + "/" + new Date(data.action_taken).getMonth() + "/" + new Date(data.action_taken).getFullYear() + " " + new Date(data.action_taken).toLocaleTimeString()}</td> */}
                                        {/* <td>{data.firstname + " "}{data.lastname}</td> */}
                                        <td>{data.status_msg}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </center>
                </Modal>
            </>
        );
    }
}