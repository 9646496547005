import { React, Axios, $} from "../../../Headers"
// import Header from './Header'



export default class SuperAdminContact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            server: '',
            ContactData: [],
        }

    }

   
    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })
        // //console.log(localStorage.getItem('user'));
        // //console.log(localStorage.getItem('role'));

        Axios.post('//' + window.$name + '/controller/GetContactForTableController.php')
            .then((res) => {
                //console.log(res);
                this.setState({ ContactData: res.data })
                // //console.log(res.data[0].Submitted_on)
                // //console.log(new Date(res.data[0].Submitted_on).toDateString())

                var dataTable = $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
                $("#searchbox").on("keyup search input paste cut", function () {
                    dataTable.search(this.value).draw();
                });
            })
            .catch((err) => {
                //console.log(err);
            })
    }

    render() {
        return (
            <>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <p id="ss-uad-admin-02">Contact Us</p>
                        </div>
                        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                           
                        </div>
                        <div class="col-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div class="input-group">
                                <i id="ss-sad-login-011" class="fa fa-search" ></i>
                                <input class="form-control" type="text" id="searchbox" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body" id="card_body">
                        <div className="container-fluid" >
                            <center>
                                <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>#</th>
                                            <th style={{ width: "20%" }}>Name</th>
                                            <th style={{ width: "20%" }}>Email</th>
                                            <th style={{ width: "50%" }}>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ContactData.map((data) =>
                                            <tr>
                                                <td style={{ width: "10%" }}></td>
                                                <td style={{ width: "20%" }}>{data.name}</td>
                                                <td style={{ width: "20%" }}>{data.email}</td>
                                                <td style={{ width: "50%" }}>{data.msg}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </center>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}