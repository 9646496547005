import { React, Link, Axios } from '../../../Headers';

export default class SuperDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      server: '',
      a_total: "",
      a_pending: "",
      a_reject: "",
      a_publish: "",
      a_approve: "",
    }

  }
  componentDidMount() {

    const server = window.$name;
    // //console.log(server);
    this.setState({ server: server })
    //console.log(localStorage.getItem('user'));
    //console.log(localStorage.getItem('role'));

    Axios.post('//' + window.$name + '/controller/GetCountForSuperAdminController.php',
    {
      user_id: localStorage.getItem('user_id'),
      role_id: localStorage.getItem('role_id'),
    })
      .then((res) => {
        //console.log(res);
        this.setState({ a_total: res.data['total'][0]['a_id'] })
        this.setState({ a_pending: res.data['pending'] })
        this.setState({ a_reject: res.data['reject'] })
        this.setState({ a_approve: res.data['approve'] })
        // this.setState({ a_publish: res.data['publish'][0]['a_id'] })
        // //console.log(res.data[0].Submitted_on)
        // //console.log(new Date(res.data[0].Submitted_on).toDateString())
      })
      .catch((err) => {
        //console.log(err);
      })
  }

  render() {
    return (
      <>
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
              </div>
              {/* <!-- /.col --> */}
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.container-fluid --> */}
        </div>
        {/* <!-- /.content-header --> */}

        {/* <!-- Main content --> */}
        <section class="content">
          <div class="container-fluid">
            {/* <!-- Small boxes (Stat box) --> */}
            <div class="row">
              {/* <div class="col-lg-3 col-6">
            
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{this.state.a_total}</h3>

                    <p>Total Manuscript</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <Link to={"/superadmin"} class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> */}
              {/* <!-- ./col --> */}
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{this.state.a_total}</h3>

                    <p>Total Manuscript</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <Link to={"/manuscript/superadmin/all"} class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div>
              <div class="col-lg-3 col-6">
               
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>{this.state.a_pending}</h3>

                    <p>Pending Manuscript</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <Link to={"/manuscript/superadmin/pending"} class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                {/* <!-- small box --> */}
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{this.state.a_approve}</h3>

                    <p>Approve Manuscript</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <Link to={"/manuscript/superadmin/approve"} class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div>
              {/* <!-- ./col --> */}
              
              {/* <!-- ./col --> */}
              <div class="col-lg-3 col-6">
                {/* <!-- small box --> */}
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{this.state.a_reject}</h3>

                    <p>Rejected Manuscript</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <Link to={"/manuscript/superadmin/reject"} class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div>
              {/* <!-- ./col --> */}
            </div>

          </div>
        </section>

        {/* <div class="container-fluid">
          <h3 class="mb-2">Total Manuscript</h3>
          <div class="row">
              
              </div>
            </div> */}
            {/* <!-- /.col --> */}
            {/* <div class="col-md-6 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-success"><i class="far fa-flag"></i></span>

                <div class="info-box-content">
                  <span class="info-box-text">Active Plans</span>
                  <span class="info-box-number">10</span>
                </div> */}
                {/* <!-- /.info-box-content --> */}
              {/* </div> */}
              {/* <!-- /.info-box --> */}
            {/* </div> */}
            {/* <!-- /.col --> */}
          {/* </div>
        </div>

        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Actions</h1>
              </div> */}
              {/* <!-- /.col --> */}
              {/* <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Actions</li>
                </ol>
              </div> */}
              {/* <!-- /.col --> */}
            {/* </div> */}
            {/* <!-- /.row --> */}
          {/* </div> */}
          {/* <!-- /.container-fluid --> */}
        {/* </div> */}
        {/* <!-- /.content-header --> */}
        {/* <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-12">
              <Link to="/super_org_register">
                <div class="info-box bg" style={{ backgroundColor: "#3c8dbc", color: "white" }}>
                  <span class="info-box-icon"><i class="far fa-building"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Organization</span>
                    <span class="progress-description" style={{ fontSize: ".75rem", display: "block" }}>
                      Create New Organization
                    </span>
                  </div> */}
                  {/* <!-- /.info-box-content --> */}
                {/* </div> */}
                {/* <!-- /.info-box --> */}
              {/* </Link>
            </div> */}
            {/* <!-- /.col --> */}
            {/* <div class="col-md-6 col-sm-6 col-12">
              <Link>
                <div class="info-box bg" style={{ backgroundColor: "#001f3f", color: "white" }}>
                  <span class="info-box-icon"><i class="far fa-paper-plane"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Plan</span>
                    <span class="progress-description" style={{ fontSize: ".75rem", display: "block" }}>
                      Create New Plan
                    </span>
                  </div> */}
                  {/* <!-- /.info-box-content --> */}
                {/* </div> */}
                {/* <!-- /.info-box --> */}
              {/* </Link>
            </div> */}
            {/* <!-- /.col --> */}
          {/* </div>
        </div>

        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Views</h1>
              </div> */}
              {/* <!-- /.col --> */}
              {/* <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Views</li>
                </ol>
              </div> */}
              {/* <!-- /.col --> */}
            {/* </div> */}
            {/* <!-- /.row --> */}
          {/* </div> */}
          {/* <!-- /.container-fluid --> */}
        {/* </div> */}
        {/* <!-- /.content-header --> */}
        {/* <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-12">
              <Link to="/super_org_existing">
                <div class="info-box bg" style={{ backgroundColor: "#3c8dbc", color: "white" }}>
                  <span class="info-box-icon"><i class="far fa-building"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Organization</span>
                    <span class="progress-description" style={{ fontSize: ".75rem", display: "block" }}>
                      View Existing Organizations
                    </span>
                  </div> */}
                  {/* <!-- /.info-box-content --> */}
                {/* </div> */}
                {/* <!-- /.info-box --> */}
              {/* </Link>
            </div> */}
            {/* <!-- /.col --> */}
            {/* <div class="col-md-6 col-sm-6 col-12">
              <Link>
                <div class="info-box bg" style={{ backgroundColor: "#001f3f", color: "white" }}>
                  <span class="info-box-icon"><i class="far fa-paper-plane"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Plan</span>
                    <span class="progress-description" style={{ fontSize: ".75rem", display: "block" }}>
                      View Existing Plans
                    </span>
                  </div> */}
                  {/* <!-- /.info-box-content --> */}
                {/* </div> */}
                {/* <!-- /.info-box --> */}
              {/* </Link>
            </div> */}
            {/* <!-- /.col --> */}
          {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}