// import {Link} from "../../../Headers"
import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
export default class Menu extends React.Component {

    render() {
        return (
            // <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
            // <button title="All" className="btn btn-outline-info m-2 active action-buttons" ><i class="nav-icon fas fa-exclamation"></i></button>
            // <button title="Approve" className="btn btn-outline-success m-2 action-buttons" onClick={() => this.typeselect("Approve")}><i class="fas  fa-check"></i></button>
            // <button title="Reject" className="btn btn-outline-danger m-2 action-buttons" onClick={() => this.typeselect("Reject")}><i class="fas  fa-times"></i></button>
        // </div>
        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                 <NavLink to={'/manuscript/Reviewer/pending'} title="Pending" id="sort-table-nav-btn" className="btn btn-outline-info m-2" activeClassName="active"><i class="nav-icon fas fa-exclamation"></i></NavLink>
                 <NavLink to={'/manuscript/Reviewer/approve'} title="Approve" id="sort-table-nav-btn" className="btn btn-outline-success m-2" activeClassName="active"><i class="fas  fa-check"></i></NavLink>
                 <NavLink to={'/manuscript/Reviewer/reject'} title="Reject" id="sort-table-nav-btn" className="btn btn-outline-danger m-2" activeClassName="active"><i class="fas  fa-times"></i></NavLink>
            </div>
        );
    }   
}    