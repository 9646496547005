export const TypeOfBook = [
  {
    value: "TextBook",
    label: "TextBook",
  },
  {
    value: "Reference Book",
    label: "Reference Book",
  },
  {
    value: "Competition",
    label: "Competition",
  },
  {
    value: "Question Bank",
    label: "Question Bank",
  },
  {
    value: "Record Book",
    label: "Record Book",
  },
];

export const ManuscriptStatus = [
  {
    value: "Not Stated Yet",
    label: "Not Stated Yet",
  },
  {
    value: "Partially completed",
    label: "Partially completed",
  },
  {
    value: "Prepared",
    label: "Prepared",
  },
];

export const EducationType = [
  {
    value: "UG",
    label: "UG",
  },
  {
    value: "PG",
    label: "PG",
  },
  {
    value: "PHD",
    label: "PHD",
  },
  {
    value: "REFERENCE",
    label: "REFERENCE",
  },
];

export const BookWriteStatus = [
  {
    value: "YES",
    label: "Yes",
  },
  {
    value: "NO",
    label: "No",
  },
];

export const Interest = [
  {
    value: "Book",
    label: "Book",
  },
  {
    value: "Journal",
    label: "Journal",
  },
  {
    value:"Both",
    label:"Both",
  }
];

export const DepartmentOfJournal = [
  {
    value: "Medical",
    label: "Medical",
  },
  {
    value: "Nursing",
    label: "Nursing",
  },
  {
    value: "Pharma",
    label: "Pharma",
  },
  {
    value: "Dentistry",
    label: "Dentistry",
  },
  {
    value: "Ayurveda",
    label: "Ayurveda",
  },
  {
    value: "Agriculture",
    label: "Agriculture",
  },
];

export const TypeOfJournal = [
  {
    value: "Institutional",
    label: "Institutional",
  },
  {
    value: "Association",
    label: "Association",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Research Entities",
    label: "Research Entities",
  },
];

export const TentativeFrequency = [
  {
    value: " Once a year",
    label: " Once a year",
  },
  {
    value: "Twice a year",
    label: "Twice a year",
  },
  {
    value: "Thrice a year",
    label: "Thrice a year",
  },
  {
    value: "Four times a year",
    label: "Four times a year",
  },
  {
    value: "6 times a year",
    label: "6 times a year",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Others",
    label: "Others",
  },
];
