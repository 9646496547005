import { React, Axios, Link, validator, Redirect } from "../../../Headers"

export default class SuperAdminLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      RedirectState: false,
      server: '',
      errors: {},
    }
  }
  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    let email = this.email.value;
    let password = this.password.value;

    if (!validator.isEmail(email)) {
      formIsValid = false;
      errors["email"] = "Please Enter Valid Email Id";
    }
    if (!validator.isStrongPassword(password)) {
      formIsValid = false;
      errors["password"] = "minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  componentDidMount() {
    const server = window.$name;
    ////console.log(server);
    this.setState({ server: server })
  }
  // static contextType = AppContext;
  insertUser = (event) => {
    event.preventDefault();
      event.persist();
    if (this.handleValidation()) {
      Axios.post('//' + this.state.server + '/controller/SuperAdminLogController.php',
        {
          email: this.email.value,
          password: this.password.value,
        })
        .then(function (res) {
          //console.log(res);
          if (res.data.Success == 1) {
            alert(res.data.Msg);
            //console.log(res);
            localStorage.setItem('user_id', res.data.User[0]['user_id']);
            localStorage.setItem('role_id', res.data.User[0]['role']);
            localStorage.setItem('user_firstname', res.data.User[0]['firstname']);
            localStorage.setItem('user_lastname', res.data.User[0]['lastname']);
            // //console.log(res.data.User[0]['user_id']);
            // //console.log(res.data.User[0]['role']);
            this.setState({ Redirectstate: true });
          }
          else {
            alert(res.data.Msg);
            //console.log(res);

          }

        }
          .bind(this))
        .catch(function (error) {
          //console.log(error);
        });
    }
  }
  render() {

    return (
      (this.state.Redirectstate) ? (<Redirect to="/super_dashboard" />) :
        <body>
          <div style={{ backgroundImage: "url('../../uploads/logo-black.png')" }} id="ss-sad-login-001"></div>
          <div className="container-fluid">
            <div className="row" id="ss-sad-login-002">
              <div className="col-7" id="ss-sad-login-003">
                <img src={"../../uploads/banner1.png"} className="ss-sad-login-004" />
                {/* <div style={{width:"inherit",height:"inherit"}}> */}
                <img src={"../../uploads/banner2.png"} className="ss-sad-login-004" />
                {/* <img  src={"../../uploads/logo-with-text-white.png"} style={{width:"inherit",height:"100vh"}}/> */}
                <div id="ss-sad-login-005" style={{ backgroundImage: "url('../../uploads/logo-with-text-white.png')" }}></div>
                {/* </div> */}
              </div>
              <div id="ss-sad-login-006" class="col-5 login-box" >
                {/* <!-- /.login-logo --> */}
                <div id="ss-sad-login-007" class="card" >
                  <div id="ss-sad-login-008" class="card-header text-left">
                    <div class="ss-sad-login-009" >Super Admin Login</div>
                  </div>
                  <div id="ss-sad-login-010" class="card-body">
                    {/* <p class="login-box-msg">Sign in to start your session</p> */}
                    <form onSubmit={this.insertUser}>
                      <div class="input-group mb-3">
                        <i id="ss-sad-login-011" class="fa fa-user" ></i>
                        <input type="email" name="email" ref={(val) => this.email = val} class="form-control" id="ss-sad-login-013" placeholder="Email" required />
                        <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                      </div>
                      <div class="input-group mb-3">
                        <i id="ss-sad-login-012" class="fa fa-lock" ></i>
                        <input type="password" name="password" ref={(val) => this.password = val} class="form-control" id="ss-sad-login-013" placeholder="Password" required />
                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                      </div>
                      <div class="row">
                        <button type="submit" id="ss-sad-login-014" class="btn btn-block">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
    );
  }
}