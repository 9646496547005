import { useEffect, useState } from "react";
import {
  Axios,
  Modal,
  React,
  Select,
  useParams,
  validator,
} from "../../../Headers";
import {
  DepartmentOfJournal,
  TentativeFrequency,
  TypeOfJournal,
} from "../helper";

const AuthorFormJournal = () => {
  const [modelIsOpenForSubmit, setModelIsOpenForSubmit] = useState(false);
  const [modelIsOpenForResponse, setModelIsOpenForResponse] = useState(false);
  const [response, setResponse] = useState("");

  const [selectedTypeOfDepartment, setSelectedTypeOfDepartment] = useState("");
  const [
    selectedTypeOfDepartmentForValid,
    setSelectedTypeOfDepartmentForValid,
  ] = useState("");
  const [selectedTypeOfJournal, setSelectedTypeOfJournal] = useState("");
  const [selectedTypeOfJournalForValid, setSelectedTypeOfJournalForValid] =
    useState("");
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState("");

  const [frequency, setFrequency] = useState("");
  const [frequencyForValid, setFrequencyForValid] = useState("");

  const [error, setError] = useState({});
  const [minDate, setMinDate] = useState();
  const [server, setServer] = useState("");

  const { formId } = useParams();

  useEffect(() => {
    const server = window.$name;
    setServer(server);
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are 0-based
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    const minDate = `${year}-${month}-${day}`;

    setMinDate(minDate);
  }, []);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (validator.isEmpty(selectedTypeOfDepartmentForValid)) {
      formIsValid = false;
      errors["typeOfDepartment"] = "Please Select";
    }

    if (validator.isEmpty(selectedTypeOfJournalForValid)) {
      formIsValid = false;
      errors["typeOfJournal"] = "Please Select";
    }

    if (validator.isEmpty(keyword)) {
      formIsValid = false;
      errors["keyword"] = "Please Enter Keyword";
    }

    if (validator.isEmpty(frequencyForValid)) {
      formIsValid = false;
      errors["frequency"] = "Please Select";
    }

    setError(errors);
    return formIsValid;
  };

  const ToOpenModel = (event, button) => {
    event.preventDefault();
    event.persist();
    if (button == "submit") {
      if (handleValidation()) {
        setModelIsOpenForSubmit(true);
      }
    }
  };

  const insertData = (event) => {
    event.preventDefault();
    event.persist();

    setModelIsOpenForSubmit(false);
    const fd = new FormData();

    fd.append("formId", formId);
    fd.append("typeOfDepartment", selectedTypeOfDepartment.value);
    fd.append("typeOfJournal", selectedTypeOfJournal.value);
    fd.append("keyword", keyword);
    fd.append("startDate", startDate);
    fd.append("frequency", frequency.value);

    Axios.post(
      "//" + server + "/controller/WebAuthorJournalFormController.php",
      fd
    )
      .then((res) => {
        console.log(res);
        if (res.data.success == 1) {
          setResponse(res.data.Msg);
          setModelIsOpenForResponse(true);
        } else {
          setResponse(res.data.Msg);
          setModelIsOpenForResponse(true);
          //   alert(res.data.Msg);
          //   window.location.reload();
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleDepartmentOfJournal = (selectedValue) => {
    setSelectedTypeOfDepartment(selectedValue);
    setSelectedTypeOfDepartmentForValid(selectedValue.value);
  };

  const handleTypeOfJournal = (selectedValue) => {
    setSelectedTypeOfJournal(selectedValue);
    setSelectedTypeOfJournalForValid(selectedValue.value);
  };

  const handleFrequency = (selectedValue) => {
    setFrequency(selectedValue);
    setFrequencyForValid(selectedValue.value);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_10">
          <p id="journal-title">Journal Form</p>
          <div class="sort_12"></div>
          <div className="container ">
            <form onSubmit={(event) => ToOpenModel(event, "submit")}>
              <center>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleDepartmentOfJournal}
                          options={DepartmentOfJournal}
                          value={selectedTypeOfDepartment}
                          placeholder="Department of Journal"
                        />
                        <span style={{ color: "red" }}>
                          {error["DepartmentOfJournal"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleTypeOfJournal}
                          options={TypeOfJournal}
                          value={selectedTypeOfJournal}
                          placeholder="Type of Journal"
                        />
                        <span style={{ color: "red" }}>
                          {error["TypeOfJournal"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-11 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="keyword"
                          onChange={(e) => {
                            setKeyword(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Insert Keywords for Journal"
                          required
                        />
                        <span style={{ color: "red" }}>{error["title"]}</span>
                      </div>
                    </div>
                    <div className="col-1 mobile-none">
                      <button
                        style={{
                          borderRadius: "50px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "larger",
                          width: "100%",
                        }}
                        type="button"
                        class="icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Please provide the exact keywords that best describe the journal"
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleFrequency}
                          options={TentativeFrequency}
                          value={frequency}
                          placeholder="Tentative Frequency"
                        />
                        <span style={{ color: "red" }}>
                          {error["TentativeFrequency"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          value={startDate}
                          name="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          min={minDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                          className="form-control"
                          placeholder="When do you want to start"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["startDate"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row sort_18">
                    <button type="submit" id="home-publish-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </center>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 sort_13">
          <img src={"../../uploads/rafiki3.png"} class="sort_14" />
        </div>
      </div>
      <Modal
        isOpen={modelIsOpenForSubmit}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => {
          setModelIsOpenForSubmit(false);
        }}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Alert</span>
        </div>
        <div class="card-body">
          <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
          <div className="row web-custom-btn">
            <button
              // id="ss-uad-article-09"
              className="accept-btn"
              onClick={insertData}
            >
              Accept
            </button>
            <button
              // id="ss-uad-article-08"
              className="cancel-btn"
              onClick={() => setModelIsOpenForSubmit(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modelIsOpenForResponse}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => setModelIsOpenForResponse(false)}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Success</span>
        </div>
        <div class="card-body">
          {/* <p id="assign-popup-box-msg">{response}</p> */}
          <p id="assign-popup-box-msg">Thank You</p>
          <div className="row sort_26">
            <button
              id="ss-uad-article-09"
              className="btn btn-primary mr-2 col-5 sort_27"
              onClick={() => window.location.assign("/author_form")}
            >
              <i class="fas fa-check" style={{ fontSize: "29px" }}></i>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthorFormJournal;
