import React from 'react';
export default class JournalPolicy extends React.Component {
    render() {
	    return (
        <div className="row mb-5" style={{width:"100%"}}>
         <div className="col-6 d-flex justify-content-center">
               <img src={"../../uploads/OA_Policy.png"} style={{filter:"drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",height:"450px",padding:"15px"}} />
           </div>
        <div className="col-6" style={{padding:"35px"}}>
           <div>
               <p id="journal-title">OA Policies</p>
               <div class="sort_12"></div>
                        <ul id="block-ul">
                        <li><p id="block-content">Our publication policies help our authors achieve a high level of openness with their research and support them in meeting the open access (OA) requirements of their research funders and institutions.</p></li>
                        <li><p id="block-content">For information about meeting the OA policies of funders and institutions when publishing with Springer Nature.</p></li>
               
               
               </ul>
               {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
        <button id="journal-view-btn">Learn More</button> */}
               
           </div>
                   
           </div>
               </div>
      //  </div>
        );
    }   
}     