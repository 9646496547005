import { Modal } from "../../../../Headers";

const AuthorBookFormModal = (props) => {
  const {
    modalStatus,
    handleModal,
    bookData,
    authorTableData,
    authorAffiliationTable,
  } = props;

  return (
    <Modal
      isOpen={modalStatus}
      className="popup-modal-details"
      overlayClassName="popup-modal"
      onRequestClose={handleModal}
    >
      {bookData && bookData.length > 0 && (
        <>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Book Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].book_title}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>About Book</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].about_book}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Book Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].book_type}
                  disabled
                />
              </div>
            </div>
          </div>
          {authorAffiliationTable &&
            authorAffiliationTable.length > 0 &&
            authorAffiliationTable.map((_data, index) => {
              return (
                <>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Author</label>
                        <input
                          type="text"
                          className="form-control"
                          value={_data.author}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Affiliation</label>
                        <input
                          type="text"
                          className="form-control"
                          value={_data.affiliation}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Education type</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].education_type}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Furnish Detail status</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].furnish_detail_status}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Furnish Details</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].furnish_detail}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Book Write Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].selected_book_write_status}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Manuscript date</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].manuscript_date}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Manuscript Month</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].manuscript_month}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Unique Feature</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].unique_feature}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Manuscript Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookData[0].manuscript_status}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              {bookData[0].file && (
                <div className="download-btn">
                  <a
                    target="_blank"
                    className="download"
                    href={`${window.$file}/AuthorData/${bookData[0].file}`}
                  >
                    Download File
                  </a>
                </div>
              )}
              {!bookData[0].file && (
                <div className="download-btn">Not Uploaded</div>
              )}
            </div>
          </div>
          {authorTableData &&
            authorTableData.length > 0 &&
            authorTableData.map((_data, index) => {
              return (
                <>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={_data.title}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Publisher</label>
                        <input
                          type="text"
                          className="form-control"
                          value={_data.publisher}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Author</label>
                        <input
                          type="text"
                          className="form-control"
                          value={_data.author}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </>
      )}
    </Modal>
  );
};

export default AuthorBookFormModal;
