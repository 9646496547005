import { React, Axios, Link } from "../Headers"

export default class SuperAdminLogOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      server: ""
    }
  }
  componentDidMount() {
    const server = window.$name;
    //console.log(server);
    this.setState({ server: server })

    Axios.post('//' + window.$name + '/controller/LogoutController.php',
      {
        user_id: localStorage.getItem('user'),
      })
      .then((response) => {
        // console.log(response);
      })
    localStorage.clear();
  }
  render() {
    return (
      <body >
        <div style={{ backgroundImage: "url('../../uploads/logo-black.png')" }} id="ss-sad-login-001"></div>
        <div className="container-fluid">
          <div className="row" id="ss-sad-login-002">
            <div className="col-7" id="ss-sad-login-003">
              <img src={"../../uploads/banner1.png"} className="ss-sad-login-004" />
              <img src={"../../uploads/banner2.png"} className="ss-sad-login-004" />
              <div id="ss-sad-login-005" style={{ backgroundImage: "url('../../uploads/logo-with-text-white.png')" }}></div>
            </div>

            <div id="ss-sad-login-006" class="col-5 login-box" >
                {/* <!-- /.login-logo --> */}
                <div id="ss-sad-login-007" class="card" >                  <div class="ss-sad-login-009">
                <div id="ss-sad-login-008" class="card-header text-left">
                    Logout
                  </div>
                  <div className="row">
                    <div class="col-12">
                      
                        <div class="card-body text-center" style={{ color: "#ef0000" }}>
                          You're Successfully Logged Out!!!
                        </div>
                        {/* <!-- /.card-body --> */}
                      {/* <!-- /.card --> */}
                    </div>
                  </div>
                  <div class="text-center ss-sad-login-009">
                    <p class="h5">Click Here to Login Again!!!</p>
                  </div>
                 
                  <div className="text-center ">
                    <Link id="ss-sad-login-014" class="btn btn-block" to="login">Login</Link>
                  </div> 
                </div>
              </div>
            </div>

          </div>
        </div>

      </body>
    )
  }
}