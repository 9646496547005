import { React, Axios, Link, Redirect, Modal, validator, Select } from "../../../Headers"
// import Header from './Header'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



import '../layout/FooterInsideWrapper.css'

export default class SuperCreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: "",
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            server: '',
            role: "",
            subject: "",
            selectedrole: "",
            selectedroleforvalid: "",
            selectedsubject: "",
            selectedsubjectforvalid: "",
            selectedtype: {
                label: "Select Editor Type",
                value: "Cheif",
            },
            typeshow: "none",
        }

    }
    handleValidation() {
        //let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let firstname = this.firstname.value;
        let lastname = this.lastname.value;
        let username = this.username.value;
        let password = this.password.value;
        let address = this.address.value;
        let email = this.email.value;
        let phone = this.state.phone;
        let role = this.state.selectedroleforvalid;
        let subject = this.state.selectedsubjectforvalid;


        //organization Name

        if (!validator.isAlpha(firstname, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["firstname"] = "Only letters";
        }

        //director Name

        if (!validator.isAlpha(lastname, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["lastname"] = "Only letters";
        }

        //user name

        if (!validator.isAlphanumeric(username, ['en-GB'])) {
            formIsValid = false;
            errors["username"] = "Only letters";
        }

        if (validator.isNumeric(username, ['en-GB'])) {
            formIsValid = false;
            errors["username"] = "Only Numeric Value is Not Allowed";
        }

        //password
        if (!validator.isStrongPassword(password)) {
            formIsValid = false;
            errors["password"] = "minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,";
        }

        //headquarters address

        if (!validator.isAlphanumeric(address, ['en-GB'], { ignore: " " })) {
            if (!address.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["address"] = "Enter Only Valid Text";
            }
        }

        //Email
        if (!validator.isEmail(email)) {
            formIsValid = false;
            errors["email"] = "Enter Valid Email Address.";
        }


        //phone
        if (!validator.isMobilePhone(phone)) {
            formIsValid = false;
            errors["phone"] = "Please Enter Valid Mobile Number.";
        }

        if (validator.isEmpty(role)) {
            formIsValid = false;
            errors["role"] = "Please Select Role";
        }

        if (validator.isEmpty(subject)) {
            formIsValid = false;
            errors["subject"] = "Please Select Subject";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    phoneinput = (event) => {
        //console.log(event)
        this.setState({ phone: event })
    }

    ToOpenModel = (event, button) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });
                // this.insertData
            }
        }

    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetRoleForCreateUser.php'),
            Axios.post('//' + window.$name + '/controller/GetSubjectController.php'),
        ])
            .then((res) => {
                //console.log(res[0].data.value['Role']);
                //console.log(res[1]);
                this.setState({ role: res[0].data.value['Role'] })
                this.setState({ subject: res[1].data })
            })
            .catch((err) => {
                //console.log(err[0]);
                //console.log(err[1]);
            })
    }
    handlechange = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedrole: selectedvalue })
        this.setState({ selectedroleforvalid: selectedvalue.label })
        if (selectedvalue.label == "Editor") {
            this.setState({ typeshow: "block" })
        }
        else {
            this.setState({ typeshow: "none" })
        }
    }
    handlechangetype = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedtype: selectedvalue })
    }
    handlechangesubject = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedsubject: selectedvalue })
        this.setState({ selectedsubjectforvalid: selectedvalue.label })
    }
    ResetForm = () => {
        // this.setState({selectedsubject:"",selectedtype:  {label: "Select User Type",
        // value: "Cheif"}});
        // this.setState({ selectedrole: {label:"",value:"" }});
        // this.setState({ typeshow: "none" });
        document.getElementById("firstname").removeAttribute('value');
        document.getElementById("lastname").removeAttribute('value');
        document.getElementById("username").removeAttribute('value');
        document.getElementById("password").removeAttribute('value');
        document.getElementById("address").removeAttribute('value');
        document.getElementById("email").removeAttribute('value');
        document.getElementById("adminphone").removeAttribute('value');
        // this.setState({selectedsubject:"",selectedtype:  {label: "Select User Type",
        // value: "Cheif"}});
        // this.setState({ selectedrole: {label:"",value:"" }});
        // this.setState({ typeshow: "none" })
        // document.getElementById("adminphone").setAttribute("");
        // this.setState({ phone: "999" })
        // //console.log("helo");
    //     // this.setState({selectedtype:  {label: "Select User Type",
    //     // value: "Cheif" }})
      }
     

    // static contextType = AppContext;
    insertData = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false });

        Axios.post('//' + this.state.server + '/controller/CreateUserController.php',
            {
                firstname: this.firstname.value,
                lastname: this.lastname.value,
                username: this.username.value,
                password: this.password.value,
                address: this.address.value,
                email: this.email.value,
                phone: this.state.phone,
                role: this.state.selectedrole.value,
                subject: this.state.selectedsubject.value,
                type: this.state.selectedtype.value,

            }
        )
            .then(function (res) {
                //console.log(res);
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })

                    // alert(res.data.Msg);
                    // this.sendmail();
                    window.location.reload();
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
        //console.log(this.state.selectedtype.value);
        // //console.log(this.state.selectedtype);
    }

    render() {
        const type = [
            {
                value: "Chief",
                label: "Chief",
            },
            {
                value: "Assistant",
                label: "Assistant",
            },
        ]

        return (
            <>
                <div className="container-fluid ">
                    <div className="row d-flex justify-content-center" >
                        <div className="col-6" id="ss-sad-login-007">
                            <div id="ss-sad-login-008" class="card-header text-left">
                                <div class="ss-sad-login-009" >Create User</div>
                            </div>

                            <div className="container" >
                                <form onSubmit={(event) => this.ToOpenModel(event, "submit")} onReset={this.ResetForm}>
                                    {/* <form onSubmit={this.insertData}> */}
                                    <center>
                                        <div className="form">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">User Name</label> */}
                                                        <input id="firstname" type="text" name="firstname" ref={(val) => this.firstname = val} className="form-control" placeholder="First Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Password</label> */}
                                                        <input id="lastname" type="text" name="lastname" ref={(val) => this.lastname = val} className="form-control" placeholder="Last Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="username" type="text" name="username" ref={(val) => this.username = val} className="form-control" placeholder="User Name" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["username"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="password" type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Password" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <textarea id="address" type="text" name="address" ref={(val) => this.address = val} className="form-control" placeholder="Address" required></textarea>
                                                        <span style={{ color: "red" }}>{this.state.errors["address"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="email" type="email" name="email" ref={(val) => this.email = val} className="form-control" placeholder="Offical Email" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <PhoneInput
                                                            id="adminphone"
                                                            placeholder="Enter phone number"
                                                            name="phone"
                                                            onChange={this.phoneinput}
                                                            required
                                                        // style={{ border: '1px solid #ced4da',borderRadius:'.25rem',height:'calc(1.5em + .75rem + 2px)', width: "100%"}}
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={this.handlechange}
                                                            options={this.state.role}
                                                            value={this.state.selectedrole}
                                                            placeholder="Select Role"
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["role"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={this.handlechangesubject}
                                                            options={this.state.subject}
                                                            value={this.state.selectedsubject}
                                                            placeholder="Select Subject"
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["subject"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: this.state.typeshow }}>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={this.handlechangetype}
                                                                options={type}
                                                                value={this.state.selectedtype}
                                                                placeholder="Select Editor Type"
                                                            />
                                                            <span style={{ color: "red" }}>{this.state.errors["role"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-center">
                                                <button type="submit" id="ss-sad-login-014" class="btn btn-block col-3" >Submit</button>
                                                <button type="reset" id="reset"  class="btn btn-block col-3">Reset</button>
                                            </div>
                                        </div>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <h2>Do you want to submit this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn " onClick={this.insertData}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.insertData}>OK</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>CANCEL</button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForResponse: false })}>
                    <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                    <div className="col-9" style={{ paddingTop: "6px" }}>
                        <p id="popup-title">Remark</p>
                        {/* <p id="popup-content">{this.state.response}</p> */}
                        <p id="popup-content">{this.state.response}</p>

                    </div>
                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                    </div>
                    </div>
                
                
                    
                    {/* <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button> */}
                </Modal>
            </>
        );
    }
}