import { React, Axios, Modal, MDBDataTable, validator, $, dt } from '../../../Headers';
// import Header from '../../Header';

// import { Component } from 'react';

export default class Country extends React.Component {
    constructor() {
        super();
        this.state = {
            CountryData: [],
            countryid: '',
            formAction: "ADD",
            modelIsOpenForSubmit: false,
            modelIsOpenForInactive: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            server: ''
        }
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let countryname = this.countryname.value;

        //Country Name

        if (!validator.isAlpha(countryname, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["countryname"] = "Only letters";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    EditCountryData = (CountryData) => {
        this.setState({ formAction: "EDIT" });
        this.setState({ countryid: CountryData.country_id })
        this.EditForm(CountryData);
    }

    EditForm = (ExistingCountry) => {
        document.getElementById("Country").setAttribute('value', ExistingCountry.country_name);
    }

    ResetForm = () => {
        this.setState({ formAction: 'add' });
        document.getElementById("Country").removeAttribute('value');
    }

    SubmitCountry = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false });
        if (this.state.formAction == "ADD") {
            // if(this.handleValidation()) {
            Axios.post('//' + this.state.server + '/controller/CountryController.php',
                {

                    countryname: this.countryname.value.toUpperCase(),
                },
            )
                .then(function (res) {
                    if (res.data.success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    //console.log(res);
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });

        }

        else if (this.state.formAction == "EDIT") {
            Axios.put('//' + this.state.server + '/controller/EditCountryController.php',
                {
                    countryid: this.state.countryid,
                    countryname: this.countryname.value,

                })
                .then(function (res) {
                    if (res.data.success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
            this.setState({ formAction: "ADD" });
            this.setState({ countryid: "" });
        }
    }

    InactiveCountry = () => {
        this.setState({ modelIsOpenForInactive: false });
        // alert(ArticleConfigToInactive);
        // event.preventDefault();
        // event.persist();
        Axios.put('//' + this.state.server + '/controller/InactiveCountryController.php',
            {
                countryid: this.state.countryid
            })
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    };

    // DeleteCountry = (CountryToDelete) => {
    //     // alert(ArticleConfigToInactive);
    //     // event.preventDefault();
    //     // event.persist();
    //     Axios.put('//' + this.state.server + '/controller/DeleteCountryController.php',
    //         {
    //             countryid: CountryToDelete
    //         })
    //         .then(function (res) {
    //             if (res.data.success == 1) {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //             else {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //         }
    //             .bind(this))
    //         .catch(function (error) {
    //             //console.log(error);
    //         });
    // };

    ToOpenModel = (event, button, idForInactive) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });
            }
        }
        else if (button == "inactive") {
            this.setState({
                modelIsOpenForInactive: true,
                countryid: idForInactive
            })
        }
    }

    componentDidMount() {
        const server = window.$name;
        ////console.log(server);
        this.setState({ server: server })
        //console.log(this.state.formAction);
        Axios.post('//' + window.$name + '/controller/GetCountryForTableController.php',
            {
                
            })

            .then((res) => {
                this.setState({ CountryData: res.data });
                //this.setState({articles:res.data});
                //console.log(this.state.CountryData);

                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });

            })
            .catch((error) => {
                //console.log(error[0]);
                //console.log(error[1]);
            });

        // //console.log(localStorage.getItem('user_lastname')[0])
        // this.setState({name:res.data})
    }

    render() {
        return (
            <>
                <section class="content">
                    <div id="country_form" class="container d-flex justify-content-center">
                        <div className="col-6" id="ss-sad-login-007">
                            <div id="ss-sad-login-008" class="card-header text-left">
                                <div class="ss-sad-login-009" >Create Country</div>
                            </div>
                            <div className="container ">
                                <form onSubmit={(event) => this.ToOpenModel(event, "submit", "")} onReset={this.ResetForm}>
                                    {/* <form onSubmit={this.SubmitCountry} onReset={this.ResetForm}> */}
                                    <center>
                                        <div className="form">
                                            <div className="form-group ">
                                                {/* <label className="font-weight-bold">Country Name</label> */}
                                                <input id="Country" type="text" name="countryname" ref={(val) => this.countryname = val} className="form-control" placeholder="Country Name" required />
                                                <span style={{ color: "red" }}>{this.state.errors["countryname"]}</span>
                                            </div>

                                            {/* <div> */}
                                            <div className="row d-flex justify-content-center">
                                                <button type="submit" id="ss-sad-login-014" className="btn btn-block col-3" >Submit</button>
                                                <button type="reset" className="btn btn-block col-3"  id="reset">Reset</button>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </center>
                                </form>

                            </div>

                        </div>

                    </div>
                    {/* <MDBDataTable striped bordered hover data={data} />; */}
                    <div className="container-fluid mt-3">
                        <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "20%" }}>#</th>
                                    <th style={{ width: "20%" }}>Country Name</th>
                                    <th style={{ width: "20%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.CountryData.map((data) =>
                                    <tr>
                                        <td style={{ width: "20%" }}></td>
                                        <td style={{ width: "20%" }}>{data.country_name}</td>
                                        <td style={{ width: "20%" }}>
                                            <div className="btn-group">
                                                <a href="#country_form"><button type="button" class="btn-lg  responsive_btn m-2" id="edit" title="Edit" value="Edit" onClick={() => this.EditCountryData(data)} ><i class="far fa-edit"></i></button></a>
                                                <button type="button" class="btn-lg  responsive_btn m-2" id="inactive" title="Inactive" value="Inactive" onClick={(event) => this.ToOpenModel(event, "inactive", data.country_id)} ><i class="far fa-eye-slash"></i></button>
                                                {/* <button type="button" class="btn-lg  responsive_btn m-2" id="delete" title="Delete" value="Delete" onClick={() => this.DeleteCountry(data.country_id)} ><i class="far fa-trash-alt"></i></button> */}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* </div> */}
                </section>
                {/* </div> */}
                {/* <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <h2>Do you want to submit this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.SubmitCountry}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.SubmitCountry}>OK</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>CANCEL</button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <h2>Do you want to inactive this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.InactiveCountry}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForInactive: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to inactive this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.InactiveCountry}>OK</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForInactive: false })}>CANCEL</button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                    <div className="col-9" style={{ paddingTop: "6px" }}>
                        <p id="popup-title">Remark</p>
                        {/* <p id="popup-content">{this.state.response}</p> */}
                        <p id="popup-content">{this.state.response}</p>

                    </div>
                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                    </div>
                    </div>
                
                
                    
                    {/* <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button> */}
                </Modal>

            </>
        );
    }
}
