import React, { Component } from 'react';
import { Axios } from '../../../Headers';
import JournalNav from "./JournalNav";
export default class PageCharges extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            PageChargesData: [],
            Journal_id: "",
            JournalName: "",
            JournalData: "",
        }
    }
    componentDidMount() {

        const journal_id = this.props.match.params.journal_id;
        this.setState({Journal_id: journal_id})

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetJournalsDetailsForWebController.php',
            {
                journal_id: journal_id,
            }),
            Axios.post('//' + window.$name + '/controller/GetJournalByIdController.php',
            {
                journal_id: journal_id
            })
        ])
            .then((res) => {
                //console.log(res)
                if(res[0].data.Success==1)
                {
                this.setState({ PageChargesData: res[0].data.Data[0]['page_charges'] })
                }
                this.setState({ JournalData: res[1].data[0] });
                this.setState({ JournalName: res[1].data[0].journal_name})
            })
    }
    render() {
        return (
            <>
             {/* <JournalNav JournalName={this.state.JournalName} JournalId={this.state.Journal_id} /> */}
            <div className="row mb-5">
            <div className="col-md-4" style={{ padding: "35px" }}>
                        {/* <img src={"../../uploads/journals/" + data.journal_image} style={{ height: "inherit", width: "inherit" }} /> */}
                        <div className="sort-card">
                            {/* <Link to={'/journalspecific/'+data.journal_id}> */}

                            {/* <div class="hover_color_bubble"></div> */}
                            <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + this.state.JournalData.journal_image + ")", backgroundSize: "cover" }}>
                            </div>
                            <div style={{ padding: "25px", height: "55%" }}>
                                <div class="sort-card-title">
                                    {this.state.JournalData.journal_name}
                                </div>

                                <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                                    {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                                    {this.state.JournalData.journal_description}
                                </div>
                                {/* <button  type="button">
              <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
                                <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
                            </div>
                            {/* </Link> */}
                        </div>
                    </div>
                <div className="col-md-8" style={{ padding: "25px" }}>
                    <div >
                        {/* <div> */}
                        <p id="home-heading" style={{fontSize:"35px"}}>Page Charges</p>
                        <div style={{ borderTop: "2px solid black", width: "10%" }}></div>
                        <ul>
                            {/* <li>
        <p id="prp-subheading">What is editorial board?</p> */}
                            <p id="prp-content">{this.state.PageChargesData}</p>
                            {/* <p id="prp-content">Page charges seem to be almost as common as colour charges, but there isn’t much logic as to which journals charge for what.</p> */}
                            {/* </li> */}
                            {/* <li> */}
                            {/* <p id="prp-subheading">The Editorial Board, or (Editorial) Advisory Board, is a team of experts in the journal's field. Editorial board members:</p> */}
                            {/* <li>
        <li> */}
                            {/* <p id="prp-content">per printed page for the first 8 pages and $160 per page thereafter. Articles containing eight or more figures and/or tables cost an additional $150 per figure or table.</p>   */}
                            {/* </li> */}
                            {/* <li> */}
                            {/* <p id="prp-content">per page for the first nine pages and $160 per page thereafter (with discounts for society members).</p>   */}
                            {/* </li> */}
                            {/* </li> */}
                        </ul>
                        {/* </div> */}
                        {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
<button id="journal-view-btn">Learn More</button>
</div> */}
                    </div>
                </div>
            </div>
            </>
        );
    }
}