import { React, Axios, Link, Redirect } from "../../../Headers"
// import Header from './Header'
import JournalNav from "./JournalNav";




export default class JournalSpecfic extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            response: '',
            errors: {},
            server: '',
            journals: "",
            selectedjournals: "",
            country: "",
            selectedcountry: "",
            selectedjournalsforvalid: '',
            selectedcountryforvalid: '',
            selectedtitle: "",
            selectedtitleforvalid: "",
            // ArticleData: "",
            JournalData: [],
            JournalName:"",
            Journal_id:"",

        }
    }

//     sendData = () => {
//         this.props.parentCallback("Hey Popsie, How’s it going?");
//    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })

        const journal_id = this.props.match.params.journal_id;
        this.setState({Journal_id:journal_id});

        Axios.post('//' + window.$name + '/controller/GetJournalByIdController.php',
            {
                journal_id: journal_id
            })
            .then((res) => {
                //console.log(res);
                //console.log(res.data[0]);
                // //console.log(res.data[0]);
                // this.setState({ ArticleData: res.data[0] })
                this.setState({ JournalData: res.data[0] });
                this.setState({ JournalName: res.data[0].journal_name});
                // //console.log(this.state.JournalData);
                // this.setState({ file: "//localhost/office/SORTSOL/frontend/public/uploads/" + res.data[0].upload });
                // this.setState({ file: window.$file});
            })
            .catch((err) => {
                //console.log(err);
            })
    }
    // <div class="card">
    //                                         <div class="card-header">
    //                                             {data.journal_name}
    //                                         </div>
    //                                         <div class="card-body">
    //                                             <h5 class="card-title">Description :- { data.journal_description}</h5>
    //                                             <img src={"../../uploads/journals/" + data.journal_image} style={{height:"50px",width:"50px"}} />

    //                                         </div>
    //                                     </div> 

    render() {
        return (
            <>
            {/* <JournalNav JournalName={this.state.JournalName} JournalId={this.state.Journal_id} /> */}
            <div className="row mb-5">
            <div className="col-md-4" style={{ padding: "35px" }}>
                        {/* <img src={"../../uploads/journals/" + data.journal_image} style={{ height: "inherit", width: "inherit" }} /> */}
                        <div className="sort-card">
                            {/* <Link to={'/journalspecific/'+data.journal_id}> */}

                            {/* <div class="hover_color_bubble"></div> */}
                            <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + this.state.JournalData.journal_image + ")", backgroundSize: "cover" }}>
                            </div>
                            <div style={{ padding: "25px", height: "55%" }}>
                                <div class="sort-card-title">
                                    {this.state.JournalData.journal_name}
                                </div>

                                <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                                    {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                                    {this.state.JournalData.journal_description}
                                </div>
                                {/* <button  type="button">
              <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
                                <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
                            </div>
                            {/* </Link> */}
                        </div>
                    </div>
                    <div className="col-md-8"  style={{ padding:"35px 0px 35px 35px"}}>
                    <div>
            <p id="home-heading" style={{fontSize:"35px"}}>Journal Details</p>
            <div style={{ borderTop: "2px solid black", width: "10%", marginBottom: "20px" }}></div>

            {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
            <button id="journal-view-btn">Learn More</button>
           </div> */}
          </div>
          <div style={{padding:"0 0"}}>
                        {/* <b id="prp-subheading" className="b_title" style={{fontSize:"30px",color:"rgb(125, 121, 135)",marginBottom:"10px"}}>{this.state.JournalData.journal_name}</b> */}
                        {/*<p class="editor_name">Editor-in-chief : <span>Dr. Dinesh Kansal,</span></p> */}
                        <p className="journal_subheading"><b>Abbreviation : </b><span>Indian</span></p>
                        <p className="journal_subheading"><b>ISSN : </b><span>2393-9079</span></p>
                        <p className="journal_subheading"><b>E-ISSN : </b><span>2393-9087</span></p>
                        {/*-<p class="editor_name">CODEN : </b><span>IJPPTK</span></p>---*/}
                        <p className="journal_subheading"><b>Frequency : </b><span>Quarterly</span></p>
                        {/* <p className="editor_name"><b>Subjects: </b><span>Main Subject is &nbsp;Pharmacy&nbsp; And Sub Subject Pharmacy, Pharmacology, Pharmaceutical, Clinical Pharmacology, Pharmacy Practices, Social Pharmacy, Experimental Pharmacology, Clinical trials, drug delivery, pharmacy practices, social pharmacy, experimental pharmacy, pharmacy education, pharmaceutical marketing, clinical pharmacology, experimental pharmacology, clinical trials, medical education in pharmacology Pharmaceutics, drug delivery, experimental &amp; cli</span></p> */}
                        <p className="journal_subheading"><b>Keywords : </b><span>Pharmacy, Pharmacology, Clinical Pharmacology, Experimental Pharmacology, Clinical trials, Drug Delivery, Drug Targeting, Experimental Pharmacy, Experimental Preparation, Pharmacy Practice, Pharmacy Education.</span></p>
                        <p className="journal_subheading"><b>Current Volume :</b><span> 8</span></p>
                        <p className="journal_subheading"><b>Journal Website: </b><span> <a href="journalhome" target="_blank">www.journal.com</a></span></p>
                        <p className="journal_subheading"><b>Submit Manuscript : </b><span> <a href="journalhome" target="_blank">www.sortsol.com/journal</a></span></p>
                        <p className="journal_subheading"><b style={{width:"max-content"}}>About us :</b><span>Pacific Journal open access, peer-reviewed quarterly journal publishing since 2021.&nbsp;<a href="journalhome" /></span></p>
                        {/* <p class="editor_name_a_div"><a href="#" target="_blank" class="button_title_div">Open Journal</a></p>*/}
                    </div>
                    </div>
                    
                    <div className="container-fluid d-flex justify-content-end">
                <button id="home-publish-btn" >
                        <Link to='/sortsollogin' style={{color:"white"}}>Join Us</Link></button>
                    </div>

                </div>
                
                </>
        );
    }
}