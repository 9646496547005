import {React,Link,Redirect} from '../../../Headers'

class AppHeader extends React.Component {
  constructor(){
    super();
    this.state = {
      Redirectstate: false,
      name:"",
    }
  }

  componentDidMount() {
    const value=localStorage.getItem('user');

    if(value !== null)
    {
     this.setState({Redirectstate:false});
     this.state.Redirectstate = false;
     //console.log(this.state.Redirectstate);
    //  localStorage.getItem('user_firstname')[0]+""+localStorage.getItem('user_lastname')[0]
     this.setState({name:localStorage.getItem('user_firstname')[0]+""+localStorage.getItem('user_lastname')[0]})
    }
    else{
     this.setState({Redirectstate:true});
     //console.log(this.state.Redirectstate);
    }

  }
 render(){
  return ((this.state.Redirectstate)?<Redirect to='/login'/>:
    <nav class="main-header navbar navbar-expand  navbar-dark" id="ss-uad-header-01">
    {/* <!-- Left navbar links --> */}
    <ul class="navbar-nav">
    <li class="nav-item">
            <Link class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars" style={{color:"#333333"}}></i></Link>
          </li>
      {/* <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars" style={{color:"#333333"}}></i></a>
      </li> */}
      {/* <svg class="svg-inline--fa fa-bars fa-w-14" aria-hidden="true" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg> */}
      {/* <li class="nav-item d-none d-sm-inline-block">
        <Link to={'/super_dashboard'} class="nav-link">Home</Link>
      </li> */}
      {/* <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li> */}
    </ul>

    {/* <!-- Right navbar links --> */}
    <ul class="navbar-nav ml-auto">
      {/* <!-- Navbar Search --> */}
      <li class="nav-item" style={{display:"none"}}>
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"/>
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>

      {/* <!-- Messages Dropdown Menu --> */}
      <li class="nav-item dropdown" style={{display:"none"}}>
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item">
            {/* <!-- Message Start --> */}
            <div class="media">
              <img src="dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle"/>
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            {/* <!-- Message End --> */}
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            {/* <!-- Message Start --> */}
            <div class="media">
              <img src="dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3"/>
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            {/* <!-- Message End --> */}
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            {/* <!-- Message Start --> */}
            <div class="media">
              <img src="dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3"/>
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                  <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            {/* <!-- Message End --> */}
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
        </div>
      </li>
      {/* <!-- Notifications Dropdown Menu --> */}
      <li class="nav-item dropdown" style={{display:"none"}}>
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      <li class="nav-item" style={{display:"none"}}>
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item" style={{display:"none"}}>
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li>
      <li class="nav-item" style={{color:"#FA9225",margin:"auto"}}>
        {/* {localStorage.getItem('user_firstname')+" "}{localStorage.getItem('user_lastname')} */}
        {/* <div id="ss-uad-header-02">UN</div> */}
        <div id="ss-uad-header-02">{this.state.name}</div>
      </li>
      <li class="nav-item" style={{color:"#FA9225",margin:"auto"}}>
        {/* {localStorage.getItem('user_firstname')+" "}{localStorage.getItem('user_lastname')} */}
        <div id="ss-uad-header-03">
          <p id="ss-uad-header-04">{localStorage.getItem('user_firstname')}</p>
          <p id="ss-uad-header-05">ADMIN</p>
          </div>
      </li>
      <li class="nav-item" style={{display:"none"}}>
      <Link class="nav-link" to={'/logout'} title="Log out" role="button">
              <i class="fas  fa-sign-out-alt"></i>
      </Link>
      </li>
    </ul>
  </nav>
  )
}
}

export default AppHeader
