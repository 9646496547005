import React from 'react';
// import { $, Axios, Modal,Redirect,validator } from '../../../Headers'

import ContactUs from '../../ContactUs';

export default class ContactForm extends React.Component {
    
    render() {
        
        return (<>
        <ContactUs/>
        </>
        );
    }
}