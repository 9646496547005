import {React,Redirect,Axios} from '../../../Headers'
import AppHeader from './AppHeader'
//import AppSidebar from './AppSidebar'
import AppContent from './AppContent'
import AppFooter from './AppFooter'
import JournalNav from '../components/JournalNav'

export default class DefaultLayout extends React.Component {
  constructor(){
    super();
    this.state = {
      Redirectstate: false
    }
  }

    componentDidMount(){
      const value=localStorage.getItem('user');
      // const value=localStorage.getItem('org_id');
      console.log(localStorage.getItem('user'));
      console.log(value);
      const journal_id = this.props.match.params.journal_id;
      this.setState({JournalId: journal_id});
      console.log(journal_id);
      Axios.post('http://' + window.$name + '/controller/GetJournalByIdController.php',
  {
      journal_id: journal_id
  }).then((res) => {
    // console.log(res);
    console.log(res.data[0].journal_name);
    // console.log(res[0].data[0]);
    // this.setState({ ArticleData: res.data[0] })
    // this.setState({ ArticleData: res[0].data })
    // this.setState({ JournalData: res[1].data[0] });
    this.setState({ JournalName: res.data[0].journal_name})
    // this.setState({ file: "http://localhost/office/SORTSOL/frontend/public/uploads/" + res.data[0].upload });
    // this.setState({ file: window.$file });
  })
  .catch((err) => {
    console.log(err);
  })
      // this.setState({data:value});
    //  if(value==null)
    //  {
    //   this.setState({Redirectstate:true});
    //   console.log(this.state.Redirectstate);
    //  }
    //  else{
    //    this.setState({Redirectstate:false});
    //  }
    //  console.log(this.state.Redirectstate);
      }

  render(){
  return ((this.state.Redirectstate)?<Redirect to='/login'/>:
    // <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
      <div class="wrapper">
         <AppHeader />
         <JournalNav JournalName={this.state.JournalName} JournalId={this.state.JournalId}/>
      {/* <AppSidebar /> */}
      {/* <div className="wrapper d-flex flex-column min-vh-100 bg-light"> */}
       
        {/* <div className="body flex-grow-1 px-3" style={{height:"92vh",overflowY:"scroll"}}> */}
        <div className="container-fluid">
          <AppContent />
        </div>
         {/* <AppFooter />  */}
      {/* </div> */}
    </div>
    // </body>
  );
}
}