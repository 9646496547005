import {Link} from "../../../Headers"
import React from 'react';
import { NavLink } from "react-router-dom";
export default class Menu extends React.Component {

    render() {
        return (
            <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
            <NavLink to={'/manuscript/editor/pending'} title="Pending" id="sort-table-nav-btn" className="btn btn-outline-info m-2" activeClassName="active"><i class="nav-icon fas fa-exclamation"></i></NavLink>
            <NavLink to={'/manuscript/editor/approve'} title="Approve" id="sort-table-nav-btn" className="btn btn-outline-success m-2" activeClassName="active"><i class="fas  fa-check"></i></NavLink>
            <NavLink to={'/manuscript/editor/reject'} title="Reject" id="sort-table-nav-btn" className="btn btn-outline-danger m-2" activeClassName="active"><i class="fas  fa-times"></i></NavLink>
       </div>
        );
    }   
}    