// import React from 'react'

import Dashboard from './components/User_Dashboard'
import Admin from './components/Admin'
import Article from './components/Article'
import AdminApprove from './components/AdminApprove'
import AdminReject from './components/AdminReject'
import AdminHistory from './components/AdminHistory'
import AdminPublish from './components/AdminPublish'
import AdminToPublish from './components/AdmintoPublish'
import SubDetails from './components/SubscriptionDetails'
import ApproveSubDetails from './components/ApproveSubscriptionDetails'
import AllArticle from './components/AllArticle'
import ArticleDetails from './components/ArticleDetails'
import CreateJournals from './components/CreateJournals'
import JournalsDetails from './components/JournalsDetails'
import AuthorFormDetails from './components/AuthorFormDetails'



const routes = [

  { path: '/user_dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/manuscript/admin/pending', exact: true, name: 'Admin', component: Admin },
  { path: '/manuscript/admin/approve', exact: true, name: 'AdminApprove', component: AdminApprove },
  { path: '/manuscript/admin/reject', exact: true, name: 'AdminReject', component: AdminReject },
  { path: '/articlehistory/:article_id/:status', exact: true, name: 'AdminHistory', component: AdminHistory },

  { path: '/article/:article_id', exact: true, name: 'Article', component: Article },
  { path: '/manuscript/admin/adminpublish', exact: true, name: 'AdminPublish', component: AdminPublish },
  { path: '/admintopublish/:article_id/:status', exact: true, name: 'AdminHistory', component: AdminToPublish },
  { path: '/admin/subs/pending', exact: true, name: 'SubDetails', component: SubDetails },
  { path: '/admin/subs/approve', exact: true, name: 'SubDetails', component: ApproveSubDetails },
  { path: '/manuscript/admin/all', exact: true, name: 'AllArticle', component: AllArticle },
  { path: '/articledetails/:article_id', exact: true, name:'ArticleDetails', component:ArticleDetails},
  { path: '/create_journals', exact: true, name:'CreateJournal', component: CreateJournals},
  { path: '/journals_details', exact: true, name:'JournalDetails', component: JournalsDetails},
  { path: '/author_form_details', exact: true, name:'AuthorFormDetails', component: AuthorFormDetails}

]

export default routes
