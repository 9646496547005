import React, { Component } from "react";
export default class JournalCopyright extends React.Component {
    render() {
	    return (
        <div className="row mb-5" style={{width:"100%"}}>
         <div className="col-6">
               <img src={"../../uploads/Copyright.png"} style={{width:"inherit",filter:"drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",height:"450px",padding:"15px"}} />
           </div>
        <div className="col-6" style={{padding:"35px"}}>
           <div>
               <p id="journal-title">Copyright</p>
               <div class="sort_12"></div>
                        <ul id="block-ul">
                        <li><p id="block-content">Copyright on any open access article in a journal published by SpringerOpen is retained by the author(s).</p></li>
                        <li><p id="block-content">Authors grant SpringerOpen a license to publish the article and identify itself as the original publisher.</p></li>
                        <li><p id="block-content">Authors also grant any third party the right to use the article freely as long as its integrity is maintained and its original authors, citation details and publisher are identified.</p></li>
               
               </ul>
               {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
        <button id="journal-view-btn">Learn More</button> */}
          </div>
        </div>
      </div>
      //  </div>
    );
  }
}
