import { $, React, Axios, validator, ImageUploader, Modal, Select, dt } from '../../../Headers';
// import Header from '../../Header'


export default class CreateJournals extends React.Component {
    constructor() {
        super();
        this.state = {
            JournalsData: [],
            formAction: "ADD",
            journal_id: "",
            modelIsOpenForSubmit: false,
            modelIsOpenForInactive: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            articleType: '',
            editRecordId: '',
            server: '',
            articleTypeforvalid: '',
            subject: '',
            selectedsubject: "",
            selectedsubjectForValid: "",
            picture: "",
        }
        this.ondrop = this.ondrop.bind(this)
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let journals_name = this.journals_name.value;
        let journals_description = this.journals_description.value;
        let journals_subject = this.state.selectedsubjectForValid;
        // //console.log(articletypeselected);
        //article name

        if (!validator.isAlpha(journals_name, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["journals_name"] = "Only letters";
        }
        if (!validator.isAlphanumeric(journals_description, ['en-GB'], { ignore: " " })) {
            if (!journals_description.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["journals_description"] = "Only letters";
            }
        }
        if (validator.isEmpty(journals_subject)) {
            formIsValid = false;
            errors["journals_subject"] = "Only letters";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    ondrop(pictures) {
        this.setState({
            picture: pictures
        })
        //console.log(this.state.picture)
    }

    EditJournalData = (JournalData) => {
        this.setState({ formAction: "EDIT" });
        this.setState({ journal_id: JournalData.journal_id });
        //console.log(JournalData);



        document.getElementById("journals_name").setAttribute('value', JournalData.journal_name);
        document.getElementById("journals_description").setAttribute('value', JournalData.description);
        // document.getElementById("journals_subject").setAttribute('value', JournalData.subject_id,'label',JournalData.subject_name);
        this.setState({ selectedsubject: { value: JournalData.subject_id, label: JournalData.subject_name } });
        // document.getElementById("DGDescription").setAttribute('value', ArticleData.dg_description);

        // To set the existing values into the form...
        //this.setState({selectedDestinations:JSON.parse(ExistingCombo.dg_destinations)});
    }
    handlechangesubject = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedsubject: selectedvalue })
        this.setState({ selectedsubjectForValid: selectedvalue.value })
    }

    ResetForm = () => {
        this.setState({ formAction: 'ADD',selectedsubject:'',picture: '' });
        // this.setState({ selectedsubject: '' });
        // this.setState({ picture: ''})
        document.getElementById("journals_name").removeAttribute('value');
        document.getElementById("journals_description").removeAttribute('value');
        document.getElementById("journals_description").removeAttribute('value');
    }

    SubmitJournals = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false });
        // //console.log(this.state.selectedoption);
        const fd = new FormData();

        fd.append('journalsname', this.journals_name.value.toUpperCase());
        fd.append('journalsdescription', this.journals_description.value);
        fd.append('journalssubject', this.state.selectedsubject.value);
        fd.append('journalsimages', this.state.picture[0]);
        if (this.state.formAction == "ADD") {
            Axios.post('//' + this.state.server + '/controller/AddJournalsController.php', fd

            )
                .then(function (res) {
                    if (res.data.Success == 1) {
                        this.setState({
                            response: res.data.value.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    else {
                        this.setState({
                            response: res.data.value.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    // //console.log(res)
                    // //console.log(res.data.value.Msg)
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
        }
        else if (this.state.formAction == "EDIT") {
            fd.append('journal_id', this.state.journal_id);
            fd.append('journalsname', this.journals_name.value.toUpperCase());
            fd.append('journalsdescription', this.journals_description.value);
            fd.append('journalssubject', this.state.selectedsubject.value);
            fd.append('journalsimages', this.state.picture[0]);
            Axios.post('//' + this.state.server + '/controller/EditJournalController.php',fd
                // {
                    // journal_id: this.state.journal_id,
                    // journalsname: this.journals_name.value,
                    // journalsdescription: this.journals_description.value,
                    // journalssubject: this.state.selectedsubject.value,
                // }
                )
                .then(function (res) {
                    if (res.data.Success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
            this.setState({ formAction: "ADD" });
            this.setState({ articleid: "" });
        }
    }

    InactiveArticle = () => {
        this.setState({ modelIsOpenForInactive: false });
        // alert(ArticleConfigToInactive);
        // event.preventDefault();
        // event.persist();
        Axios.post('//' + this.state.server + '/controller/InactiveJournalController.php',
            {
                journal_id: this.state.journal_id,
            })
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    };

    // DeleteArticle = (ArticleToDelete) => {
    //     // alert(ArticleConfigToInactive);
    //     // event.preventDefault();
    //     // event.persist();
    //     Axios.post('//' + this.state.server + '/controller/DeleteJournalController.php',
    //         {
    //             journal_id: ArticleToDelete,
    //         })
    //         .then(function (res) {
    //             if (res.data.success == 1) {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //             else {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //         }
    //             .bind(this))
    //         .catch(function (error) {
    //             //console.log(error);
    //         });
    // };

    ToOpenModel = (event, button, idForInactive) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });
            }
        }
        else if (button == "inactive") {
            this.setState({
                modelIsOpenForInactive: true,
                journal_id: idForInactive
            })
        }
    }

    componentDidMount() {
        const server = window.$name;
        ////console.log(server);
        this.setState({ server: server })
        // //console.log(this.state.formAction);

        Axios.all(
            [
                Axios.post("//" + window.$name + "/controller/GetJournalsForTableController.php",
                    {

                    }),
                Axios.post('//' + window.$name + '/controller/GetSubjectController.php'),
            ])
            .then((res) => {
                this.setState({ JournalsData: res[0].data });
                this.setState({ subject: res[1].data })
                //console.log(this.state.JournalsData);
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
            })
            .catch((error) => {
                //console.log(error[0]);
                // //console.log(error[1]);
            });

        // //console.log(res)
        // this.setState({name:res.data})
    }
    render() {

        return (
            <>
                <section class="content ">
                    <div id="Journal_form" class="container d-flex justify-content-center">
                        <div className="col-6" id="ss-sad-login-007">
                            <div id="ss-sad-login-008" class="card-header text-left">
                                <div class="ss-sad-login-009" style={{textAlign:"center"}}>Create Journal</div>
                            </div>
                            <div className="container">
                                <form onSubmit={(event) => this.ToOpenModel(event, "submit", "")} onReset={this.ResetForm}>
                                    {/* <form onSubmit={this.SubmitJournals} onReset={this.ResetForm}> */}
                                    <center>
                                        <div className="form">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Article Name</label> */}
                                                        <input id='journals_name' type="text" name="journals_name" ref={(val) => this.journals_name = val} className="form-control" placeholder="Journals Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["journals_name"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Article Name</label> */}
                                                        <input id='journals_description' type="text" name="journals_description" ref={(val) => this.journals_description = val} className="form-control" placeholder="Journals Description" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["journals_description"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group ">
                                                        {/* <label className="font-weight-bold">Article Name</label> */}
                                                        <Select
                                                            onChange={this.handlechangesubject}
                                                            options={this.state.subject}
                                                            value={this.state.selectedsubject}
                                                            placeholder="Select Subject"
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["journals_subject"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <ImageUploader
                                                        withIcon={false}
                                                        withPreview={true} required
                                                        buttonText="Upload Images"
                                                        onChange={this.ondrop}
                                                        imgExtension={[".jpg", ".png"]}
                                                        maxFileSize={2000000}
                                                        label="Max file size 2mb, accepted: jpg|png"
                                                        fileSizeError=" file size is too big"
                                                    ></ImageUploader>
                                                </div>
                                            </div>
                                            {/* <div className="card-footer"> */}
                                            <div className="row d-flex justify-content-center">
                                                <button type="submit" id="ss-sad-login-014" className="btn btn-block col-3" >Submit</button>
                                                <button type="reset"  className="btn btn-block col-3" id="reset">Reset</button>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </center>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid mt-3">
                        <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Journal Name</th>
                                    <th>Subject</th>
                                    <th>Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.JournalsData.map((data) =>
                                    <tr>
                                        <td></td>
                                        <td>{data.journal_name}</td>
                                        <td>{data.subject_name}</td>
                                        <td>{data.description}</td>
                                        <td>
                                            <div className="btn-group">
                                                <a href="#Journal_form" className="m-2"><button type="button" class="btn-lg  responsive_btn" id="edit" title="Edit" value="Edit" onClick={() => this.EditJournalData(data)} ><i class="far fa-edit"></i></button></a>
                                                <button type="button" class="btn-lg   responsive_btn m-2" title="Inactive" id="inactive" value="Inactive" onClick={(event) => this.ToOpenModel(event, "inactive", data.journal_id)} ><i class="far fa-eye-slash"></i></button>
                                                {/* <button type="button" class="btn-lg  responsive_btn m-2" title="Delete" id="delete" value="Delete" onClick={() => this.DeleteArticle(data.journal_id)} ><i class="far fa-trash-alt"></i></button> */}
                                            </div></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
                {/* </div> */}
                {/* <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <h2>Do you want to submit this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.SubmitJournals}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.SubmitJournals}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <h2>Do you want to inactive this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.InactiveArticle}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForInactive: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to inactive this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.InactiveArticle}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForInactive: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>

                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Remark</p>
                            {/* <p id="popup-content">{this.state.response}</p> */}
                            <p id="popup-content">{this.state.response}</p>
                        </div>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}