import { React, Axios, Link, $, dt } from "../../../Headers"
// import Header from './Header'



export default class ApproveSubscriptionDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            response: '',
            redirect: false,
            redirecttype: "",
            server: '',
            Subdata: [],
        }

    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })

        Axios.post('//' + window.$name + '/controller/GetSubscriptionDetailsController.php',
            {
                status: 1,
            }
        )
            .then((res) => {
                //console.log(res);
                this.setState({ Subdata: res.data })

                var dataTable = $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
                $("#searchbox").on("keyup search input paste cut", function () {
                    dataTable.search(this.value).draw();
                });
            })
            .catch((err) => {
                //console.log(err);
            })
    }

    render() {
        return (
            <div>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <p id="ss-uad-admin-02">Approved Subscriptions</p>
                        </div>
                        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <Link to={'/admin/subs/pending'}><button className="btn btn-outline-info m-2 action-buttons" ><i class="nav-icon fas fa-exclamation"></i></button></Link>
                            <Link to={'/admin/subs/approve'}><button className="btn btn-outline-success m-2 active action-buttons" > <i class="fas  fa-check"></i></button></Link>
                        </div>
                    </div>
                    <div className="card-body" id="card_body">
                        <div className="container-fluid" >
                            <center>
                                <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Start Date</th>
                                            <th>End Data</th>
                                            <th>No Of Copy</th>
                                            <th>Entry Date</th>
                                            <th>Approve Date</th>
                                            <th>Year Of Subscription</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Subdata.map((data) =>
                                            <tr>
                                                <td></td>
                                                <td>{data.email}</td>
                                                <td>{data.start_date}</td>
                                                <td>{data.end_date}</td>
                                                <td>{data.no_of_copy}</td>
                                                <td>{new Date(data.entrydate).toDateString()}</td>
                                                <td>{new Date(data.approve_date).toDateString()}</td>
                                                <td>{data.year_of_sub}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}