import { React, Axios, Link, Redirect, Modal, $, validator, Select } from "../../../Headers"
// import '../layout/FooterInsideWrapper.css'
const loading = (
    <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
)

export default class E_Article extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            manuscript_title: "",
            org_name: "",
            reviewer: "",
            abstract: "",
            email: "",
            firstname: "",
            lastname: "",
            date: "",
            file: "",
            fileType: "",
            fileForPDF: "",
            fileForDOC: "",
            modelIsOpenForReject: false,
            modelIsOpenForAssign: false,
            article_id: "",
            server: "",
            modelIsOpenForResponse: false,
            modelIsOpenForDetails: false,
            modelIsOpenForComment: false,
            modelIsOpenForSuccessResponse: false,
            modelIsOpenForRejectResponse: false,
            modelIsOpenForPreloader:false,
            redirect: false,
            selectedReviewerId: '',
            HistoryData: [],
            pdfshow: "none",
            docshow: "none",
            preloader: "block"
        }

    }

    doc_show = () => {
        if (this.state.fileType == "pdf") {
            this.setState({ pdfshow: "contents", fileForPDF: window.$file + this.state.file, preloader: "none" })
        }
        if (this.state.fileType == "doc" || this.state.fileType == "docx") {
            this.setState({ docshow: "contents", fileForDOC: window.$file + this.state.file, preloader: "none" })
        }
    }

    handlechange = (selected) => {
        // //console.log(selected.value);
        this.setState({ selectedReviewerId: selected.value });
    }

    comment = (event) => {
        event.preventDefault();
        event.persist();

        Axios.post("//" + this.state.server + "/controller/CommentController.php",
            {
                user_id: localStorage.getItem('user'),
                remark: this.remark.value,
                article_id: this.state.article_id,
                role_id: localStorage.getItem('role')
            }
        )
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForComment: false,
                        modelIsOpenForResponse: true
                    })

                    // alert(res.data.Msg);
                    // this.sendmail();
                    // window.location.reload();
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForComment: false,
                        modelIsOpenForResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    }

    approveArticle = (event) => {
        event.preventDefault();
        event.persist();
        // //console.log(this.state.selectedReviewerId + " " + this.approve_remark.value);
        this.setState({modelIsOpenForAssign:false,modelIsOpenForPreloader:true});
        // C:\xampp\htdocs\office\SORTSOL\backend\controller\ApproveArticleController.php
        Axios.post("//" + this.state.server + "/controller/ApproveArticleByEditorController.php",
            {
                // reviewer_id: this.state.selectedReviewerId,
                user_id: localStorage.getItem('user'),
                approve_remark: this.approve_remark.value,
                article_id: this.state.article_id,
                role_id: localStorage.getItem('role')
            }
        )
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForPreloader:false,
                        // modelIsOpenForAssign: false,
                        modelIsOpenForSuccessResponse: true
                    })

                    // alert(res.data.Msg);
                    // this.sendmail();
                    // window.location.reload();
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        // modelIsOpenForAssign: false,
                        modelIsOpenForPreloader:false,
                        modelIsOpenForSuccessResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    }


    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        const article = this.props.match.params.article_id;
        //console.log(article);
        this.setState({ article_id: article });
        //console.log(localStorage.getItem('user'));
        //console.log(localStorage.getItem('role'));
        this.setState({ server: server })

        Axios.all([Axios.post('//' + window.$name + '/controller/GetArticleController.php', {
            article: article,
            status: 4,
        }),
        Axios.post('//' + window.$name + '/controller/GetCommentController.php',
            {
                article: article,
            })
        ])
            .then((res) => {
                //console.log(res[0]);
                //console.log(res[1]);
                // //console.log(res[0].data.value.Article[0].manuscript_title);
                this.setState({ manuscript_title: res[0].data.value.Article[0].manuscript_title });
                this.setState({ org_name: res[0].data.value.Article[0].organization });
                this.setState({ abstract: res[0].data.value.Article[0].abstract });
                this.setState({ email: res[0].data.value.Article[0].email });
                this.setState({ firstname: res[0].data.value.Article[0].firstname });
                this.setState({ lastname: res[0].data.value.Article[0].lastname });
                this.setState({ date: res[0].data.value.Article[0].entrydate });
                // this.setState({ file: res.data.value.Article[0].upload });
                this.setState({ file: res[0].data.value.Article[0].upload });
                this.setState({ fileType: res[0].data.value.Article[0].upload_type });

                this.setState({ reviewer: res[0].data.value.Reviewer })
                //console.log(res[0].data.value.Reviewer);
                this.setState({ HistoryData: res[1].data })
                // this.setState({role: res.data})
                this.doc_show();
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });

            })
            .catch((err) => {
                //console.log(err);
            })
    }


    RejectArticle = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({modelIsOpenForReject:false,modelIsOpenForPreloader:true});
        Axios.post('//' + this.state.server + '/controller/RejectEditorController.php',
            {
                remark: this.remark.value,
                user_id: localStorage.getItem('user'),
                role_id: localStorage.getItem('role'),
                article_id: this.state.article_id,
            }
        )
            .then(function (res) {
                //console.log(res);
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        // modelIsOpenForReject: false,
                        modelIsOpenForPreloader:false,
                        modelIsOpenForRejectResponse: true
                    })

                    alert(res.data.Msg);
                    // this.sendmail();
                    // window.location.reload();
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        // modelIsOpenForReject: false,
                        modelIsOpenForPreloader:false,
                        modelIsOpenForRejectResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });


    }
    ForDetails = () => {
        this.setState({ modelIsOpenForDetails: true });
    }
    Redirecttohome = () => {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to={'/manuscript/editor/pending'} />
        }
        return (
            <>
                <div id="ss-uad-article-01">
                    <div className="row" id="ss-uad-article-02">
                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                            <Link to={'/manuscript/editor/pending'}>
                                <div id="ss-uad-article-03" style={{ backgroundImage: "url('../../uploads/back.png')" }}></div>
                            </Link>
                            {/* <h1 className="" type="button" onClick={this.ForDetails} style={{ textAlign: 'left' }} id="card_header">{this.state.manuscript_title}</h1> */}
                            <div id="ss-uad-article-04">
                                <p id="ss-uad-article-05" type="button" onClick={this.ForDetails}>{this.state.manuscript_title}</p>
                                <p id="ss-uad-article-06">{this.state.org_name}</p>
                            </div>
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-08" className="btn btn-primary mr-2" onClick={() => this.setState({ modelIsOpenForReject: true })}>Reject</button>
                            <button id="ss-uad-article-09" className="btn btn-danger" onClick={() => this.setState({ modelIsOpenForAssign: true })}>Approve</button>
                        </div>
                    </div>

                    <div>
                        <div className="container-fluid" style={{ padding: "0 39px" }} >

                            <div className="row" style={{ height: "72vh" }}>
                                <div className="col-9">
                                    <div className="mt-2 p-2" id="ss-uad-article-13">
                                        <button id="ss-uad-article-14" className="btn btn-warning" onClick={() => this.setState({ modelIsOpenForComment: true })}><i class="fas fa-comment-alt" id="ss-uad-article-15"></i></button>
                                    </div>
                                    <div style={{ display: this.state.pdfshow }}>
                                        <iframe src={this.state.fileForPDF} height="100%" width="100%" frameBorder="0" style={{ borderRadius: "12px" }}></iframe>
                                    </div>
                                    <div id="parent" style={{ display: this.state.docshow }}>
                                        <iframe id="myiframe" src={"https://docs.google.com/gview?url=" + this.state.fileForDOC + "&embedded=true"} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%">
                                            {/* <iframe style={{display:"none",src:"none"}} frameBorder="0" style={{ borderRadius: "12px" }} width="100%" height="100%"> */}
                                        </iframe>
                                    </div>

                                </div>
                                <div className="col-3" style={{ borderRadius: "12px", padding: "0px", background: "#F4F5F7", height: "inherit" }} >
                                    <div className="card" style={{ borderRadius: "12px 12px 0 0", margin: "0" }}>
                                        <div className="card-header" id="ss-uad-article-10">
                                            <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i>
                                            <span id="ss-uad-article-12">Comments</span>
                                        </div>
                                    </div>
                                    <div style={{ height: "90%", overflowY: "auto", padding: "2vh 2vh 0 2vh" }}>
                                    {this.state.HistoryData.map((data, i) =>
                                        
                                            <div class="card" style={{ borderRadius: "12px"}}>
                                                <div class="card-header d-flex" style={{ padding: ".8vw" }}>
                                                    {/* <div className="row"> */}
                                                        <div className="ss-uad-article-16" style={{ paddingRight: "2px",width:"-webkit-fill-available"}}>
                                                            {/* <span className="ss-uad-article-16"><i class="fas fa-comment-alt mr-2"></i></span> */}
                                                            <span className="ss-uad-article-16" style={{paddingRight:"8px",color:"#6c757d"}}>{i + 1}.</span>
                                                            {data.user_name}
                                                        </div>
                                                        <div className="col ss-uad-article-16" style={{fontSize:"xx-small"}}>
                                                            {data.action_taken}
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                                <div class="card-body" style={{padding:"1rem"}}>
                                                    <p class="card-text" id="ss-uad-article-17">{data.remark}</p>
                                                </div>
                                            </div>
                                        
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <Modal isOpen={this.state.modelIsOpenForPreloader} className="popup-modal-preloader" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForPreloader: false })}>
                    {loading}
                    </Modal>
                <Modal isOpen={this.state.modelIsOpenForReject} className="popup-modal-content-reject" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForReject: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForReject: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Reject Manuscript</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">For the next step of rejection you have to fill the remark</p>
                        <form onSubmit={this.RejectArticle}>
                            <center>
                                <div className="form">
                                    <div className="form-group ">
                                        <textarea id="approve_remark" type="text" name="remark" ref={(val) => this.remark = val} className="form-control" placeholder="Remark" required />
                                        {/* <span style={{color: "red"}}>{this.state.errors["countryname"]}</span> */}
                                    </div>
                                </div>
                            </center>

                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <button id="cancel" className="popup-btn" onClick={() => this.setState({ modelIsOpenForReject: false })}>Cancel</button>
                                <button className="popup-btn ml-3" type="submit" onClick={this.RejectArticle}>Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForAssign} className="popup-modal-content-reject" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForAssign: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={() => this.setState({ modelIsOpenForAssign: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Assign a Reviewer</span>
                    </div>

                    <div class="card-body">
                        <p id="assign-popup-box-msg">For the next step of approval you have to assign a Reviewer to review the manuscript</p>
                        <form onSubmit={(event) => this.approveArticle(event)}>
                            <center>
                                <div className="form">

                                    <div className="form-group ">
                                        <textarea id="approve_remark" type="text" name="approve_remark" ref={(val) => this.approve_remark = val} className="form-control" placeholder="Remark" required />
                                        {/* <span style={{color: "red"}}>{this.state.errors["countryname"]}</span> */}
                                    </div>
                                </div>
                            </center>
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <button id="cancel" className="popup-btn " onClick={() => this.setState({ modelIsOpenForAssign: false })}>Cancel</button>
                                <button className="popup-btn ml-3" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForResponse: false })}>
                    {/* <span class="closed" style={{ float: "right", fontSize: "32px" }} type="button" onClick={() => this.setState({ modelIsOpenForResponse: false })}>&times;</span> */}
                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Remark</p>
                            <p id="popup-content">{this.state.response}</p>
                        </div>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={this.Redirecttohome}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen="true" className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSuccessResponse: false })}> */}

                <Modal isOpen={this.state.modelIsOpenForSuccessResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSuccessResponse: false })}>
                    <span class="closed" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={this.Redirecttohome}>&times;</span>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className="popup-circle-check">
                                <i class="fas fa-check" style={{ color: "#6FCF97", fontSize: "x-large" }}></i>
                            </div>
                        </div>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Success</p>
                            {/* <span>{this.state.response}</span> */}
                            <p id="popup-content">{this.state.response}</p>
                            {/* <p id="popup-content">Sucessfully approved and assigined to a reviewer and an email alert has been sent to other users.</p> */}
                        </div>
                    </div>
                    {/* <h4>{this.state.response}</h4> */}
                    {/* <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={this.Redirecttohome}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button> */}

                </Modal>
                <Modal isOpen={this.state.modelIsOpenForRejectResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForRejectResponse: false })}>
                    <span class="closed" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={this.Redirecttohome}>&times;</span>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className="popup-circle-check">
                                <i class="fas fa-times" style={{ color: "#EB5757", fontSize: "x-large" }}></i>
                            </div>
                        </div>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Reject</p>
                            {/* <span>{this.state.response}</span> */}
                            <p id="popup-content">{this.state.response}</p>
                            {/* <p id="popup-content">Sucessfully rejected and permanently closed and an email alert has been sent to other users.</p> */}
                        </div>
                    </div>

                </Modal>
                <Modal isOpen={this.state.modelIsOpenForDetails} className="popup-modal-content-details" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForDetails: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForDetails: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Manuscript Details</span>
                    </div>
                    <div class="card-body">
                        {/* <center style={{ textAlign: 'center' }}> */}
                            <table className="table remove_border">
                                {/* <tr>
                                    <td>First Name</td>
                                    <td>{this.state.firstname}</td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td>{this.state.lastname}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{this.state.email}</td>
                                </tr> */}
                                <tr>
                                    <td className="text_new">Upload Date</td>
                                    <td className="text_new1">{this.state.date}</td>
                                </tr> 
                                <tr>
                                    <td className="text_new">Abstract</td>
                                    <td className="text_new1">{this.state.abstract}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ textAlign: "center" }}><a id="ss-uad-article-new" className="btn badge-info popup-abs-btn-center new_button" target="_blank" href={window.$file+this.state.file}>View/Download</a></td>
                                </tr>
                            </table>
                        {/* </center> */}
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForComment} className="popup-modal-content-comment" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForComment: false })}>
                    <span type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={() => this.setState({ modelIsOpenForComment: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Remark Manuscript</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">For the next step you have to fill the remark</p>
                        <form onSubmit={(event) => this.comment(event)}>
                            <center>
                                <div className="form">
                                    <div className="form-group ">
                                        <textarea id="approve_remark" type="text" name="remark" ref={(val) => this.remark = val} className="form-control" placeholder="Remark" required />
                                    </div>
                                </div>
                            </center>
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <button id="cancel" className="popup-btn" onClick={() => this.setState({ modelIsOpenForComment: false })}>Cancel</button>
                                <button className="popup-btn ml-3" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </>
        );
    }
}