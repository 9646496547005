import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import { CContainer, CSpinner } from '@coreui/react'
import AppFooter from './AppFooter';
import "./FooterInsideWrapper.css";

// routes config
import routes from '../routes'

const loading = (
  <div class="spinner-border text-light" role="status">
  <span class="sr-only">Loading...</span>
</div>
)

const AppContent = () => {
  return (
    // <CContainer lg>
    <>
    <div class="content-wrapper" style={{height:"90vh",overflowY:"scroll"}}>
    <div style={{minHeight:"84.8vh"}}>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <>
                      <route.component {...props} />
                    </>
                  )}
                />
              )
            )
          })}
          <Redirect from="/" to="/user_dashboard" />
        </Switch>
      </Suspense>
      </div>
      <AppFooter/>
      </div>
      
    
    </>
  )
}

export default React.memo(AppContent)
