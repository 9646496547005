import React from 'react';
import { Axios } from '../../../Headers';
import JournalNav from "./JournalNav";
export default class SubmissionFee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      SubmissionfeesData: [],
      Journal_id: "",
      JournalName: "",
      JournalData: "",
    }
  }
  componentDidMount() {

    const journal_id = this.props.match.params.journal_id;
    this.setState({Journal_id: journal_id})

    Axios.all([
      Axios.post('//' + window.$name + '/controller/GetJournalsDetailsForWebController.php',
      {
        journal_id: journal_id,
      }),
      Axios.post('//' + window.$name + '/controller/GetJournalByIdController.php',
      {
          journal_id: journal_id
      })
    ])
      .then((res) => {
        //console.log(res)
        if(res[0].data.Success==1)
        {
        this.setState({ SubmissionfeesData: res[0].data.Data[0]['submission_fees'] })
        }
        this.setState({ JournalData: res[1].data[0] });
        this.setState({ JournalName: res[1].data[0].journal_name})
      })
  }
  render() {
    return (
      <>
      {/* <JournalNav JournalName={this.state.JournalName} JournalId={this.state.Journal_id} /> */}
            <div className="row mb-5">

                <div className="col-4" style={{ padding: "40px", width: "100%" }}>
                    {/* <div className="col-4" style={{padding:"35px"}}> */}
                    <div className="sort-card">
                        {/* <div class="hover_color_bubble"></div> */}
                        {/* <div class="so_top_icon" style={{width:"100%",height:"45%",borderRadius:"15px 15px 0 0",backgroundImage:"url(../../uploads/journals/"+data.journal_image+")",backgroundSize:"cover"}}> */}
                        <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + this.state.JournalData.journal_image + ")", backgroundSize: "cover" }}>
                        </div>
                        <div style={{ padding: "25px", height: "55%" }}>
                            <div class="sort-card-title">
                                {/* {data.journal_name} */}
                                {this.state.JournalData.journal_name}
                            </div>

                            <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                                {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                                {/* {data.journal_description} */}
                                {this.state.JournalData.journal_description}
                            </div>
                            {/* <button  type="button">
                            <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
                            <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
                        </div>
                    </div>

                </div>




                <div className="col-8" style={{ padding: "25px" }}>
                    <p id="home-heading" style={{fontSize:"35px"}}>Submission Fee</p>
                    <div style={{ borderTop: "2px solid black", width: "10%", marginBottom: "20px" }}></div>
                    {/* {this.state.EditorData.map((data) => */}
                        <div >
                            {/* <div id="prp-subheading">
                                {data.firstname + " " + data.lastname}

                            </div> */}
                            <div >
                                <p id="prp-content" style={{ margin: "0" }}>Submission Fee:- {this.state.SubmissionfeesData}</p>
                                {/* <p id="prp-content" style={{ margin: "0" }}>Address:- {data.address}</p> */}
                                {/* <p id="prp-content" style={{margin:"0"}}>Department:- {data.department}</p>
                                <p id="prp-content" style={{margin:"0"}}>Country:- {data.country}</p>
                                <p id="prp-content" style={{margin:"0"}}>Designation:- {data.designation}</p> */}
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>

                    {/* )} */}
                </div>

                {/* <div className="col-4" style={{padding:"25px" }}>
                    <img src={"./uploads/photo10.png"} style={{width:"inherit" , borderRadius:"25px",filter:"drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",height:"450px",padding:"15px"}} />
                    </div> */}

            </div>
            {/* <p>{this.state.SubmissionfeesData}</p> */}
           
      </>
    );
  }
}