import React from "react";
export default class PeerReviewProcess extends React.Component {
  render() {
    return (
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_04">
          <img
            src={"../../uploads/rafiki2.png"}
            class="sort_14"
            style={{
              width: "inherit",
              filter: "drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",
              position: "sticky",
              top: "115px",
            }}
          />
        </div>
        <div className="col-lg-6 col-md-12 sort_04">
          <div className="row justify-content-start sort_18">
            <p id="journal-title">Peer Review Process </p>
            <div class="sort_19"></div>
            <p id="prp-content" class="sort_20">
              At Sort Sol, we follow a peer review process to maintain the
              quality and integrity of our publications. Here's an overview of
              the process:
            </p>
            <ul>
              <li id="prp-content">
                <span className="list-title">Author Submission : </span> Authors
                submit their manuscripts through our online submission system,
                adhering to the guidelines provided. The identities of the
                authors are known to the reviewers, but the reviewers'
                identities are kept confidential.
              </li>
              <li id="prp-content">
                <span className="list-title">Editorial Evaluation : </span>
                The editor-in-chief or associate editor evaluates the submission
                to ensure it meets the scope and quality criteria of the
                journal. Manuscripts that align with the journal's focus proceed
                to the peer review stage.
              </li>
              <li id="prp-content">
                <span className="list-title">Reviewer Assignment : </span>
                The editor assigns the manuscript to expert reviewers in the
                field. Reviewers are chosen based on their expertise,
                experience, and lack of conflicts of interest.
              </li>
              <li id="prp-content">
                <span className="list-title">Peer Review : </span>
                Reviewers assess the manuscript's scientific validity,
                originality, methodology, clarity, and significance. They
                provide constructive feedback and recommendations for
                improvement. The single-blind process ensures unbiased
                evaluations while maintaining the anonymity of the reviewers.
              </li>
              <li id="prp-content">
                <span className="list-title">Editorial Decision : </span>
                Based on the reviewers' feedback, the editor makes an informed
                decision on the manuscript. Possible outcomes include
                acceptance, revision, or rejection. Authors receive detailed
                feedback to help them enhance their work.
              </li>
              <li id="prp-content">
                <span className="list-title">Revision and Resubmission : </span>
                If revisions are requested, authors have the opportunity to
                address the reviewers' comments and improve their manuscript
                accordingly. Revised submissions undergo further evaluation to
                ensure the concerns have been adequately addressed.
              </li>
              <li id="prp-content">
                <span className="list-title">Publication : </span>
                Once accepted, manuscripts proceed to the production stage for
                formatting, copyediting, and typesetting. The final version is
                then published in the relevant journal, accessible to the global
                academic community.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
