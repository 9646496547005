import {React,Redirect} from '../../../Headers'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'
import AppContent from './AppContent'
// import AppFooter from './AppFooter'

export default class DefaultLayout extends React.Component {
  constructor(){
    super();
    this.state = {
      Redirectstate: false
    }
  }

    componentDidMount(){
      const value=localStorage.getItem('user');
      const role_id=localStorage.getItem('role');
      //console.log(role_id);
      // const value=localStorage.getItem('org_id');
      //console.log(localStorage.getItem('user'));
      //console.log(value);
      // this.setState({data:value});
      if(value !== null && role_id==1)
      {
       this.setState({Redirectstate:false});
       this.state.Redirectstate = false;
       //console.log(this.state.Redirectstate);
       
      }
      if(role_id==2 || role_id==3 || role_id==null)
      {
       this.setState({Redirectstate:true});
       //console.log(this.state.Redirectstate);
      }
    //  //console.log(this.state.Redirectstate);
      }

  render(){
  return ((this.state.Redirectstate)?<Redirect to='/login'/>:
    <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
      <div class="wrapper">
         <AppHeader />
      <AppSidebar />
      {/* <div className="wrapper d-flex flex-column min-vh-100 bg-light"> */}
       
        {/* <div className="body flex-grow-1 px-3" style={{height:"92vh",overflowY:"scroll"}}> */}
          <AppContent />
        {/* </div> */}
        {/* <AppFooter /> */}
      {/* </div> */}
    </div>
    </body>
  );
}
}