import { React, Axios, Link, Redirect, Modal, validator, Select } from "../../../Headers"
// import Header from './Header'
import CreateSubject from "./CreateSubject"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import '../layout/FooterInsideWrapper.css'

const loading = (
    <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
)

export default class SuperInstituteRegister extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: "",
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            server: '',
            typeshow: "none",
            user_name_error:"",
            display_uname_error:"block",
            subject: "",
            selectedsubject: "",
            selectedsubjectforvalid: "",
            modelIsOpenForAddSubject:false,
            modelIsOpenForPreloader:false,
        }

    }
    handleValidation() {
        //let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let firstname = this.firstname.value;
        let lastname = this.lastname.value;
        let username = this.username.value;
        let password = this.password.value;
        let address = this.address.value;
        let email = this.email.value;
        let phone = this.state.phone;
        let inst_name = this.inst_name.value;
        let subject = this.state.selectedsubjectforvalid;


        //organization Name

        if (!validator.isAlpha(firstname, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["firstname"] = "Only letters";
        }

        //director Name

        if (!validator.isAlpha(lastname, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["lastname"] = "Only letters";
        }

        //user name

        if (!validator.isAlphanumeric(username, ['en-GB'])) {
            formIsValid = false;
            errors["username"] = "Only letters";
        }

        if (validator.isNumeric(username, ['en-GB'])) {
            formIsValid = false;
            errors["username"] = "Only Numeric Value is Not Allowed";
        }

        //password
        if (!validator.isStrongPassword(password)) {
            formIsValid = false;
            errors["password"] = "minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,";
        }

        //headquarters address

        if (!validator.isAlphanumeric(address, ['en-GB'], { ignore: " " })) {
            if (!address.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["address"] = "Enter Only Valid Text";
            }
        }

        //Email
        if (!validator.isEmail(email)) {
            formIsValid = false;
            errors["email"] = "Enter Valid Email Address.";
        }


        //phone
        if (!validator.isMobilePhone(phone)) {
            formIsValid = false;
            errors["phone"] = "Please Enter Valid Mobile Number.";
        }
        if (!validator.isAlpha(inst_name, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["inst_name"] = "Only letters";
        }
        if (validator.isEmpty(subject)) {
            formIsValid = false;
            errors["subject"] = "Please Select Subject";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    phoneinput = (event) => {
        //console.log(event)
        this.setState({ phone: event })
    }

    ToOpenModel = (event, button) => {
        event.preventDefault();
        event.persist();
        //console.log("hello");
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });
                // this.insertData
                //console.log("hello");
            }
        }

    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })
        Axios.post("//"+window.$name+"/controller/GetSubjectController.php")
        .then((res)=>{
            console.log(res)
            this.setState({ subject: res.data })
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    handlechangesubject = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedsubject: selectedvalue })
        this.setState({ selectedsubjectforvalid: selectedvalue.label })
    }
   
    ResetForm = () => {
       
        document.getElementById("inst_name").removeAttribute('value');
        document.getElementById("firstname").removeAttribute('value');
        document.getElementById("lastname").removeAttribute('value');
        document.getElementById("username").removeAttribute('value');
        document.getElementById("password").removeAttribute('value');
        document.getElementById("address").removeAttribute('value');
        document.getElementById("email").removeAttribute('value');
        document.getElementById("adminphone").removeAttribute('value');
    
      }
     

    // static contextType = AppContext;
    insertData = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false,modelIsOpenForPreloader:true});

        Axios.post('//' + this.state.server + '/controller/InstituteRegisterController.php',
            {
                inst_name: this.inst_name.value,
                firstname: this.firstname.value,
                lastname: this.lastname.value,
                username: this.username.value,
                password: this.password.value,
                address: this.address.value,
                email: this.email.value,
                phone: this.state.phone,
                subject: this.state.selectedsubject.value,

            }
        )
            .then(function (res) {
                //console.log(res);
                if (res.data.success == 1) {
                    this.setState({
                        modelIsOpenForPreloader:false,
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })

                    // alert(res.data.Msg);
                    // this.sendmail();
                    window.location.reload();
                }
                else {
                    this.setState({
                        modelIsOpenForPreloader:false,
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
        // //console.log(this.state.selectedtype.value);
        // //console.log(this.state.selectedtype);
    }
    CheckUserName=()=>{
        this.setState({display_uname_error:"block"})
        Axios.post('//' + this.state.server + '/controller/CheckUserNameController.php',
        {
            username:this.username.value,
        })
        .then((res)=>{
            //console.log(res);
            //console.log(res.data.success);
            if(res.data.success==1)
            {
                this.setState({user_name_error:res.data.Msg})
                setTimeout(
                
                this.seterror
                  ,5000 /// milliseconds = 10 seconds
                );
            }
            else
            {
                this.setState({user_name_error:res.data.Msg})
                setTimeout(
                
                    this.seterror
                      ,5000 /// milliseconds = 10 seconds
                    );
            }
        })
        .catch((err)=>{
            //console.log(err)
        })
    }
    AddSubject=(event)=>{
        event.preventDefault();
        this.setState({modelIsOpenForAddSubject:true})
    }
    seterror=()=>{
        // //console.log("k");
        this.setState({display_uname_error:"none"});
    }
    checkpassword=()=>{
        // //console.log(this.password.value);
        let errors = {};
        let formIsValid = true;
         //password
         if (!validator.isStrongPassword(this.password.value)) {
            formIsValid = false;
            errors["password"] = "minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
       
        return (
            <>
                <div className="container-fluid ">
                    <div className="row d-flex justify-content-center" >
                        <div className="col-6" id="ss-sad-login-007">
                            <div id="ss-sad-login-008" class="card-header text-left">
                                <div class="ss-sad-login-009" >Institute Registration</div>
                            </div>

                            <div className="container" >
                                <form onSubmit={(event) => this.ToOpenModel(event, "submit")} onReset={this.ResetForm}>
                                    {/* <form onSubmit={this.insertData}> */}
                                    <center>
                                        <div className="form">
                                        <div className="row">
                                                <div className="col-md-6 col-sm-11">
                                                    <div className="form-group ">
                                                        <input id="inst_name" type="text" name="inst_name" ref={(val) => this.inst_name = val} className="form-control" placeholder="Institute Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["inst_name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-11">
                                                    <div className="row">
                                                <div className="col-9 form-group">
                                                        <Select
                                                            onChange={this.handlechangesubject}
                                                            options={this.state.subject}
                                                            value={this.state.selectedsubject}
                                                            placeholder="Select Subject"
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["subject"]}</span>
                                                    </div>
                                                    <div className="col-3">
                                                    <button id="add-subject"  class="btn btn-block col-3" onClick={this.AddSubject} ><i class="fas fa-plus"></i></button>
                                                    </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-1"> */}
                                                {/* </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group ">
                                                        <input id="firstname" type="text" name="firstname" ref={(val) => this.firstname = val} className="form-control" placeholder="First Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group ">
                                                        <input id="lastname" type="text" name="lastname" ref={(val) => this.lastname = val} className="form-control" placeholder="Last Name" required />
                                                        <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="username" type="text"  onBlur={this.CheckUserName} name="username" ref={(val) => this.username = val} className="form-control" placeholder="User Name" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["username"]}</span>
                                                        <span style={{ color: "red",display:this.state.display_uname_error }}>{this.state.user_name_error}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="password" onBlur={this.checkpassword} type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Password" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <textarea id="address" type="text" name="address" ref={(val) => this.address = val} className="form-control" placeholder="Address" required></textarea>
                                                        <span style={{ color: "red" }}>{this.state.errors["address"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <input id="email" type="email" name="email" ref={(val) => this.email = val} className="form-control" placeholder="Offical Email" required></input>
                                                        <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <PhoneInput
                                                            id="adminphone"
                                                            placeholder="Enter phone number"
                                                            name="phone"
                                                            onChange={this.phoneinput}
                                                            required
                                                        // style={{ border: '1px solid #ced4da',borderRadius:'.25rem',height:'calc(1.5em + .75rem + 2px)', width: "100%"}}
                                                        />
                                                        <span style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-center">
                                                <button type="submit" id="ss-sad-login-014" class="btn btn-block col-3" >Submit</button>
                                                <button type="reset" id="reset"  class="btn btn-block col-3">Reset</button>
                                            </div>
                                        </div>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.modelIsOpenForPreloader} ariaHideApp={false} className="popup-modal-preloader" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForPreloader: false })}>
                    {loading}
                    </Modal>
                <Modal isOpen={this.state.modelIsOpenForAddSubject} ariaHideApp={false} className="popup-modal-content-details" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForAddSubject: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForAddSubject: false })}>&times;</span>
                    <center style={{ textAlign: 'center' }}>
                        <div className="table-in-popup " style={{ width: "100%" }}>
                    <CreateSubject />
                    </div></center>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForSubmit} ariaHideApp={false} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.insertData}>OK</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>CANCEL</button>
                        </div>
                    </div>
                </Modal>
                
                <Modal isOpen={this.state.modelIsOpenForResponse} ariaHideApp={false} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                    <div className="col-9" style={{ paddingTop: "6px" }}>
                        <p id="popup-title">Remark</p>
                        {/* <p id="popup-content">{this.state.response}</p> */}
                        <p id="popup-content">{this.state.response}</p>

                    </div>
                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                    </div>
                    </div>
                </Modal>
            </>
        );
    }
}