import React from 'react';
export default class Guideline extends React.Component {
    render() {
	    return (
        <div className="row mb-5">
        <div className="col-6 sort_04">
        <img src={"../../uploads/rafiki2.png"} class="sort_14" />
        </div>
        <div className="col-6 sort_04">
        <div className="row justify-content-start sort_18">
    {/* <div> */}
        <p id="journal-title">Guide for Authors</p>
        <div class="sort_19"></div>
        {/* <ul>
        <li>
        <p id="prp-subheading">What is peer review?</p> */}
        <p id="prp-content" class="sort_20">Only relevant material related to subject of Journal.<br/>
	        One covering letter for each article providing:</p>
            <p id="prp-content" class="sort_20">Title of article -<br/>
          Name of authors -<br/>
          Designation of authors -<br/>
          Orcid ID – <br/>
          Email id - </p>
        {/* </li>
        <li> */}
        {/* <p id="prp-subheading">How does it work?</p> */}
        <p id="prp-content" class="sort_20">Article abstract should be there.<br/>
	Your work should not be in consideration with any other publisher while you have submitted the same work to SORT SOL PUBLICATIONS.<br/>
	Provide covering letters for article explaining contributions<br/>
	Images provided should be of at least 500 PPI.<br/>
	No color enhancement on images should be done.<br/>
	Articles submitted should be in two column formats.</p>
  <p id="prp-content" class="sort_20">References should be clearly mentioned in the end of the article on anew page.<br/>
	Objective of the article should be clearly mentioned before the beginning of the article using the center alignment.<br/>
	All the graphs submitted with the articles which supports the article should be in 500 ppi.<br/>
	Institutions submitting the articles for their official journal to us should make a clear index for the articles before submitting.</p>
        {/* </li>
        </ul> */}
    {/* </div> */}
    {/* <div className="row justify-content-start sort_21">
<button id="journal-view-btn">Learn More</button>
</div>  */}
    </div>
        </div>
        </div>
 );
    }   
}     