// import React from 'react'

// import Dashboard from './components/EditorDashboard'
import Editor from './components/Editor'
import E_Article from './components/E_Article'
import EditorApprove from './components/EditorApprove'
import EditorReject from './components/EditorReject'
import EditorHistory from './components/EditorHistory'


const routes = [

  // { path: '/editor_dashboard', exact: true, name: 'Dashboard', component: Dashboard},
  { path: '/manuscript/editor/pending', exact:true,name:'Editor',component: Editor},
  { path: '/manuscript/editor/approve', exact:true, name:'EditorApprove', component: EditorApprove},
  { path: '/manuscript/editor/reject', exact:true, name:'EditorApprove', component: EditorReject},
 
  { path: '/earticle/:article_id', exact: true, name:'Article', component: E_Article},
  { path: '/editorhistory/:article_id/:status', exact:true, name:'EditorHistory',component: EditorHistory},
]

export default routes
