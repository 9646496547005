import { React, Link, Axios, $, Redirect, Modal } from "../../../Headers";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      server: "",
      redirect: false,
      redirecttype: "",
      successdata: [],
    };
  }

  componentDidMount() {
    const server = window.$name;
    // //console.log(server);
    this.setState({ server: server });
    const p_id = this.props.match.params.p_id;
    const o_id = this.props.match.params.o_id;
    // //console.log(localStorage.getItem("user"));
    // //console.log(localStorage.getItem("role"));

    Axios.post("//" + window.$name + "/controller/GetTransactionController.php",
      {
        p_id: p_id,
        o_id: o_id,
      })
      .then((res) => {
        //console.log(res);
        this.setState({ successdata: res.data[0] });
        //     this.setState({ a_pending: res.data["pending"][0]["a_id"] });
        //     this.setState({ a_reject: res.data["reject"][0]["a_id"] });
        //     this.setState({ a_publish: res.data["publish"][0]["a_id"] });
        //     this.setState({ a_approve: res.data["approve"][0]["a_id"] });
        //     this.setState({
        //       a_pendingtopublish: res.data["pendingtopublish"][0]["a_id"],
        // });
        //     // //console.log(res.data[0].Submitted_on)
        //     // //console.log(new Date(res.data[0].Submitted_on).toDateString())
      })
      .catch((err) => {
        //console.log(err);
      });

      
  }
  printpage=()=>{

      var homebutton=document.getElementById("home");
      var p1=document.getElementById("p1");
      var r1=document.getElementById("r1");
      homebutton.style.visibility = 'hidden';
      p1.style.visibility = 'hidden';
      r1.style.visibility = 'hidden';
      window.print()

      homebutton.style.visibility = 'visible';
      p1.style.visibility = 'visible';
      r1.style.visibility = 'visible';
  }
  render() {
    return (
      <>
        <div class="container-fluid">
          {/* <center><h3 class="mb-2">Success</h3></center> */}
          <div className="container">
          <div className="card">
          <div className="card-body">
            <table class="table table-striped table-bordered">
            <thead>
            <th colSpan="2" style={{textAlign:"center",color:"black"}}><h3><b>SUCCESS</b></h3></th>
            </thead>
              <tbody>
                <tr>
                  <td style={{textAlign:"center"}}>Transaction Id</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.unique_t_id}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Transaction Time</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.tranx_time}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Reference Id</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.reference_id}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Payment Mode</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.payment_mode}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Amount</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.price}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Email</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.email}</td>
                </tr>
                <tr>
                  <td style={{textAlign:"center"}}>Mobile</td>
                  <td style={{textAlign:"center"}}>{this.state.successdata.mobile}</td>
                </tr>
                <tr id="r1">
              
                  <td colSpan="2" style={{textAlign:"center"}}><Link to={'/author_manuscript'}><button id="home" className="btn btn-primary m-2">Home</button></Link>
                 <button id="p1" onClick={this.printpage} className="btn btn-success m-2">Print</button></td>
               
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          </div>
        </div>
      </>
    );
  }
}
