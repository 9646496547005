// import { render } from 'react-dom'
import { NavLink } from 'react-router-dom'
import {React,Link} from '../../../Headers'

// import JournalSpecfic from './JournalSpecific'

export default class JournalNav extends React.Component{

constructor(props){
  super(props)
        this.state = {
          // JournalName : ""
          journal_id:"",
        }
}
componentDidMount() {
  const server = window.$name;
  // console.log(server);
  this.setState({ server: server })

  // const journal_id = this.props.match.params.journal_id;
  // this.setState({ journal_id: journal_id })
}
  
render(){
    return (
      <>
      <div style={{boxShadow:"rgb(0 0 0 / 25%) 0px 3px 5px",margin:"0"}}>
       <div className="container-fluid" id="journal_title">
            {this.props.JournalName}
            </div>
        <nav className="navbar navbar-expand-lg" style={{ background: "rgba(196, 196, 196, 0.2)", position: "sticky", top: "0", zIndex: "1"}}>
          {/* <Link className="navbar-brand" to="/journalhome"><img src={"../../uploads/logo-white-text.png"} /></Link> */}
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
       
           
          <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: "start", paddingLeft:"8%"}}>
            <ul class="navbar-nav">
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/"+this.props.JournalId+"/journalspecific"}>Home <span className="sr-only">(current)</span></NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/"+this.props.JournalId+"/currentissue"}>Current Issues</NavLink>
                {/* <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/currentissue"}>Current Issues</NavLink> */}
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/"+this.props.JournalId+"/archive"}>Local Repository</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/"+this.props.JournalId+"/editorialboard"}>Editorial Board</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="nav-link sort-nav-active-menu dark_menu" to={"/journalhome/"+this.props.JournalId+"/aimscope"}>Aims & Scopes</NavLink>
              </li>
              {/* <li class="nav-item">
                <NavLink className="nav-link sort-nav-menu dark_menu" activeClassName="sort-nav-active-menu dark_menu" to="/policy">Aims & Scope</NavLink>
              </li> */}
              <li class="dropdown nav-item" >
              <li><NavLink className="nav-link sort-nav-menu dropdown-toggle dark_menu" role="button" id="dropdownMenuLink" data-toggle="dropdown" to="#">Author Charges
              </NavLink>
                <ul role="menu" className="dropdown-menu">
                  <li><NavLink className="dropdown-item" to={"/journalhome/"+this.props.JournalId+"/submissionfee"}>SUBMISSION FEE</NavLink></li>
                  {/* <li><NavLink className="dropdown-item" to="/policy">EDITORIAL</NavLink></li> */}
                  <li><NavLink className="dropdown-item" to={"/journalhome/"+this.props.JournalId+"/apc"}>APC</NavLink></li>
                  <li><NavLink className="dropdown-item" to={"/journalhome/"+this.props.JournalId+"/pagecharge"}>PAGE CHARGES</NavLink></li>
                  <li><NavLink className="dropdown-item" to={"/journalhome/"+this.props.JournalId+"/colourcharge"}>COLOUR CHARGES</NavLink></li>
                  <li><NavLink className="dropdown-item" to={"/journalhome/"+this.props.JournalId+"/editorial"}>EDITORIAL</NavLink></li>
                </ul>
              </li>
              </li>
              {/* <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
              {/* <li className="nav-item">
        <a className="nav-link disabled" href="#">Disabled</a>
      </li> */}
            </ul>
            {/* <form className="form-inline my-2 my-lg-0">
      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> */}
          </div>
        </nav>
        </div>
      </>
    );
  }
  }
// export default JournalNav



