import Home from './components/Home'
import AuthorEthics from './components/AuthorEthics';
import Contact_Form from './components/Contact_Form';
// import Guideline from './components/Guideline';
import OurVision from './components/OurVision';
// import Plagiarism from './components/Plagiarism';
// import SortSubscription from './components/SortSubscription';
import SortManuscript from './components/SortManuscript';
// import WkConference from './components/WkConference';
// import CustomerServices  from './components/CustomerServices';
// import AuthorRegister from './components/AuthorRegister';
// import SortSolLogin from './components/SortSolLogin';
// import SortSolLogout from './components/SortSolLogout';
import PeerReviewProcess from './components/PeerReviewProcess';
import Technology from './components/Technology';
import AuthorForm from './components/AuthorForm';
import AuthorFormBook from './components/AuthorFormBook';
import AuthorFormJournal from './components/AuthorFormJournal';


const routes = [

  { path: '/home', exact: true, name: 'Home', component: Home},
  { path: '/author_and_reviewer', exact:true,name:'Author And Reviewer',component: AuthorEthics},
  { path: '/contact_form', exact:true, name:'Contact_Form', component: Contact_Form},
  // { path: '/sortguideline', exact:true, name:'Guideline', component: Guideline},
  // { path: '/wkconference', exact:true, name:'WKConference', component:WkConference},
  // { path: '/customerservices', exact:true, name:'Customerservices', component: CustomerServices},
  { path: '/our_vision', exact: true, name:'OurVision', component: OurVision},
  // { path: '/plagiarism', exact:true, name:'Plagiarism',component: Plagiarism},
  // { path: '/sortsubscription', exact:true, name:'SortSubscription',component: SortSubscription},
  { path: '/sortmanuscript', exact:true, name:'SortManuscript',component: SortManuscript},
  { path: '/author_form', exact:true, name:'Author Form',component: AuthorForm},
  { path: '/author_form_2/:formId/:journal?', exact:true, name:'Author Form',component: AuthorFormBook},
  { path: '/author_form_3/:formId', exact:true, name:'Author Form',component: AuthorFormJournal},
  // { path: '/author_register', exact:true, name:'AuthorRegister', component: AuthorRegister},
  // { path: '/sortsollogin', exact:true, name:'SortSolLogin', component: SortSolLogin},
  // { path: '/sortsollogout', exact:true, name:'SortSolLogin', component: SortSolLogout},
  { path: '/peer_review_process', exact:true, name:'PeerReviewProcess', component: PeerReviewProcess},
  { path: '/technology', exact:true, name:'Technology', component: Technology},
]

export default routes
