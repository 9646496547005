import { Modal } from "../../../../Headers";

const AuthorJournalFormModal = (props) => {
  const { modalStatus, handleModal, journalData } = props;

  return (
    <Modal
      isOpen={modalStatus}
      className="popup-modal-details-2"
      overlayClassName="popup-modal"
      onRequestClose={handleModal}
    >
      {journalData && journalData.length > 0 && (
        <>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Frequency</label>
                <input
                  type="text"
                  className="form-control"
                  value={journalData[0].frequency}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Keyword</label>
                <input
                  type="text"
                  className="form-control"
                  value={journalData[0].keyword}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Start Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={journalData[0].start_date}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Type of Department</label>
                <input
                  type="text"
                  className="form-control"
                  value={journalData[0].type_of_department}
                  disabled
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Type of Journal</label>
                <input
                  type="text"
                  className="form-control"
                  value={journalData[0].type_of_journal}
                  disabled
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AuthorJournalFormModal;
