import React from "react";
import { $, Axios, Modal, validator } from "../Headers";

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelIsOpenForSubmit: false,
      modelIsOpenForResponse: false,
      response: "",
      redirect: false,
      server: "",
      article_id: "",
      errors: {},
    };
  }
  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let name = this.name.value;
    let email = this.email.value;
    let msg = this.msg.value;

    if (!validator.isAlpha(name, ["en-GB"], { ignore: " " })) {
      formIsValid = false;
      errors["name"] = "Only letters";
    }

    if (!validator.isAlphanumeric(msg, ["en-GB"], { ignore: " " })) {
      if (!msg.match(/[a-zA-Z0-9][():,./]/)) {
        formIsValid = false;
        errors["msg"] = "Only letters";
      }
    }
    if (!validator.isLength(msg, { max: 500 })) {
      formIsValid = false;
      errors["msg"] = "Maximum 500 character ";
    }

    //Email
    if (!validator.isEmail(email)) {
      formIsValid = false;
      errors["email"] = "Enter Valid Email Address.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  SubmitInfo = (event) => {
    event.preventDefault();
    event.persist();
    if (this.handleValidation()) {
      Axios.post("//" + window.$name + "/controller/ContactusController.php", {
        name: this.name.value,
        email: this.email.value,
        msg: this.msg.value,
      })
        .then(
          function (res) {
            if (res.data.success == 1) {
              this.setState({
                response: res.data.Msg,
                // modelIsOpenForAssign: false,
                modelIsOpenForResponse: true,
              });

              // alert(res.data.Msg);
              // this.sendmail();
              // window.location.reload();
            } else {
              this.setState({
                response: res.data.Msg,
                // modelIsOpenForAssign: false,
                modelIsOpenForResponse: true,
              });
              // alert(res.data.Msg);
              // window.location.reload();
              //console.log(res);
            }
          }.bind(this)
        )
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  render() {
    return (
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_13">
          <img src={"../../uploads/bro2.png"} class="sort_14" />
        </div>
        <div className="col-lg-6 col-md-12 sort_10 align-self-center">
          <div className="row justify-content-start sort_15">
            <div className="col-lg-10 col-md-12">
              <p id="journal-title">Contact Us</p>
              <div class="sort_16"></div>
              <div className="container ">
                <form onSubmit={this.SubmitInfo} onReset={this.ResetForm}>
                  <center>
                    <div className="form">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="name"
                          ref={(val) => (this.name = val)}
                          className="form-control"
                          placeholder="Full Name"
                          required
                        ></input>
                        <span style={{ color: "red" }}>
                          {this.state.errors["name"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="email"
                          name="email"
                          ref={(val) => (this.email = val)}
                          className="form-control"
                          placeholder="Email"
                          required
                        ></input>
                        <span style={{ color: "red" }}>
                          {this.state.errors["email"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <textarea
                          id="contact-input"
                          type="text"
                          name="msg"
                          ref={(val) => (this.msg = val)}
                          className="form-control"
                          placeholder="Message"
                          style={{ height: "130px" }}
                          required
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["msg"]}
                        </span>
                      </div>
                      <div className="row d-flex justify-content-start sort_17">
                        <button
                          type="submit"
                          id="home-publish-btn"
                          className="btn btn-block col-lg-3 col-sm-12"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modelIsOpenForResponse}
          className="popup-modal-content-success"
          overlayClassName="popup-modal"
          onRequestClose={() =>
            this.setState({ modelIsOpenForResponse: false })
          }
        >
          <div className="row" style={{ height: "100%", margin: "0px" }}>
            <div className="col-9" style={{ paddingTop: "6px" }}>
              <p id="popup-title">Alert</p>
              <p id="popup-content">{this.state.response}</p>
            </div>
            <div
              className="col-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                id="ss-uad-article-09"
                style={{
                  height: "50px",
                  lineHeight: "initial",
                  padding: "initial",
                  width: "110px",
                }}
                className="btn btn-primary"
                onClick={() => window.location.reload()}
              >
                <i class="fas fa-check" style={{ fontSize: "29px" }}></i>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
