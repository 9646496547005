// import React from 'react'

// import Dashboard from './components/ReviewerDashboard'
import Reviewer from './components/Reviewer'
import Article from './components/R_Article'
import ReviewerApprove from './components/ReviewerApprove'
import ReviewerReject from './components/ReviewerReject'
import ReviewerHistory from './components/ReviewerHistory'
// import Reviewer_Approve from './components/ReviewerApproveAfterReject'


const routes = [

  // { path: '/reviewer_dashboard', exact: true, name: 'Dashboard', component: Dashboard},
  { path: '/manuscript/Reviewer/pending', exact:true,name:'Reviewer',component: Reviewer},
  { path: '/manuscript/Reviewer/approve', exact:true, name:'ReviewerApprove', component: ReviewerApprove},
  { path: '/manuscript/Reviewer/reject', exact:true, name:'ReviewerApprove', component: ReviewerReject},
 
  { path: '/rarticle/:article_id', exact: true, name:'Article', component: Article},
  { path: '/reviewerhistory/:article_id/:status', exact:true, name:'ReviewerHistory', component:ReviewerHistory},
  // { path: '/reviewer_approve/:article_id', exact:true, name:'Reviewer_Approve', component: Reviewer_Approve}
]

export default routes
