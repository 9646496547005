import { React, Link, $, NavLink } from '../../../Headers';
// import './FooterInsideWrapper.css';
export default class AppSidebar extends React.Component {

  componentDidMount() {
    // $(document).on('click', '.nav-item', function () {
    //   $(this).siblings().children().removeClass('active');
    //   $(this).children().addClass('active');
    // })
  }

  render() {
    return (
      // <body class="hold-transition sidebar-mini layout-fixed">
      //<div class="wrapper"> 
      <>

        <aside id="ss-uad-sidebar-01" class="main-sidebar sidebar-dark-primary elevation-4" >
          {/* <!-- Brand Logo --> */}
          {/* <a href="#" class="brand-link">
      <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style={{opacity:'.8'}}/>
      <span class="brand-text font-weight-light" style={{marginLeft:"73px",fontFamily:"-webkit-pictograph",letterSpacing:"12px"}}>MPSS</span>
    </a> */}
          <Link id="ss-uad-sidebar-02" class="brand-link">
            <div id="ss-uad-sidebar-03">
              <img id="ss-uad-sidebar-04" src={"../../uploads/logo-right-text.png"} />
            </div>
            {/* <span class="brand-text font-weight-light">MPSS</span> */}
            {/* <span class="brand-text font-weight-light text-uppercase" style={{ marginLeft: "20px", fontFamily: "-webkit-pictograph", fontSize: "30px" }}>TICKSY</span> */}
          </Link>

          {/* <!-- Sidebar --> */}
          <div class="sidebar" id="sidebar">
            {/* <!-- Sidebar user panel (optional) --> */}
            {/* <div class="user-panel mt-3 pb-3 mb-3 d-flex">
              <div class="image"> */}
            {/* <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"/> */}
            {/* </div> */}
            {/* <div class="info"> */}
            {/* <a href="#" class="d-block">Alexander Pierce</a> */}
            {/* </div> */}
            {/* </div> */}

            {/* <!-- SidebarSearch Form --> */}
            <div style={{ marginTop: "5px" }}></div>
            <div class="form-inline">
            {/* <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" /> */}
            {/* <div class="input-group-append">
                  <button class="btn btn-sidebar" style={{ margin: "0px", padding: ".375rem .75rem", boxShadow: "none", fontSize: "inherit" }}>
                    <i class="fas fa-search fa-fw"></i>
                  </button>
                </div> */}
            {/* </div> */}
            </div>

            {/* <!-- Sidebar Menu --> */}
            <nav class="mt-2">
              <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library --> */}
                <li class="nav-item">
                  <NavLink to="/super_dashboard" className="nav-link" activeClassName="active1">
                    <i class="nav-icon fas fa-tachometer-alt"></i>
                    <p>
                      Dashboard
                      {/* <span class="right badge badge-danger">New</span> */}
                    </p>
                  </NavLink>
                </li>
                <li class="nav-header">Actions</li>
                {/* <li class="nav-item menu-open"> */}
                {/* <NavLink to="/super_create_user" className="nav-link" activeClassName="active">
                    <i class="nav-icon fas fa-building"></i>
                    <p>
                      Organization
                      <i class="right fas fa-angle-left"></i>
                     
                    </p>
                  </NavLink> */}
                {/* <ul class="nav nav-treeview"> */}
                {/* <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Dropdown link
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
        </li> */}
                <li class="nav-item">
                  <NavLink to='/manuscript/superadmin' className="nav-link" activeClassName=" active1">
                    <i class="fas fa-chart-pie nav-icon"></i>
                    <p>Manuscripts
                      {/* <span class="right fa fa-angle-down"></span> */}
                    </p>
                  </NavLink>
                  {/* <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <NavLink to='/manuscript/superadmin' className="nav-link" activeClassName="active">
                        <i class="far fa fa-chart-bar nav-icon"></i>
                        <p>Pending</p>
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/manuscript/superadminapprove" className="nav-link" activeClassName="active">
                        <i class="far fa fa-chart-bar nav-icon"></i>
                        <p>Approve</p>
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/manuscript/superadminreject" className="nav-link" activeClassName="active">
                        <i class="far fa fa-chart-bar nav-icon"></i>
                        <p>Reject</p>
                      </NavLink>
                    </li>

                  </ul> */}
                </li>
                <li class="nav-item">
                  <NavLink to='/country' className="nav-link" activeClassName="active1">
                    <i class="fas fa-globe-asia nav-icon"></i>
                    <p>Create Country</p>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink to='/create_subject' className="nav-link" activeClassName="active1">
                    <i class="far fa fa-plus nav-icon"></i>
                    <p>Create Subject</p>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink to='/super_create_user' className="nav-link" activeClassName="active1">
                    <i class="fas fa-user nav-icon"></i>
                    <p>Create User</p>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink to='/super_institute_register' className="nav-link" activeClassName="active1">
                  <i class="fas fa-university nav-icon"></i>
                    <p>Institute Register</p>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink to='/view_user' className="nav-link" activeClassName="active1">
                    <i class="far fa-eye nav-icon"></i>
                    <p>View User</p>
                  </NavLink>
                </li>

                {/* <li class="nav-item">
                      <NavLink to='/create_journals' className="nav-link" activeClassName="active">
                        <i class="fas fa-book nav-icon"></i>
                        <p>Add Journal</p>
                      </NavLink>
                    </li> */}

                {/* <li class="nav-item">
                      <NavLink to='/editorialboard' className="nav-link" activeClassName="active">
                        <i class="fas fa-clipboard nav-icon"></i>
                        <p>Add Editorial Board</p>
                      </NavLink>
                    </li> */}
                <li class="nav-item">
                  <NavLink to='/contact_us' className="nav-link" activeClassName="active1">
                    <i class="fas fa-id-card-alt nav-icon"></i>
                    <p>Contact Info</p>
                  </NavLink>
                </li>
                <li class="nav-item">

                  {/* </li> */}
                  {/* <li class="nav-item">
                      <NavLink to="/super_org_existing" className="nav-link" activeClassName="active">
                        <i class="far fa fa-eye nav-icon"></i>
                        <p>View Existing</p>
                      </NavLink>
                    </li> */}
                  {/* </ul> */}
                </li>

                {/* <li class="nav-item menu-open">
                  <a href="#" class="nav-link">
                    <i class="nav-icon fas fa-paper-plane"></i>
                    <p>
                      Plans
                      <i class="right fas fa-angle-left"></i> */}
                {/* <span class="badge badge-info right">2</span> */}
                {/* </p> */}
                {/* </a> */}
                {/* <ul class="nav nav-treeview">
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="fa fa-plus nav-icon"></i>
                        <p>Create New</p>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="fa fa-eye nav-icon"></i>
                        <p>View Existing</p>
                      </a>
                    </li>
                    <li class="nav-item">
                                <NavLink to='super_article' className="nav-link" activeClassName=" active">
                                    <i class="fas fa-plus-square nav-icon"></i>
                                    <p>Add Article</p>
                                </NavLink>
                            </li>
                  </ul> */}
                {/* </li> */}
                <li class="nav-item">
                  <NavLink to="/super_logout" className="nav-link" activeClassName="active1">
                    <i class="nav-icon fa fa-sign-out-alt"></i>
                    <p>
                      Log Out
                    </p>
                  </NavLink>
                </li>
              </ul>
            </nav>
            {/* <!-- /.sidebar-menu --> */}
          </div>
          {/* <!-- /.sidebar --> */}
        </aside>
      </>
      //</div>
      //</body>
    )
  }
}
