import { React, Axios, Modal, validator, Select } from "../../../Headers"
// import Header from './Header'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Files from 'react-files'

const loading = (
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
)

export default class UploadManuscript extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: "",
            modelIsOpenForSubmit: false,
            modelIsOpenForResponse: false,
            modelIsOpenForPreloader:false,
            response: '',
            errors: {},
            server: '',
            journals: "",
            selectedjournals: "",
            country: "",
            selectedcountry: "",
            upload: "",
            filetype: "",
            filesize: "",
            filename: "",
            selectedjournalsforvalid: '',
            selectedcountryforvalid: '',
            selectedtitle: "",
            selectedtitleforvalid: "",
            error1: "",
        }
        this.onFilesChange = this.onFilesChange.bind(this);

    }
    handleValidation() {
        //let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let title = this.state.selectedtitleforvalid;
        let firstname = this.firstname.value;
        let lastname = this.lastname.value;
        let address = this.address.value;
        let email = this.email.value;
        let phone = this.state.phone;
        let org_name = this.organization.value;
        let manuscript_title = this.manuscript_title.value;
        let abstract = this.abstract.value;
        let upload_file = this.state.upload;
        let country = this.state.selectedcountryforvalid;
        //console.log(country)


        if (validator.isEmpty(title)) {
            formIsValid = false;
            errors["title"] = "Please Select";
        }

        if (!validator.isAlpha(firstname.trim(), ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["firstname"] = "Only letters";
        }

        if (!validator.isAlpha(lastname.trim(), ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["lastname"] = "Only letters";
        }

        if (!validator.isAlphanumeric(manuscript_title, ['en-GB'], { ignore: " " })) {
            if (!abstract.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["manuscript_title"] = "Only letters";
            }
        }

        if (!validator.isAlphanumeric(abstract, ['en-GB'], { ignore: " " })) {
            if (!abstract.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["abstract"] = "Only letters";
            }
        }
        if (!validator.isLength(abstract, { max: 500 })) {
            formIsValid = false;
            errors["abstract"] = "Maximum 500 character ";
        }


        if (!validator.isAlpha(org_name, ['en-GB'], { ignore: " " })) {
            if (!org_name.match(/[a-zA-Z][():,./]/)) {
                formIsValid = false;
                errors["org_name"] = "Only letters";
            }
        }

        if (!validator.isAlphanumeric(address, ['en-GB'], { ignore: " " })) {
            if (!address.match(/[a-zA-Z0-9][():,./]/)) {
                formIsValid = false;
                errors["address"] = "Enter Only Valid Text";
            }
        }

        //Email
        if (!validator.isEmail(email)) {
            formIsValid = false;
            errors["email"] = "Enter Valid Email Address.";
        }


        //phone
        if (!validator.isMobilePhone(phone)) {
            formIsValid = false;
            errors["phone"] = "Please Enter Valid Mobile Number.";
        }
        if (!upload_file) {
            formIsValid = false;
            errors["upload_file"] = "Please Upload File";
        }
        if (validator.isEmpty(country)) {
            formIsValid = false;
            errors["country"] = "Please Select Country";
        }
        if (validator.isEmpty(this.state.selectedjournalsforvalid)) {
            formIsValid = false;
            errors["journal"] = "Please Select Journal Type";
        }



        this.setState({ errors: errors });
        return formIsValid;
    }


    phoneinput = (event) => {
        //console.log(event)
        this.setState({ phone: event })
    }

    ToOpenModel = (event, button) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });

            }
        }

    }

    onFilesChange = (files) => {
        //console.log(files)

        //console.log(files[0])
        //console.log(files.length)
        if (files.length > 0) {
            this.setState({ upload: files });
            //console.log(files[0].extension)
            //console.log(files[0].size)
            this.setState({ filetype: files[0].extension })
            this.setState({ filesize: files[0].size })
            this.setState({ filename: files[0].name })
        }
        else if (files.length = 0) {
            this.setState({ error1: "Not a Valid File Type" })
        }
    }
    onFilesError = (error, file) => {
        //console.log('error code ' + error.code + ': ' + error.message )
    }

    componentDidMount() {
        const server = window.$name;
        // //console.log(server);
        this.setState({ server: server })

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetJournalsForInstController.php',
                {
                    subject_id: localStorage.getItem("subject_id"),
                }),
            Axios.post('//' + window.$name + '/controller/GetCountryController.php'),
        ])
            .then((res) => {
                //console.log(res[0]);
                //console.log(res[1]);
                this.setState({ journals: res[0].data })
                this.setState({ country: res[1].data })
            })
            .catch((err) => {
                //console.log(err[0]);
                //console.log(err[1]);
            })
    }
    handlechange = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedjournals: selectedvalue })
        this.setState({ selectedjournalsforvalid: selectedvalue.value })

    }
    handlechangeCountry = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedcountry: selectedvalue })
        this.setState({ selectedcountryforvalid: selectedvalue.value })
    }
    handlechangetitle = (selectedvalue) => {
        //console.log(selectedvalue);
        this.setState({ selectedtitle: selectedvalue });
        this.setState({ selectedtitleforvalid: selectedvalue.value });
    }

    // static contextType = AppContext;
    insertData = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false,
        modelIsOpenForPreloader:true});

        const fd = new FormData();

        fd.append('type', localStorage.getItem('type'));
        fd.append('unique_user_id', localStorage.getItem('unique_user_id'));
        fd.append('journaltype', this.state.selectedjournals.value);
        fd.append('title', this.state.selectedtitle.value);
        fd.append('firstname', this.firstname.value);
        fd.append('lastname', this.lastname.value);
        fd.append('address', this.address.value);
        fd.append('email', this.email.value);
        fd.append('phone', this.state.phone);
        fd.append('country', this.state.selectedcountry.value);
        fd.append('org_name', this.organization.value);
        fd.append('manuscript_title', this.manuscript_title.value);
        fd.append('abstract', this.abstract.value);
        fd.append('upload_file', this.state.upload[0]);
        fd.append('file_type', this.state.filetype);
        fd.append('file_size', this.state.filesize);

        Axios.post('//' + this.state.server + '/controller/UploadArticleController.php', fd
            // {
            // firstname: this.firstname.value,
            // lastname: this.lastname.value,
            // username: this.username.value,
            // password: this.password.value,
            // address: this.address.value,
            // email: this.email.value,
            // phone: this.state.phone,
            // role: this.state.selectedrole.value,
            // subject: this.state.selectedsubject.value,
            // }
        )
            .then(function (res) {
                //console.log(res);
                //console.log(res.data);
                if (res.data.success == 1) {
                    this.setState({
                        modelIsOpenForPreloader:false,
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })

                    //     alert(res.data.Msg);
                    // this.sendmail();
                    // window.location.reload();
                }
                else {
                    this.setState({
                        modelIsOpenForPreloader:false,
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    // alert(res.data.Msg);
                    // window.location.reload();
                    //console.log(res);

                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    }

    render() {
        const title = [
            {
                value: "Mr.",
                label: "Mr."
            },
            {
                value: "Ms.",
                label: "Ms."
            },
            {
                value: "Mrs.",
                label: "Mrs."
            }
        ]
        return (
            <>
                <div className="row mb-5">

                    <div className="col-6 sort_10">
                        {/* <div className="row justify-content-start" style={{width:"100%",margin:"0"}}> */}
                        {/* <div> */}
                        {/* <div className="col-10"> */}
                        <p id="journal-title">Manuscript</p>
                        <div class="sort_12"></div>
                        <div className="container ">
                            <form onSubmit={(event) => this.ToOpenModel(event, "submit")}>
                                {/* <form onSubmit={this.insertData}> */}
                                <center>
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <Select
                                                        id="contact-input2"
                                                        onChange={this.handlechangetitle}
                                                        options={title}
                                                        value={this.state.selectedtitle}
                                                        placeholder="Mr."
                                                    />
                                                    <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group ">
                                                    {/* <label className="font-weight-bold">User Name</label> */}
                                                    <input id="contact-input" type="text" name="firstname" ref={(val) => this.firstname = val} className="form-control" placeholder="First Name" required />
                                                    <span style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group ">
                                                    {/* <label className="font-weight-bold">Password</label> */}
                                                    <input id="contact-input" type="text" name="lastname" ref={(val) => this.lastname = val} className="form-control" placeholder="Last Name" required />
                                                    <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <Select
                                                        id="contact-input2"
                                                        onChange={this.handlechange}
                                                        options={this.state.journals}
                                                        value={this.state.selectedjournals}
                                                        placeholder="Select Journal"
                                                    />
                                                    <span style={{ color: "red" }}>{this.state.errors["journal"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <Select
                                                        id="contact-input2"
                                                        onChange={this.handlechangeCountry}
                                                        options={this.state.country}
                                                        value={this.state.selectedcountry}
                                                        placeholder="Select Country"
                                                    />
                                                    <span style={{ color: "red" }}>{this.state.errors["country"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <input id="contact-input" type="text" name="organization" ref={(val) => this.organization = val} className="form-control" placeholder="Organization Name" required />
                                                    <span style={{ color: "red" }}>{this.state.errors["org_name"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <input id="contact-input" type="text" name="manuscript_title" ref={(val) => this.manuscript_title = val} className="form-control" placeholder="Manuscript Title" required />
                                                    <span style={{ color: "red" }}>{this.state.errors["manuscript_title"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input id="contact-input" type="email" name="email" ref={(val) => this.email = val} className="form-control" placeholder="Offical Email" required></input>
                                                    <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <PhoneInput
                                                        id="contact-input"
                                                        placeholder="Enter phone number"
                                                        name="phone"
                                                        onChange={this.phoneinput}
                                                        required
                                                    // style={{ border: '1px solid #ced4da',borderRadius:'.25rem',height:'calc(1.5em + .75rem + 2px)', width: "100%"}}
                                                    />
                                                    <span style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <textarea id="contact-input" type="text" name="abstract" ref={(val) => this.abstract = val} className="form-control" placeholder="Abstract" required />
                                                    <span style={{ color: "red" }}>{this.state.errors["abstract"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <textarea id="contact-input" type="text" name="address" ref={(val) => this.address = val} className="form-control" placeholder="Address" required></textarea>
                                                    <span style={{ color: "red" }}>{this.state.errors["address"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div style={{ backgroundColor: "rgb(70, 80, 85)", padding: "3vh", borderRadius: "25px", color: "white", opacity: ".9",marginBottom:"1rem"}}> */}
                                        <Files
                                            className='files-dropzone'
                                            onChange={this.onFilesChange}
                                            onError={this.onFilesError}
                                            accepts={['.pdf', '.docx']}
                                            // multiple
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            clickable
                                        >
                                            <div style={{ backgroundColor: "rgb(70, 80, 85)", padding: "3vh", borderRadius: "25px", color: "white", opacity: ".9", marginBottom: "1rem" }}>
                                                Drop files here or click to upload
                                                <div style={{ color: "#212529" }}>{this.state.filename}</div>
                                                <span style={{ color: "red" }} >{this.state.errors["upload_file"]}</span>
                                                <div style={{ color: "red" }} >{this.state.error1}</div>
                                            </div>
                                        </Files>
                                        {/* <div style={{ color: "#212529" }}>{this.state.filename}</div>
                                                    <span style={{ color: "red" }} >{this.state.errors["upload_file"]}</span>
                                                    <div style={{ color: "red" }} >{this.state.error1}</div>
                                                </div> */}
                                        <div className="row sort_18">
                                            <button type="submit" id="home-publish-btn">Submit</button>
                                        </div>
                                    </div>
                                </center>
                            </form>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-6 sort_13">
                        <img src={"../../uploads/rafiki3.png"} class="sort_14" />
                    </div>

                    {/* </div> */}

                    {/* </section> */}
                </div>
                <Modal isOpen={this.state.modelIsOpenForPreloader} className="popup-modal-preloader" overlayClassName="popup-modal">
                    {loading}
                </Modal>
                <Modal ariaHideApp={false} isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed sort_24" type="button" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header sort_25" id="ss-uad-article-10" >
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h2>Do you want to submit this record ?</h2> */}
                        <div className="row sort_26">
                            <button id="ss-uad-article-09" className="btn btn-primary mr-2 col-5 sort_27" onClick={(event)=>this.insertData(event)}>Accept</button>
                            <button id="ss-uad-article-08" className="btn btn-danger col-5 sort_27" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForResponse: false })}>
                    <span class="closed sort_24" type="button" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header sort_25" id="ss-uad-article-10" >
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Success</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">{this.state.response}</p>
                        {/* <h4>{this.state.response}</h4> */}
                        <div className="row sort_26">
                            <button id="ss-uad-article-09" className="btn btn-primary mr-2 col-5 sort_27" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}