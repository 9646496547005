import { $, React, Axios, validator, Modal, dt } from '../../../Headers';
// import Header from '../../Header'


export default class CreateSubject extends React.Component {
    constructor() {
        super();
        this.state = {
            SubjectData: [],
            formAction: "ADD",
            subject_id: "",
            modelIsOpenForSubmit: false,
            modelIsOpenForInactive: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            articleType: '',
            editRecordId: '',
            server: '',
            articleTypeforvalid: '',
        }
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let subject_name = this.subject_name.value;
        // //console.log(articletypeselected);
        //article name

        if (!validator.isAlpha(subject_name, ['en-GB'], { ignore: " " })) {
            formIsValid = false;
            errors["subject_name"] = "Only letters";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }



    EditJournalData = (SubjectData) => {
        this.setState({ formAction: "EDIT" });
        this.setState({ subject_id: SubjectData.subject_id });



        document.getElementById("subject_name").setAttribute('value', SubjectData.subject_name);
        // document.getElementById("DGDescription").setAttribute('value', ArticleData.dg_description);

        // To set the existing values into the form...
        //this.setState({selectedDestinations:JSON.parse(ExistingCombo.dg_destinations)});
    }

    ResetForm = () => {
        this.setState({ formAction: 'ADD' });
        document.getElementById("subject_name").removeAttribute('value');
    }

    SubmitJournals = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({ modelIsOpenForSubmit: false });
        // //console.log(this.state.selectedoption);
        if (this.state.formAction == "ADD") {
            Axios.post('//' + this.state.server + '/controller/AddSubjectController.php',
                {

                    subject_name: this.subject_name.value.toUpperCase(),
                },
            )
                .then(function (res) {
                    if (res.data.success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                    }
                    //console.log(res)
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
        }
        else if (this.state.formAction == "EDIT") {
            Axios.put('//' + this.state.server + '/controller/EditSubjectController.php',
                {
                    subject_id: this.state.subject_id,
                    subject_name: this.subject_name.value,
                })
                .then(function (res) {
                    if (res.data.success == 1) {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                    else {
                        this.setState({
                            response: res.data.Msg,
                            modelIsOpenForResponse: true
                        })
                        //console.log(res);
                    }
                }
                    .bind(this))
                .catch(function (error) {
                    //console.log(error);
                });
            this.setState({ formAction: "ADD" });
            this.setState({ articleid: "" });
        }
    }

    InactiveArticle = () => {
        this.setState({ modelIsOpenForInactive: false });
        // alert(ArticleConfigToInactive);
        // event.preventDefault();
        // event.persist();
        Axios.post('//' + this.state.server + '/controller/InactiveSubjectController.php',
            {
                subject_id: this.state.subject_id,
            })
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    };

    // DeleteArticle = (SubjectToDelete) => {
    //     // alert(ArticleConfigToInactive);
    //     // event.preventDefault();
    //     // event.persist();
    //     Axios.post('//' + this.state.server + '/controller/DeleteSubjectController.php',
    //         {
    //             subject_id: SubjectToDelete,
    //         })
    //         .then(function (res) {
    //             if (res.data.success == 1) {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //             else {
    //                 this.setState({
    //                     response: res.data.Msg,
    //                     modelIsOpenForResponse: true
    //                 })
    //             }
    //         }
    //             .bind(this))
    //         .catch(function (error) {
    //             //console.log(error);
    //         });
    // };

    ToOpenModel = (event, button, idForInactive) => {
        event.preventDefault();
        event.persist();
        if (button == "submit") {
            if (this.handleValidation()) {
                this.setState({
                    modelIsOpenForSubmit: true
                });
            }
        }
        else if (button == "inactive") {
            this.setState({
                modelIsOpenForInactive: true,
                subject_id: idForInactive
            })
        }
    }

    componentDidMount() {
        const server = window.$name;
        ////console.log(server);
        this.setState({ server: server })
        // //console.log(this.state.formAction);
       
        Axios.all(
            [
                Axios.post("//" + window.$name + "/controller/GetSubjectForTableController.php",
                    {
                      
                    })
            ])
            .then((res) => {
                this.setState({ SubjectData: res[0].data });
                //console.log(this.state.SubjectData);
                $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
            })
            .catch((error) => {
                //console.log(error[0]);
                // //console.log(error[1]);
            });

        // //console.log(res)
        // this.setState({name:res.data})
    }
    render() {

        return (
            <>
                <div>
                    <section class="content">
                        <div id="subject_form" class="container d-flex justify-content-center">
                            <div className="col-6" id="ss-sad-login-007">
                                {/* <h1 className="card-header text-center text-uppercase " id="card_header">Create Subject</h1> */}
                                <div id="ss-sad-login-008" class="card-header text-left">
                                    <div class="ss-sad-login-009" >Create Subject</div>
                                </div>

                                <div className="container">
                                    <form onSubmit={(event) => this.ToOpenModel(event, "submit", "")} onReset={this.ResetForm}>
                                        {/* <form onSubmit={this.SubmitJournals} onReset={this.ResetForm}> */}
                                        <center>
                                            <div className="form">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group ">
                                                            {/* <label className="font-weight-bold">Article Name</label> */}
                                                            <input id='subject_name' type="text" name="subject_name" ref={(val) => this.subject_name = val} className="form-control" placeholder="Subject Name" required />
                                                            <span style={{ color: "red" }}>{this.state.errors["subject_name"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="card-footer"> */}
                                                <div className="row d-flex justify-content-center">
                                                    <button type="submit" id="ss-sad-login-014" className="btn btn-block col-3">Submit</button>
                                                    <button type="reset" id="reset" className="btn btn-block col-3">Reset</button>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </center>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Subject Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.SubjectData.map((data) =>
                                        <tr>
                                            <td></td>
                                            <td>{data.subject_name}</td>

                                            <td>
                                                <div className="btn-group">
                                                    <a href="#subject_form"><button type="button" class="btn-lg  responsive_btn m-2" id="edit" title="Edit" value="Edit" onClick={() => this.EditJournalData(data)} ><i class="far fa-edit"></i></button></a>
                                                    <button type="button" class="btn-lg   responsive_btn m-2" title="Inactive" id="inactive" value="Inactive" onClick={(event) => this.ToOpenModel(event, "inactive", data.subject_id)} ><i class="far fa-eye-slash"></i></button>
                                                    {/* <button type="button" class="btn-lg  responsive_btn m-2" title="Delete" id="delete" value="Delete" onClick={() => this.DeleteArticle(data.subject_id)} ><i class="far fa-trash-alt"></i></button> */}
                                                </div></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
                <Modal isOpen={this.state.modelIsOpenForSubmit} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForSubmit: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForSubmit: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* <h2>Do you want to submit this record ?</h2> */}
                    <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.SubmitJournals}>Accept</button>
                    <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForSubmit: false })}>Reject</button>

                    {/* <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.SubmitJournals}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForSubmit: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button> */}
                </div>
                </div>
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Do you want to inactive this record ?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" type="submit" onClick={this.InactiveArticle}>Accept</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-danger col-5" onClick={() => this.setState({ modelIsOpenForInactive: false })}>Reject</button>
                        </div>
                    </div>

                    {/* <span class="closed" onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <h2>Do you want to inactive this record ?</h2>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-btn" onClick={this.InactiveArticle}><i class="fas fa-check" style={{ fontSize: "25px" }}></i></button>
                    <button className="btn btn-danger col-sm-2 mr-2 mb-2 popup-btn" onClick={() => this.setState({ modelIsOpenForInactive: false })}><i class="fas fa-times" style={{ fontSize: "29px" }}></i></button> */}
                </Modal>
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>

                    <div className="col-9" style={{ paddingTop: "6px" }}>
                        <p id="popup-title">Remark</p>
                        {/* <p id="popup-content">{this.state.response}</p> */}
                        <p id="popup-content">{this.state.response}</p>

                    </div>
                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary" onClick={() => window.location.reload()}><i class="fas fa-check" style={{ fontSize: "29px" }}></i></button>
                    </div>
                    </div>
                
                
                    
                    {/* <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button> */}
                </Modal>
            </>
        );


    }

}