import { React, Axios, Link, $, dt } from '../../../Headers';
// import Journal from '../../journal';

export default class JournalHome extends React.Component {

  constructor() {
    super();
    this.state = {
      journals: [],
      journalid: '',
      formAction: "ADD",
      modelIsOpenForSubmit: false,
      modelIsOpenForInactive: false,
      modelIsOpenForResponse: false,
      response: '',
      errors: {},
      server: '',
      selectedyear: "",
      selectedyearforvalid: "",
    }
  }

  componentDidMount() {
    const server = window.$name;
    //console.log(server);
    this.setState({ server: server })
    console.log(this.state.formAction);
    Axios.post('//' + window.$name + '/controller/GetJournalForWebController.php')
      .then((res) => {
        this.setState({ journals: res.data })
        //this.setState({articles:res.data});
        console.log(this.state.journals);
      })
      .catch((error) => {
        console.log(error[0]);
        console.log(error[1]);
      });

    // console.log(res)
    // this.setState({name:res.data})
  }

  render() {
    return (
      <div className="row mb-5">
        {/* <div className="col-4" style={{padding:"35px"}}>
                    <div>
                        <p id="home-heading" style={{fontSize:"30px"}}>Journals Categories</p>
                        <p id="home-subheading"><i class="fas fa-chevron-right"></i> Biological Science </p>
                        <p id="home-subheading"><i class="fas fa-chevron-right"></i> Medicine </p>
                        <p id="home-subheading"><i class="fas fa-chevron-right"></i> Case Reports </p>
                        <p id="home-subheading"><i class="fas fa-chevron-right"></i> Physical Sciences </p>
                        <p id="home-subheading"><i class="fas fa-chevron-right"></i> Social Sciences and Education </p>
                        <button id="home-publish-btn">Medicine</button>
                        </div>
                    </div> */}
        <div className="col-12">
          {/* <img src={"./uploads/photo1.png"} style={{filter:"drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",padding:"15px",height:"350px",alignItems:"center",width:"inherit"}} /> */}
          {/* <Journal/> */}
          <div class="row mt-3" style={{ justifyContent: "center", margin: "0", width: "inherit" }}>
            {this.state.journals.map((data) =>
              <div className="col-3 sort-card">
                <Link to={'/journalhome/' + data.journal_id + '/journalspecific'}>
                  {/* <Link to={'/journalhome/'+data.journal_id+'/editorial'}> */}

                  {/* <div class="hover_color_bubble"></div> */}
                  <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + data.journal_image + ")", backgroundSize: "cover" }}>
                  </div>
                  <div style={{ padding: "25px", height: "55%" }}>
                    <div class="sort-card-title">
                      {data.journal_name}
                    </div>

                    <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                      {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                      {data.journal_description}
                    </div>
                    {/* <button  type="button">
              <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
                    <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
                  </div>
                </Link>

              </div>

            )}

          </div>
        </div>
      </div>

    );
  }
}