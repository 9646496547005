import React from 'react'

//  for inside wrapper... In AppContent.js set .content-wrapper height to 92vh and for fixed footer height will be 85vh...
import './FooterInsideWrapper.css';

const AppFooter = () => {
  return (
    <>
  {/* <footer class="main-footer"> */}
  
  {/* for inside wrapper */}
  <div className="justify-content-left">
  <div id="nui-copyright" style={{ right: "0px", paddingRight: "2vh" }}>Designed & Developed by  <a href="https://www.theexponent.co.in/" target="_blank"> The Exponent</a></div>
            {/* </div>
            <div id="nui-copyright" style={{ right: "0px", paddingRight: "2vh" }}>Powered by  <a href="https://www.theexponent.co.in/" target="_blank"> The Exponent</a></div> */}
          </div>
  </>
  )
}

export default React.memo(AppFooter)
