import { useEffect, useState } from "react";
import {
  Axios,
  Modal,
  React,
  Select,
  useParams,
  validator,
} from "../../../Headers";
import {
  BookWriteStatus,
  EducationType,
  ManuscriptStatus,
  TypeOfBook,
} from "../helper";
import Files from "react-files";

const AuthorFormBook = () => {
  const [modelIsOpenForSubmit, setModelIsOpenForSubmit] = useState(false);
  const [modelIsOpenForResponse, setModelIsOpenForResponse] = useState(false);
  const [response, setResponse] = useState("");
  const [selectedBookType, setSelectedBookType] = useState("");
  const [selectedBookTypeForValid, setSelectedBookTypeForValid] = useState("");
  const [selectedManuscriptStatus, setSelectedManuscriptStatus] = useState("");
  const [
    selectedManuscriptStatusForValid,
    setSelectedManuscriptStatusForValid,
  ] = useState("");
  const [bookTitle, setBookTitle] = useState("");
  const [selectedEducationType, setSelectedEducationType] = useState("");
  const [selectedEducationTypeForValid, setSelectedEducationTypeForValid] =
    useState("");
  const [uniqueFeature, setUniqueFeature] = useState("");
  const [aboutBook, setAboutBook] = useState("");
  const [furnishDetail, setFurnishDetail] = useState("");
  const [furnishDetailStatus, setFurnishDetailStatus] = useState(false);
  const [selectedBookWriteStatus, setSelectedBookWriteStatus] = useState("");
  const [selectedBookWriteStatusForValid, setSelectedBookWriteStatusForValid] =
    useState("");
  const [manuscriptDate, setManuscriptDate] = useState("");
  const [manuscriptMonth, setManuscriptMonth] = useState("");
  const [error, setError] = useState({});
  const [server, setServer] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      author: "",
      title: "",
      publisher: "",
    },
  ]);

  const [authorInputFields, setAuthorInputFields] = useState([
    {
      author: "",
      affiliation: "",
    },
  ]);

  const [upload, setUpload] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileName, setFileName] = useState("");

  const [errorFile, setErrorFile] = useState("");
  const [minDate, setMinDate] = useState();
  const [publishMinDate, setPublishMinDate] = useState();

  const { formId, journal } = useParams();

  useEffect(() => {
    const server = window.$name;
    setServer(server);
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are 0-based
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    const minDate = `${year}-${month}-${day}`;

    setMinDate(minDate);
    setPublishMinDate(minDate);
  }, []);

  const onFilesChange = (files) => {
    if (files.length > 0) {
      setUpload(files);
      setFileType(files[0].extension);
      setFileSize(files[0].size);
      setFileName(files[0].name);
    } else if ((files.length = 0)) {
      setErrorFile("Not a Valid File Type");
    }
  };
  const onFilesError = (error, file) => {
    //console.log('error code ' + error.code + ': ' + error.message )
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (validator.isEmpty(selectedBookTypeForValid)) {
      formIsValid = false;
      errors["bookType"] = "Please Select";
    }
    if (validator.isEmpty(selectedManuscriptStatusForValid)) {
      formIsValid = false;
      errors["manuscriptStatus"] = "Please Select";
    }
    if (validator.isEmpty(bookTitle)) {
      formIsValid = false;
      errors["title"] = "Please Enter title";
    }
    if (validator.isEmpty(selectedEducationTypeForValid)) {
      formIsValid = false;
      errors["education"] = "Please Select";
    }
    if (validator.isEmpty(uniqueFeature)) {
      formIsValid = false;
      errors["ups"] = "Please Select";
    }
    if (validator.isEmpty(aboutBook)) {
      formIsValid = false;
      errors["aboutBook"] = "Please Select";
    }
    if (validator.isEmpty(selectedBookWriteStatusForValid)) {
      formIsValid = false;
      errors["BookWriteStatus"] = "Please Select";
    }
    // if (validator.isEmpty(furnishDetail)) {
    //   formIsValid = false;
    //   errors["furnishDetail"] = "Please Select";
    // }
    if (validator.isEmpty(manuscriptDate)) {
      formIsValid = false;
      errors["manuscriptDate"] = "Please Enter Date";
    }
    if (validator.isEmpty(manuscriptMonth)) {
      formIsValid = false;
      errors["manuscriptYear"] = "Please Select";
    }

    if (authorInputFields.length >= 1) {
      if (
        authorInputFields[0]?.author == "" &&
        authorInputFields[0]?.affiliation == ""
      ) {
        formIsValid = false;
        errors["authorField"] = "One Field is mandatory";
      }
    } else {
      formIsValid = false;
      errors["authorField"] = "One Field is mandatory";
    }

    setError(errors);
    return formIsValid;
  };

  const addInputField = () => {
    if (inputFields.length < 5) {
      setInputFields([
        ...inputFields,
        {
          author: "",
          title: "",
          publisher: "",
        },
      ]);
    }
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const addAuthorInputField = () => {
    setAuthorInputFields([
      ...authorInputFields,
      {
        author: "",
        affiliation: "",
      },
    ]);
  };
  const removeAuthorInputFields = (index) => {
    const rows = [...authorInputFields];
    rows.splice(index, 1);
    setAuthorInputFields(rows);
  };

  const handleChangeInputFields = (index, event) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleChangeAuthorInputFields = (index, event) => {
    const { name, value } = event.target;
    const list = [...authorInputFields];
    list[index][name] = value;
    setAuthorInputFields(list);
  };

  const ToOpenModel = (event, button) => {
    event.preventDefault();
    event.persist();
    if (button == "submit") {
      if (handleValidation()) {
        setModelIsOpenForSubmit(true);
      }
    }
  };

  const insertData = (event) => {
    event.preventDefault();
    event.persist();

    console.log("a", inputFields);

    setModelIsOpenForSubmit(false);
    const fd = new FormData();

    fd.append("formId", formId);
    fd.append("bookType", selectedBookType.value);
    fd.append("manuscriptStatus", selectedManuscriptStatus.value);
    fd.append("bookTitle", bookTitle);
    fd.append("educationType", selectedEducationType.value);
    fd.append("uniqueFeature", uniqueFeature);
    fd.append("aboutBook", aboutBook);
    fd.append("furnishDetail", furnishDetail);
    fd.append("furnishDetailStatus", furnishDetailStatus);
    fd.append("selectedBookWriteStatus", selectedBookWriteStatus.value);
    fd.append("manuscriptDate", manuscriptDate);
    fd.append("manuscriptMonth", manuscriptMonth);
    fd.append("upload_file", upload[0]);
    fd.append("file_type", fileType);
    fd.append("file_size", fileSize);
    fd.append("authorTable", JSON.stringify(inputFields));
    fd.append("authorAffiliationTable", JSON.stringify(authorInputFields));

    Axios.post(
      "//" + server + "/controller/WebAuthorBookFormController.php",
      fd
    )
      .then((res) => {
        console.log(res);
        if (res.data.success == 1) {
          if (journal) {
            window.location.assign("/author_form_3/" + formId);
          } else {
            setResponse(res.data.Msg);
            setModelIsOpenForResponse(true);
          }
        } else {
          setResponse(res.data.Msg);
          setModelIsOpenForResponse(true);
          //   alert(res.data.Msg);
          //   window.location.reload();
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const handleBookType = (selectedValue) => {
    setSelectedBookType(selectedValue);
    setSelectedBookTypeForValid(selectedValue.value);
  };

  const handleManuscriptStatus = (selectedValue) => {
    setSelectedManuscriptStatus(selectedValue);
    setSelectedManuscriptStatusForValid(selectedValue.value);
  };

  const handleEducation = (selectedValue) => {
    setSelectedEducationType(selectedValue);
    setSelectedEducationTypeForValid(selectedValue.value);
  };

  const handleBookWriteStatus = (selectedValue) => {
    setSelectedBookWriteStatus(selectedValue);
    setSelectedBookWriteStatusForValid(selectedValue.value);
    if (selectedValue.value === "YES") {
      setFurnishDetailStatus(true);
    } else {
      setFurnishDetailStatus(false);
    }
  };

  const handleValidationDate = (date) => {
    // Create a Date object for the current date
    const currentDate = new Date(date);

    // Add 30 days to the current date
    currentDate.setDate(currentDate.getDate() + 30);

    // Format the result in YYYY-MM-DD format
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; // Months are 0-based
    if (month < 10) {
      month = `0${month}`;
    }
    let day = currentDate.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    const resultDate = `${year}-${month}-${day}`;
    setManuscriptMonth("");
    setPublishMinDate(resultDate);
    console.log(resultDate);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_10">
          <p id="journal-title">Book Form</p>
          <div class="sort_12"></div>
          <div className="container ">
            <form onSubmit={(event) => ToOpenModel(event, "submit")}>
              <center>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleBookType}
                          options={TypeOfBook}
                          value={selectedBookType}
                          placeholder="Book Type"
                        />
                        <span style={{ color: "red" }}>
                          {error["bookType"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group ">
                        <Select
                          id="contact-input2"
                          onChange={handleManuscriptStatus}
                          options={ManuscriptStatus}
                          value={selectedManuscriptStatus}
                          placeholder="Manuscript Status"
                        />
                        <span style={{ color: "red" }}>
                          {error["manuscriptStatus"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group ">
                        <input
                          id="contact-input"
                          type="text"
                          name="title"
                          onChange={(e) => {
                            setBookTitle(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Title Of Book"
                          required
                        />
                        <span style={{ color: "red" }}>{error["title"]}</span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleEducation}
                          options={EducationType}
                          value={selectedEducationType}
                          placeholder="It is a"
                        />
                        <span style={{ color: "red" }}>
                          {error["education"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="aboutBook"
                          onChange={(e) => {
                            setAboutBook(e.target.value);
                          }}
                          className="form-control"
                          placeholder="About Book"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["aboutBook"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="ups"
                          onChange={(e) => {
                            setUniqueFeature(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Please Provide USPs"
                          required
                        />
                        <span style={{ color: "red" }}>{error["ups"]}</span>
                      </div>
                    </div>
                    <div className="col-1 mobile-none">
                      <button
                        style={{
                          borderRadius: "50px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "larger",
                          width: "100%",
                        }}
                        type="button"
                        class="icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Please enter the unique selling points of the book"
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </div>
                  </div>
                  <p>Details of co-authors/editors with affiliation</p>
                  <table
                    style={{
                      width: "100%",
                      position: "relative",
                      width: "100%",
                      zIndex: "0",
                    }}
                  >
                    <thead>
                      <tr>
                        <td>Author</td>
                        <td>Affiliation</td>
                      </tr>
                    </thead>
                    <tbody>
                      {authorInputFields.map((data, index) => {
                        const { author, affiliation } = data;
                        return (
                          <tr>
                            <td>
                              <input
                                type="text"
                                style={{ marginBottom: "8px" }}
                                id="contact-input"
                                className="form-control"
                                placeholder="Author"
                                onChange={(event) =>
                                  handleChangeAuthorInputFields(index, event)
                                }
                                value={author}
                                name="author"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                style={{ marginBottom: "8px" }}
                                id="contact-input"
                                className="form-control"
                                placeholder="Affiliation"
                                onChange={(event) =>
                                  handleChangeAuthorInputFields(index, event)
                                }
                                value={affiliation}
                                name="affiliation"
                              />
                            </td>
                            <span style={{ color: "red" }}>
                              {error["authorField"]}
                            </span>
                            {authorInputFields.length > 1 && index > 0 ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className="btn add-btn m-2"
                                onClick={addAuthorInputField}
                              >
                                +
                              </button>
                            )}
                            {authorInputFields.length !== 1 && index > 0 ? (
                              <button
                                type="button"
                                className="btn remove-btn m-2"
                                onClick={() => removeAuthorInputFields(index)}
                              >
                                -
                              </button>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="row">
                    <div
                      className={`${
                        furnishDetailStatus ? "col-lg-6 col-md-12" : "col-12"
                      }`}
                    >
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleBookWriteStatus}
                          options={BookWriteStatus}
                          value={selectedBookWriteStatus}
                          placeholder="Have you or the co-authors written any book(s) before"
                        />
                        <span style={{ color: "red" }}>
                          {error["BookWriteStatus"]}
                        </span>
                      </div>
                    </div>
                    {furnishDetailStatus && (
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            id="contact-input"
                            type="text"
                            name="FurnishDetails"
                            onChange={(e) => {
                              setFurnishDetail(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Furnish Details"
                          />
                          <span style={{ color: "red" }}>
                            {error["FurnishDetails"]}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <p>Competing Titles</p>
                  <table
                    style={{
                      width: "100%",
                      position: "relative",
                      width: "100%",
                      zIndex: "0",
                    }}
                  >
                    <thead>
                      <tr>
                        <td>Author</td>
                        <td>Title</td>
                        <td>Publisher</td>
                      </tr>
                    </thead>
                    <tbody>
                      {inputFields.map((data, index) => {
                        const { author, title, publisher } = data;
                        return (
                          <tr>
                            <td>
                              <input
                                type="text"
                                style={{ marginBottom: "8px" }}
                                id="contact-input"
                                className="form-control"
                                placeholder="Author"
                                onChange={(event) =>
                                  handleChangeInputFields(index, event)
                                }
                                value={author}
                                name="author"
                              />
                            </td>{" "}
                            <td>
                              <input
                                type="text"
                                style={{ marginBottom: "8px" }}
                                id="contact-input"
                                className="form-control"
                                placeholder="Title"
                                onChange={(event) =>
                                  handleChangeInputFields(index, event)
                                }
                                value={title}
                                name="title"
                              />
                            </td>{" "}
                            <td>
                              <input
                                type="text"
                                style={{ marginBottom: "8px" }}
                                id="contact-input"
                                className="form-control"
                                placeholder="Publisher"
                                onChange={(event) =>
                                  handleChangeInputFields(index, event)
                                }
                                value={publisher}
                                name="publisher"
                              />
                            </td>
                            {inputFields.length > 1 && index > 0 ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className="btn add-btn m-2"
                                onClick={addInputField}
                              >
                                +
                              </button>
                            )}
                            {inputFields.length !== 1 && index > 0 ? (
                              <button
                                type="button"
                                className="btn remove-btn m-2"
                                onClick={() => removeInputFields(index)}
                              >
                                -
                              </button>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <p>Manuscript delivery date</p>
                        <input
                          id="contact-input"
                          type="date"
                          name="manuscriptDate"
                          onChange={(e) => {
                            setManuscriptDate(e.target.value);
                            handleValidationDate(e.target.value);
                          }}
                          min={minDate}
                          className="form-control manuscriptDate"
                          placeholder="Manuscript delivery date"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["manuscriptDate"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <p>What is the right time to publish the book</p>
                        <input
                          id="contact-input"
                          type="date"
                          value={manuscriptMonth}
                          name="manuscriptYear"
                          onChange={(e) => {
                            setManuscriptMonth(e.target.value);
                          }}
                          min={publishMinDate}
                          className="form-control publishDate"
                          placeholder="Manuscript Publish Year"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["manuscriptYear"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={[".pdf", ".docx"]}
                    // multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(70, 80, 85)",
                        padding: "3vh",
                        borderRadius: "25px",
                        color: "white",
                        opacity: ".9",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Drop files here or click to upload
                      <div style={{ color: "#212529" }}>{fileName}</div>
                      <span style={{ color: "red" }}>
                        {error["upload_file"]}
                      </span>
                      <div style={{ color: "red" }}>{errorFile}</div>
                    </div>
                  </Files>

                  <div className="row sort_18">
                    <button type="submit" id="home-publish-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </center>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 sort_13">
          <img src={"../../uploads/rafiki3.png"} class="sort_14" />
        </div>
      </div>
      <Modal
        isOpen={modelIsOpenForSubmit}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => {
          setModelIsOpenForSubmit(false);
        }}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Alert</span>
        </div>
        <div class="card-body">
          <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
          <div className="row web-custom-btn">
            <button
              // id="ss-uad-article-09"
              className="accept-btn"
              onClick={insertData}
            >
              Accept
            </button>
            <button
              // id="ss-uad-article-08"
              className="cancel-btn"
              onClick={() => setModelIsOpenForSubmit(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modelIsOpenForResponse}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => setModelIsOpenForResponse(false)}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Success</span>
        </div>
        <div class="card-body">
          {/* <p id="assign-popup-box-msg">{response}</p> */}
          <p id="assign-popup-box-msg">Thank You</p>
          <div className="row sort_26">
            <button
              id="ss-uad-article-09"
              className="btn btn-primary mr-2 col-5 sort_27"
              onClick={() => window.location.assign("/author_form")}
            >
              <i class="fas fa-check" style={{ fontSize: "29px" }}></i>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthorFormBook;
