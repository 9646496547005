// import {Link} from "../../../Headers"
import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
export default class Menu extends React.Component {

    render() {
        return (
            <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                 <NavLink to={'/manuscript/admin/all'} title="All" id="sort-table-nav-btn" className="btn btn-outline-dark m-2" activeClassName="active"><i class="nav-icon fas fa-globe"></i></NavLink>
                 <NavLink to={'/manuscript/admin/pending'} title="Pending" id="sort-table-nav-btn" className="btn btn-outline-info m-2" activeClassName="active"><i class="nav-icon fas fa-exclamation"></i></NavLink>
                 <NavLink to={'/manuscript/admin/approve'} title="Approve" id="sort-table-nav-btn" className="btn btn-outline-success m-2" activeClassName="active"><i class="fas  fa-check"></i></NavLink>
                 <NavLink to={'/manuscript/admin/reject'} title="Reject" id="sort-table-nav-btn" className="btn btn-outline-danger m-2" activeClassName="active"><i class="fas  fa-times"></i></NavLink>
                 <NavLink to={'/manuscript/admin/adminpublish'} title="Publish" id="sort-table-nav-btn" className="btn btn-outline-warning m-2" activeClassName="active"><i class="fas  fa-paper-plane"></i></NavLink>
            </div>
        );
    }   
}    