import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Axios, Modal, React, Select, validator } from "../../../Headers";
import { Interest } from "../helper";

const AuthorForm = () => {
  const [modelIsOpenForSubmit, setModelIsOpenForSubmit] = useState(false);
  const [modelIsOpenForResponse, setModelIsOpenForResponse] = useState(false);
  const [response, setResponse] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedORCID, setSelectedORCID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [affiliations, setAffiliations] = useState("");
  const [address, setAddress] = useState("");
  const [orcid_id, setOrcid_id] = useState("");
  const [idRequired, setIdRequired] = useState(false);
  const [selectedTitleForValid, setSelectedTitleForValid] = useState("");
  const [ORCIDForValid, setORCIDForValid] = useState("");
  const [error, setError] = useState({});
  const [server, setServer] = useState("");
  const [interest, setInterest] = useState("");
  const [interestForValid, setInterestForValid] = useState("");

  useEffect(() => {
    const server = window.$name;
    setServer(server);
  }, []);

  const handleChangeTitle = (selectedValue) => {
    setSelectedTitle(selectedValue);
    setSelectedTitleForValid(selectedValue.value);
  };

  const handleInterest = (selectedValue) => {
    setInterest(selectedValue);
    setInterestForValid(selectedValue.value);
  };

  const handleOrcid = (selectedValue) => {
    setSelectedORCID(selectedValue);
    setORCIDForValid(selectedValue.value);
    if (selectedValue.value === "1") {
      setIdRequired(true);
    } else {
      setIdRequired(false);
    }
  };

  const handlePhoneInput = (selectedValue) => {
    setMobile(selectedValue);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (validator.isEmpty(selectedTitleForValid)) {
      formIsValid = false;
      errors["title"] = "Please Select";
    }

    if (validator.isEmpty(interestForValid)) {
      formIsValid = false;
      errors["interest"] = "Please Select";
    }

    if (validator.isEmpty(ORCIDForValid)) {
      formIsValid = false;
      errors["orcid"] = "Please Select";
    }

    if (selectedORCID.value === "1" && validator.isEmpty(orcid_id)) {
      formIsValid = false;
      errors["orcid_id"] = "Please Enter";
    }

    if (validator.isEmpty(firstName)) {
      formIsValid = false;
      errors["firstName"] = "Please Enter First Name";
    }

    if (validator.isEmpty(lastName)) {
      formIsValid = false;
      errors["lastName"] = "Please Enter Last Name";
    }

    if (validator.isEmpty(mobile)) {
      formIsValid = false;
      errors["mobile"] = "Please Enter Mobile Number";
    }

    if (validator.isEmpty(email)) {
      formIsValid = false;
      errors["email"] = "Please Enter Email";
    }

    if (validator.isEmpty(affiliations)) {
      formIsValid = false;
      errors["affiliations"] = "Please Enter affiliations";
    }

    if (validator.isEmpty(address)) {
      formIsValid = false;
      errors["address"] = "Please Enter address";
    }

    setError(errors);
    return formIsValid;
  };

  const ToOpenModel = (event, button) => {
    event.preventDefault();
    event.persist();
    if (button == "submit") {
      if (handleValidation()) {
        setModelIsOpenForSubmit(true);
      }
    }
  };

  const insertData = (event) => {
    event.preventDefault();
    event.persist();

    setModelIsOpenForSubmit(false);
    const fd = new FormData();

    fd.append("title", selectedTitle.value);
    fd.append("firstName", firstName);
    fd.append("lastName", lastName);
    fd.append("address", address);
    fd.append("email", email);
    fd.append("mobile", mobile);
    fd.append("affiliations", affiliations);
    fd.append("orcid", selectedORCID.value);
    fd.append("orcid_id", orcid_id);
    fd.append("interest", interest.value);

    Axios.post("//" + server + "/controller/WebAuthorFormData.php", fd)
      .then((res) => {
        if (res.data.success == 1) {
          if (interest.value === "Book") {
            window.location.assign("/author_form_2/" + res.data.latest_id);
          }
          if (interest.value === "Both") {
            window.location.assign(
              "/author_form_2/" + res.data.latest_id + "/" + res.data.latest_id
            );
          }
          if (interest.value === "Journal") {
            window.location.assign("/author_form_3/" + res.data.latest_id);
          }
        } else {
          setResponse(res.data.Msg);
          setModelIsOpenForResponse(true);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const title = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Dr.",
      label: "Dr.",
    },
  ];

  const ORCIDOption = [
    {
      label: "Yes",
      value: "1",
    },
    {
      label: "No",
      value: "0",
    },
  ];

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_10">
          <p id="journal-title">Author's Form</p>
          <div class="sort_12"></div>
          <div className="container ">
            <form onSubmit={(event) => ToOpenModel(event, "submit")}>
              <center>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleChangeTitle}
                          options={title}
                          value={selectedTitle}
                          placeholder="Mr."
                        />
                        <span style={{ color: "red" }}>{error["title"]}</span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="form-group ">
                        <input
                          id="contact-input"
                          type="text"
                          name="firstName"
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-control"
                          placeholder="First Name"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["firstName"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="form-group ">
                        <input
                          id="contact-input"
                          type="text"
                          name="lastName"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Last Name"
                          required
                        />
                        <span style={{ color: "red" }}>
                          {error["lastName"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <PhoneInput
                          id="contact-input"
                          country="IN"
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          name="phone"
                          value={mobile}
                          onChange={handlePhoneInput}
                          required
                        />
                        <span style={{ color: "red" }}>{error["mobile"]}</span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="email"
                          name="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                        <span style={{ color: "red" }}>{error["email"]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`${
                        idRequired ? "col-lg-4 col-md-12" : "col-lg-6 col-md-12"
                      } `}
                    >
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="affiliation"
                          onChange={(e) => {
                            setAffiliations(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Affiliation"
                          required
                        ></input>
                        <span style={{ color: "red" }}>
                          {error["affiliation"]}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${
                        idRequired ? "col-lg-4 col-md-12" : "col-lg-6 col-md-12"
                      } `}
                    >
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleOrcid}
                          options={ORCIDOption}
                          value={selectedORCID}
                          placeholder="Do you have ORCID"
                        />
                        <span style={{ color: "red" }}>{error["orcid"]}</span>
                      </div>
                    </div>
                    {idRequired && (
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            id="contact-input"
                            type="text"
                            name="orcid_id"
                            onChange={(e) => {
                              setOrcid_id(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Enter Id"
                          />
                          <span style={{ color: "red" }}>
                            {error["orcid_id"]}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          id="contact-input"
                          type="text"
                          name="address"
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Address for Correspondence"
                          required
                        />
                        <span style={{ color: "red" }}>{error["address"]}</span>
                      </div>
                    </div>
                    <div className={"col-lg-6 col-md-12"}>
                      <div className="form-group">
                        <Select
                          id="contact-input2"
                          onChange={handleInterest}
                          options={Interest}
                          value={interest}
                          placeholder="Interest"
                        />
                        <span style={{ color: "red" }}>
                          {error["interest"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row sort_18">
                    <button type="submit" id="home-publish-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </center>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-md-12e sort_13">
          <img src={"../../uploads/rafiki3.png"} class="sort_14" />
        </div>
      </div>
      <Modal
        isOpen={modelIsOpenForSubmit}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => {
          setModelIsOpenForSubmit(false);
        }}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Alert</span>
        </div>
        <div class="card-body">
          <p id="assign-popup-box-msg">Do you want to submit this record ?</p>
          <div className="row web-custom-btn">
            <button
              // id="ss-uad-article-09"
              className="accept-btn"
              onClick={insertData}
            >
              Accept
            </button>
            <button
              // id="ss-uad-article-08"
              className="cancel-btn"
              onClick={() => setModelIsOpenForSubmit(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modelIsOpenForResponse}
        className="popup-modal-content-subscribe"
        overlayClassName="popup-modal"
        onRequestClose={() => setModelIsOpenForResponse(false)}
      >
        <span
          class="closed sort_24"
          type="button"
          onClick={() => setModelIsOpenForSubmit(false)}
        >
          &times;
        </span>
        <div className="card-header sort_25" id="ss-uad-article-10">
          <span id="ss-uad-article-12">Success</span>
        </div>
        <div class="card-body">
          <p id="assign-popup-box-msg">{response}</p>

          <div className="row sort_26">
            <button
              id="ss-uad-article-09"
              className="btn btn-primary mr-2 col-5 sort_27"
              onClick={() => window.location.reload()}
            >
              <i class="fas fa-check" style={{ fontSize: "29px" }}></i>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthorForm;
