import React from "react";
export default class OurVision extends React.Component {
  render() {
    return (
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12 sort_04">
          <img
            src={"../../uploads/vision.png"}
            class="sort_14"
            style={{
              width: "inherit",
              filter: "drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",
              position: "sticky",
              top: "115px",
            }}
          />
        </div>
        <div className="col-lg-6 col-md-12 sort_04">
          <div className="row justify-content-start sort_18">
            <p id="journal-title">Our Vision </p>
            <div class="sort_19"></div>
            <p id="prp-content" class="sort_20">
              At Sort Sol, our vision is to be at the forefront of academic
              publishing, empowering researchers to share their discoveries,
              insights, and innovations with the global community. We aim to
              foster intellectual exchange, inspire new ideas, and contribute to
              the advancement of knowledge in collaboration with esteemed
              authors, editors, and reviewers.
            </p>
            <p className="journal_subheading">What Sets Us Apart</p>
            <ul>
              <li id="prp-content">
                <span className="list-title">Expert Editorial Teams : </span>{" "}
                Our journals are led by renowned editorial teams consisting of
                subject matter experts who ensure the highest standards of
                quality and integrity in the publications. They possess a wealth
                of knowledge and experience in their respective fields, guiding
                the publication process with precision and expertise.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Robust Peer Review Process :{" "}
                </span>
                We employ a rigorous peer review process to maintain the
                scholarly rigor and credibility of our journals.Review system,
                carried out by knowledgeable reviewers, provides valuable
                feedback to authors and facilitates the publication of impactful
                research. We have our own state of the art system to make the
                review process faster easier and better for the authors and
                reviewers both. Our review system provides full transparency to
                the users for them to always know what stage is their manuscript
                at.
              </li>
              <li id="prp-content">
                <span className="list-title">Global Reach and Impact : </span>
                With our advanced tech and an expert team, we are targeting the
                global market as we want the INDIAN journals to reach the
                highest possible level of recognition and indexing.
              </li>
              <li id="prp-content">
                <span className="list-title">Cutting-Edge Research : </span>
                Sort Sol is committed to publishing ground-breaking research
                that pushes the boundaries of knowledge. We prioritize
                innovative and impactful studies that address critical
                challenges, offer new perspectives, and contribute to
                advancements in science, technology, humanities, agriculture and
                social sciences.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
