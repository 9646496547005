import logo from './logo.svg';
import './App.css';
import {React,Route,Switch,BrowserRouter as Router} from './Headers'

import SuperAdminLogin from './components/SuperAdmin/components/SuperAdminLogin'
import SuperAdminLogOut from './components/SuperAdmin/components/SuperAdminLogOut'
import SuperAdminLayout from './components/SuperAdmin/layout/DefaultLayout'
// import UploadArticles from './components/UploadArticles'
// import Country from './components/SuperAdmin/layout/DefaultLayout'


import Login from './components/Login'
import Logout from './components/Logout'
import UserLayout from './components/Admin/layout/DefaultLayout'

// import Subscription from './components/Subscription'

import ReviewerLayout from './components/Reviewer/layout/DefaultLayout'

import EditorLayout from './components/Editor/layout/DefaultLayout'

// import Publish from './components/PublishPortal'

// import Journal from './components/journal'

import JournalLayout from './components/JournalWeb/layout/DefaultLayout'
import JournalSpecificLayout from './components/JournalWeb/layout/DefaultLayout2'

import SortWebLayout from './components/SortSolOrgWeb/layout/DefaultLayout'

// import SortSolLogin from './components/SortSolOrgWeb/components/SortSolLogin'
// import SortSolLogout from './components/SortSolOrgWeb/components/SortSolLogout'

import AuthorLayout from './components/Author/layout/DefaultLayout'

import InstituteLayout from './components/Institute/layout/DefaultLayout'

import { Redirect } from 'react-router';




class App extends React.Component{
  
  render(){
      return (
     
      <Router>
          <Switch>
              <Route exact path="/super_login" component={SuperAdminLogin}></Route>
              <Route exact path="/super_logout" component={SuperAdminLogOut}></Route>
              <Route exact path="/super_dashboard" component={SuperAdminLayout}></Route>
              <Route exact path="/super_create_user" component={SuperAdminLayout}></Route>
              <Route exact path="/super_institute_register" component={SuperAdminLayout}></Route>
              {/* <Route exact path="/create_journals" component={SuperAdminLayout}></Route> */}
              {/* <Route exact path="/upload_article" component={UploadArticles}></Route> */}
              <Route exact path="/country" component={SuperAdminLayout}></Route>
              <Route exact path="/create_subject" component={SuperAdminLayout}></Route>
              <Route exact path="/view_user" component={SuperAdminLayout}></Route>
              {/* <Route exact path="/editorialboard" component={SuperAdminLayout}></Route> */}
              <Route exact path="/superarticle/:article_id" component={SuperAdminLayout}></Route>
              <Route exact path="/manuscript/superadmin">
                  <Redirect to="/manuscript/superadmin/pending" />
              </Route>
              <Route exact path="/manuscript/superadmin/pending" component={SuperAdminLayout}></Route>
              <Route exact path="/manuscript/superadmin/approve" component={SuperAdminLayout}></Route>
              <Route exact path="/manuscript/superadmin/reject" component={SuperAdminLayout}></Route>
              <Route exact path="/manuscript/superadmin/all" component={SuperAdminLayout}></Route>
              <Route exact path="/superarticledetails/:article_id" component={SuperAdminLayout}></Route>
              <Route exact path="/contact_us" component={SuperAdminLayout}></Route>
              <Route exact path="/superarticlehistory/:article_id/:status" component={SuperAdminLayout}></Route>


              <Route exact path="/login" component={Login}></Route>
              <Route exact path="/logout" component={Logout}></Route>


              <Route exact path="/user_dashboard" component={UserLayout}></Route>
              <Route exact path="/article/:article_id" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin/pending" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin/approve" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin/reject" component={UserLayout}></Route>
              <Route exact path="/articlehistory/:article_id/:status" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin/adminpublish" component={UserLayout}></Route>
              <Route exact path="/admintopublish/:article_id/:status" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin/all" component={UserLayout}></Route>
              <Route exact path="/articledetails/:article_id" component={UserLayout}></Route>
              <Route exact path="/create_journals" component={UserLayout}></Route>
              <Route exact path="/journals_details" component={UserLayout}></Route>
              <Route exact path="/author_form_details" component={UserLayout}></Route>
              <Route exact path="/admin/subs/pending" component={UserLayout}></Route>
              <Route exact path="/admin/subs/approve" component={UserLayout}></Route>
              <Route exact path="/manuscript/admin">
                  <Redirect to="/manuscript/admin/pending" />
              </Route>
              <Route exact path="/admin/subs">
                  <Redirect to="/admin/subs/pending" />
              </Route>
           
              {/* <Route exact path="/subscription" component={Subscription}></Route> */}

               {/* <Route exact path="/reviewer_dashboard" component={ReviewerLayout}></Route> */}
              <Route exact path="/manuscript/Reviewer/pending" component={ReviewerLayout}></Route>
              <Route exact path="/manuscript/Reviewer/approve" component={ReviewerLayout}></Route>
              <Route exact path="/manuscript/Reviewer/reject" component={ReviewerLayout}></Route>
              <Route exact path="/rarticle/:article_id" component={ReviewerLayout}></Route>
              <Route exact path="/reviewerhistory/:article_id/:status" component={ReviewerLayout}></Route>
              {/* <Route exact path="/reviewer_approve/:article_id" component={ReviewerLayout}></Route> */}
              <Route exact path="/manuscript/Reviewer">
                  <Redirect to="/manuscript/Reviewer/pending" />
              </Route>
              {/* <Route exact path="/editor_dashboard" component={EditorLayout}></Route> */}
              <Route exact path="/manuscript/editor/pending" component={EditorLayout}></Route>
              <Route exact path="/manuscript/editor/approve" component={EditorLayout}></Route>
              <Route exact path="/manuscript/editor/reject" component={EditorLayout}></Route>
              <Route exact path="/earticle/:article_id" component={EditorLayout}></Route>
              <Route exact path="/editorhistory/:article_id/:status" component={EditorLayout}></Route>
              <Route exact path="/manuscript/editor">
                  <Redirect to="/manuscript/editor/pending" />
              </Route>
              {/* <Route exact path="/publish" component={Publish}></Route> */}

             

             
              {/* <Route exact path="/journal" component={Journal}></Route> */}

              <Route exact path="/journalhome/home" component={JournalLayout}></Route>
              <Route exact path="/journalhome/:journal_id/archive" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/currentissue" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/editorialboard" component={JournalSpecificLayout}></Route>
              {/* <Route exact path="/journallist" component={JournalLayout}></Route> */}
              {/* <Route exact path="/journalarticle" component={JournalLayout}></Route> */}
              {/* <Route exact path="/journalhome/journalmanuscript" component={JournalLayout}></Route> */}
              {/* <Route exact path="/journalj" component={JournalLayout}></Route> */}
              <Route exact path="/journalhome/:journal_id/colourcharge" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/aimscope" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/apc" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/policy" component={JournalLayout}></Route>
              <Route exact path="/journalhome/:journal_id/editorial" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/pagecharge" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/submissionfee" component={JournalSpecificLayout}></Route>
              <Route exact path="/journalhome/:journal_id/journalspecific" component={JournalSpecificLayout}></Route>
              {/* <Route exact path="/journalhome/sortmanuscript" component={JournalLayout}></Route> */}
              {/* <Route exact path="/guideline" component={JournalLayout}></Route> */}
              <Route exact path="/journalhome/contactform" component={JournalLayout}></Route>
              <Route exact path="/journalhome/copyright" component={JournalLayout}></Route>
              <Route exact path="/journalhome/licensing" component={JournalLayout}></Route>
              <Route exact path="/journalhome/guideline" component={JournalLayout}></Route>
              {/* <Route exact path="/journalspecific/:journal_id" component={JournalLayout}></Route> */}

              <Route exact path="/home" component={SortWebLayout}></Route>
              <Route exact path="/author_and_reviewer" component={SortWebLayout}></Route>
              <Route exact path="/contact_form" component={SortWebLayout}></Route>
              {/* <Route exact path="/sortguideline" component={SortWebLayout}></Route> */}
              {/* <Route exact path="/wkconference" component={SortWebLayout}></Route> */}
              {/* <Route exact path="/customerservices" component={SortWebLayout}></Route> */}
              <Route exact path="/our_vision" component={SortWebLayout}></Route>
              <Route exact path="/peer_review_process" component={SortWebLayout}></Route>
              <Route exact path="/technology" component={SortWebLayout}></Route>
              {/* <Route exact path="/plagiarism" component={SortWebLayout}></Route> */}
              {/* <Route exact path="/sortsubscription" component={SortWebLayout}></Route> */}
              <Route exact path="/sortmanuscript" component={SortWebLayout}></Route>
              <Route exact path="/author_form" component={SortWebLayout}></Route>
              <Route exact path="/author_form_2/:formId/:journal?" component={SortWebLayout}></Route>
              <Route exact path="/author_form_3/:formId" component={SortWebLayout}></Route>
              {/* <Route exact path="/author_register" component={SortWebLayout}></Route> */}
              {/* <Route exact path="/sortsollogin" component={SortWebLayout}></Route> */}
              {/* <Route exact path="/sortsollogout" component={SortWebLayout}></Route> */}

            
              <Route exact path="/author_dashboard" component={AuthorLayout}></Route>
              <Route exact path="/author_manuscript" component={AuthorLayout}></Route>
              <Route exact path="/manuscript/author/all" component={AuthorLayout}></Route>
              {/* <Route exact path="/payment/:article_id" component={AuthorLayout}></Route> */}
              {/* <Route exact path="/manuscript/author/p_pending" component={AuthorLayout}></Route> */}
              <Route exact path="/manuscript/author/f_pending" component={AuthorLayout}></Route>
              <Route exact path="/success/:p_id/:o_id" component={AuthorLayout}></Route>
              <Route exact path="/fail/:p_id/:o_id" component={AuthorLayout}></Route>
              <Route exact path="/author_article/:article_id" component={AuthorLayout}></Route>
              <Route exact path="/receipt/:article_id/:type" component={AuthorLayout}></Route>

              <Route exact path="/institute_dashboard" component={InstituteLayout}></Route>
              <Route exact path="/institute_manuscript" component={InstituteLayout}></Route>
              <Route exact path="/manuscript/institute/all" component={InstituteLayout}></Route>

              <Route path="/" component={SortWebLayout}></Route>  
          </Switch>
      </Router>
     );
    }
}
export default App;
