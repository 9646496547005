import React, { Component } from 'react';
// import { $, Axios, Modal,Redirect,validator } from '../../../Headers'

import ContactUs from '../../ContactUs';

export default class Contact_Form extends React.Component {
  
    render() {
        
        return (
            <>
            <ContactUs/>
            </>
        );
    }
}