import { useEffect, useState } from "react";
import { Axios, Modal, $, API_IMG } from "../../../Headers";
import AuthorFormModal from "./AuthorFormModal/AuthorFormModal";
import AuthorJournalFormModal from "./AuthorFormModal/AuthotrJournalModal";

const AuthorFormDetails = () => {
  const [formData, setFormData] = useState([]);
  const [modelBookStatus, setModelBookStatus] = useState(false);
  const [modelIsOpenForResponse, setModalIsOpenForResponse] = useState(false);
  const [modelIsOpenForExcelResponse, setModalIsOpenForExcelResponse] =
    useState(false);
  const [modelJournalStatus, setModelJournalStatus] = useState(false);
  const [response, setResponse] = useState("");
  const [responseExcel, setResponseExcel] = useState("");
  const [server, setServer] = useState("");
  const [bookData, setBookData] = useState([]);
  const [authorTableData, setAuthorTableData] = useState([]);
  const [authorAffiliationTable, setAuthorAffiliationTable] = useState([]);
  const [journalData, setJournalData] = useState([]);
  const [tableUpdate, setTableUpdate] = useState(false);

  useEffect(() => {
    const server = window.$name;
    setServer(server);

    Axios.post(
      "//" + window.$name + "/controller/WebGetAuthorFormDetailsController.php"
    )
      .then((res) => {
        setFormData(res.data);
        var dataTable = $("#table").DataTable({
          responsive: true,
          fnRowCallback: function (
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            //debugger;
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },
          columnDefs: [
            { className: "dt-center", targets: "_all" },
            { responsivePriority: 1, targets: -1 },
            { responsivePriority: 2, targets: 1 },
            { responsivePriority: 3, targets: 0 },
          ],
        });
        $("#searchbox").on("keyup search input paste cut", function () {
          dataTable.search(this.value).draw();
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [tableUpdate]);

  const handleBookModal = () => {
    setModelBookStatus(!modelBookStatus);
  };
  const handleJournalModal = () => {
    setModelJournalStatus(!modelJournalStatus);
  };
  const DeleteData = (Data) => {
    const fd = new FormData();
    fd.append("selectedId", Data.id);
    Axios.post(
      "//" + window.$name + "/controller/WebAuthorFormDeleteController.php",
      fd
    )
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setResponse(res.data.Msg);
          setModalIsOpenForResponse(true);
          setTimeout(() => {
            setModalIsOpenForResponse(false);
          }, [100]);
          setTableUpdate(!tableUpdate);
        } else {
          setResponse("Some Error");
          setModalIsOpenForResponse(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const action = (Data, type) => {
    // if (Data.interest === "Journal") {
    handleType(Data.id, type);
    // }
    // if (Data.interest === "Book") {
    //   handleType(Data.id, "Book");
    // }
    // this.setState({ modelIsOpenForSubmit: true });
  };

  const handleType = (selectedId, type) => {
    const fd = new FormData();
    fd.append("selectedId", selectedId);
    fd.append("type", type);
    Axios.post(
      "//" + window.$name + "/controller/WebAuthorFormModalController.php",
      fd
    )
      .then((res) => {
        console.log(res);
        if (type === "Book") {
          if (res.data.BookData && res.data.BookData.length > 0) {
            setBookData(res.data.BookData);
            setAuthorTableData(JSON.parse(res.data.BookData[0].authorTable));
            setAuthorAffiliationTable(
              JSON.parse(res.data.BookData[0].authorAffiliationTable)
            );
            setModelBookStatus(!modelBookStatus);
          } else {
            setResponse("Data Not Available");
            setModalIsOpenForResponse(true);
          }
        }
        if (type === "Journal") {
          if (res.data && res.data.length > 0) {
            setJournalData(res.data);
            setModelJournalStatus(!modelJournalStatus);
          } else {
            setResponse("Data Not Available");
            setModalIsOpenForResponse(true);
          }
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const downloadExcel = () => {
    Axios.post(
      "//" + window.$name + "/controller/WebFormDownloadController.php"
    )
      .then((res) => {
        console.log(res.data.Success);
        if (res.data.Success == 1) {
          setResponseExcel(res.data.Msg);
          setModalIsOpenForExcelResponse(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div id="ss-uad-article-01">
        <div className="row" id="ss-uad-article-02">
          <div
            className="col-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p id="ss-uad-admin-02">Form Details</p>
          </div>
          <div
            className="col-4"
            style={{ display: "flex", alignItems: "center" }}
          ></div>
          <div className="col-4 download_excel">
            <span className="btn" onClick={downloadExcel}>Download Excel</span>
          </div>
        </div>
        <div className="card-body" id="card_body">
          <div className="container-fluid">
            <center>
              <table
                id="table"
                className="display hover dt[-head|-body]-justify"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Mobile</th>
                    <th>Affiliations</th>
                    <th>Interest</th>
                    <th>ORC ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formData &&
                    formData.map((data) => (
                      <tr>
                        <td></td>
                        <td>{data.title}</td>
                        <td>{data.first_name + " " + data.last_name}</td>
                        <td>{data.email}</td>
                        <td>{data.address}</td>
                        <td>{data.mobile}</td>
                        <td>{data.affiliations}</td>
                        <td>{data.interest}</td>
                        <td>{data.orcid_id}</td>
                        <td className="book-action">
                          {data.interest === "Book" && (
                            <button
                              style={{ padding: "0" }}
                              title="Approve"
                              type="button"
                              id="view"
                              class="btn-lg   responsive_btn"
                              onClick={() => action(data, "Book")}
                            >
                              <i class="fa-solid fa-book"></i>
                            </button>
                          )}
                          {data.interest === "Journal" && (
                            <button
                              style={{ padding: "0" }}
                              title="Approve"
                              type="button"
                              id="view"
                              class="btn-lg   responsive_btn"
                              onClick={() => action(data, "Journal")}
                            >
                              <i class="fa-solid fa-book-journal-whills"></i>
                            </button>
                          )}
                          {data.interest === "Both" && (
                            <div style={{ display: "flex" }}>
                              <button
                                style={{ padding: "0", marginRight: "4px" }}
                                title="Book"
                                type="button"
                                id="view"
                                class="btn-lg   responsive_btn"
                                onClick={() => action(data, "Book")}
                              >
                                <i class="fa-solid fa-book"></i>
                              </button>
                              <button
                                style={{ padding: "0", marginRight: "4px" }}
                                title="Journal"
                                type="button"
                                id="view"
                                class="btn-lg   responsive_btn"
                                onClick={() => action(data, "Journal")}
                              >
                                <i class="fa-solid fa-book-journal-whills"></i>
                              </button>
                            </div>
                          )}
                          <button
                            style={{ padding: "0" }}
                            title="Delete"
                            type="button"
                            id="view"
                            class="btn-lg   responsive_btn"
                            onClick={() => DeleteData(data)}
                          >
                            <i class="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </center>
          </div>
        </div>
      </div>
      <AuthorFormModal
        bookData={bookData}
        authorTableData={authorTableData}
        authorAffiliationTable={authorAffiliationTable}
        modalStatus={modelBookStatus}
        handleModal={handleBookModal}
      />
      <AuthorJournalFormModal
        journalData={journalData}
        modalStatus={modelJournalStatus}
        handleModal={handleJournalModal}
      />
      <Modal
        isOpen={modelIsOpenForResponse}
        className="popup-modal-content-res"
        overlayClassName="popup-modal"
        onRequestClose={() => setModalIsOpenForResponse(false)}
      >
        <div className="row" style={{ height: "100%", margin: "0px" }}>
          <div className="col-12" style={{ paddingTop: "6px" }}>
            <p
              id="popup-title"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Response
            </p>
            <p
              id="popup-content"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {response}
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modelIsOpenForExcelResponse}
        className="popup-modal-content-res"
        overlayClassName="popup-modal"
        onRequestClose={() => setModalIsOpenForExcelResponse(false)}
      >
        <div className="row" style={{ height: "100%", margin: "0px" }}>
          <div className="col-12" style={{ paddingTop: "6px" }}>
            <p
              id="popup-title"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Response
            </p>
            <p
              id="popup-content"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {responseExcel}
            </p>
            <div style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}>
            <a className="popup-btn ml-3" target='_blank' href={API_IMG + "/formData.xlsx"}>Download</a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AuthorFormDetails;
