import React from 'react';
import { Axios } from '../../../Headers'
import JournalNav from "./JournalNav";
export default class ColourCharges extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ColourChargesData: [],
            Journal_id: "",
            JournalName: "",
            JournalData: "",
        }
    }
    componentDidMount() {

        const journal_id = this.props.match.params.journal_id;
        this.setState({Journal_id: journal_id})

        Axios.all([
            Axios.post('//' + window.$name + '/controller/GetJournalsDetailsForWebController.php',
            {
                journal_id: journal_id,
            }),
            Axios.post('//' + window.$name + '/controller/GetJournalByIdController.php',
            {
                journal_id: journal_id
            })
        ])
            .then((res) => {
                //console.log(res)
                if(res[0].data.Success==1)
                {
                this.setState({ ColourChargesData: res[0].data.Data[0]['colour_charges'] })
                }
                this.setState({ JournalData: res[1].data[0] });
                this.setState({ JournalName: res[1].data[0].journal_name})
            })
    }
    render() {
        return (
            <>
             {/* <JournalNav JournalName={this.state.JournalName} JournalId={this.state.Journal_id} /> */}
            <div className="row mb-5">
            <div className="col-md-4" style={{ padding: "35px" }}>
                        {/* <img src={"../../uploads/journals/" + data.journal_image} style={{ height: "inherit", width: "inherit" }} /> */}
                        <div className="sort-card">
                            {/* <Link to={'/journalspecific/'+data.journal_id}> */}

                            {/* <div class="hover_color_bubble"></div> */}
                            <div class="so_top_icon" style={{ width: "100%", height: "45%", borderRadius: "15px 15px 0 0", backgroundImage: "url(../../uploads/journals/" + this.state.JournalData.journal_image + ")", backgroundSize: "cover" }}>
                            </div>
                            <div style={{ padding: "25px", height: "55%" }}>
                                <div class="sort-card-title">
                                    {this.state.JournalData.journal_name}
                                </div>

                                <div class="sort-card-content" style={{ height: "70%", overflow: "auto" }}>
                                    {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. */}
                                    {this.state.JournalData.journal_description}
                                </div>
                                {/* <button  type="button">
              <Link to={'/journalspecific/'+data.journal_id} target="_blank" >Read More</Link></button> */}
                                <div style={{ paddingTop: "5px" }}>Last Updated on 2 Oct 2021</div>
                            </div>
                            {/* </Link> */}
                        </div>
                    </div>
                <div className="col-md-8" style={{ padding: "25px" }}>
                    <div>
                        <p id="home-heading" style={{fontSize:"35px"}}>Colour Charges</p>
                        <div style={{ borderTop: "2px solid black", width: "10%" }}></div>
                        <ul>
                            {/* <li> */}
                            {/* <p id="prp-subheading">What is a journal aims and scope statement?</p> */}
                            {/* <p id="prp-content">In the past, print journals often charged authors for printing their article in colour, as colour printing was (and still is) more expensive than printing in black and white.</p> */}
                            <p id="prp-content">{this.state.ColourChargesData}</p>
                            {/* </li> */}
                            {/* <li> */}
                            {/* <p id="prp-subheading">The aims and scope statement includes:</p> */}
                            {/* <p id="prp-content">With online publication there is no difference in cost, so it doesn’t make sense for journals to charge authors for colour for the online version of an article.But some journals are still charging for colour printing.</p> */}
                            {/* <p id="prp-content">An outline of the subjects covered</p>
               <p id="prp-content">The type of articles published (and what it doesn’t publish)</p>
               </li> */}
                        </ul>
                        {/* <div className="row justify-content-start" style={{width:"100%",margin:"0",paddingLeft:"20px"}}>
            <button id="journal-view-btn">Learn More</button>
           </div> */}

                    </div>
                </div>


                {/* <div className="col-6">
                    <img src={"../../uploads/photo2.png"} style={{ width: "inherit", filter: "drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))", height: "450px", padding: "15px" }} />
                </div> */}
            </div>
            </>
        );
    }
}