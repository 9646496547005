import React from "react";
export default class AuthorEthics extends React.Component {
  render() {
    return (
      <div className="row mobile-flex-reverse">
        <div className="col-lg-6 col-md-12 sort_10">
          <div className="row justify-content-start sort_11">
            <p id="journal-title">Authors and Reviewers</p>
            <div class="sort_12"></div>
            <p id="prp-content">
              We value the contributions of authors and reviewers who play a
              vital role in the publication process. We provide comprehensive
              guidelines and resources to support authors in preparing and
              submitting their manuscripts. For reviewers/editors and authors,
              we offer detailed guidelines to ensure fair and constructive
              evaluations that enhance the quality of published research. We
              guide our authors and researchers so that they can follow the
              guidelines suggested by COPE and WAME, (give links to COPE AND
              WAME ) NMC,DOAJ,PUBMED,SCOPUS,WEB OF SCIENCE,NAAS RATINGS and many
              other agencies.
            </p>
            <p className="journal_subheading">Author Ethics and Guidelines </p>
            <ul>
              <li id="prp-content">
                <span className="list-title">
                  Originality and Plagiarism :{" "}
                </span>
                Authors should ensure that their submissions are original and
                have not been published elsewhere. Proper attribution must be
                given to the work of others through accurate citations and
                references. Plagiarism in any form is strictly prohibited and
                can result in immediate rejection or retraction of the
                publication. At SORT SOL we use the best available plagiarism
                check software’s available and take this as the most important
                task before the review process is initiated.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Authorship and Acknowledgments :{" "}
                </span>
                All individuals who have made significant contributions to the
                research should be listed as authors. It is essential to
                acknowledge the contributions of others who have influenced the
                work but do not meet the criteria for authorship. Authors should
                disclose any potential conflicts of interest that may influence
                the interpretation of the research. We also tie up our authors
                articles with their ORCID id’s so their research can be
                credited.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Data Integrity and Reproducibility :{" "}
                </span>
                Authors should present their findings accurately and honestly.
                Data should be collected, analysed, and reported in a
                transparent manner. Raw data should be retained and made
                available upon request to ensure the reproducibility of the
                research.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Ethical Treatment of Human and Animal Subjects :{" "}
                </span>
                Authors conducting research involving human or animal subjects
                must comply with ethical guidelines and obtain appropriate
                consent and approvals. The welfare and rights of subjects must
                be protected, and the methods used should be described clearly
                in the manuscript. Any clinical trial being conducted should
                have proper approval from the ethics committee and a mandatory
                CTRI (or a countries’ respective agency) registration number.
              </li>
              <li id="prp-content">
                <span className="list-title">
                  Disclosure of Funding and Conflicts of Interest :{" "}
                </span>
                Authors should disclose all sources of funding and any conflicts
                of interest that may influence the research or its outcomes.
                Financial support, affiliations, or relationships that may
                present a potential bias should be acknowledged.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 sort_04">
          <img
            src={"../../uploads/bro.png"}
            style={{
              width: "inherit",
              filter: "drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.3))",
              position: "sticky",
              top: "115px",
            }}
          />
        </div>
      </div>
    );
  }
}
