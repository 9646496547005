import { React, Axios, Link, Redirect, Modal, $, dt, Select } from "../../../Headers"
// import Header from './Header'



export default class ViewUser extends React.Component {
    constructor() {
        super();
        this.state = {
            UserData: [],
            countryid: '',
            formAction: "ADD",
            modelIsOpenForInactive: false,
            modelIsOpenForResponse: false,
            response: '',
            errors: {},
            server: '',
            user_id: "",
        }
    }

    InactiveUserModel = (data) => {
        this.setState({ user_id: data.user_id })
        this.setState({ modelIsOpenForInactive: true })
    }
    InactiveUser = () => {
        this.setState({ modelIsOpenForInactive: false });

        Axios.put('//' + this.state.server + '/controller/InactiveUserController.php',
            {
                user_id: this.state.user_id
            })
            .then(function (res) {
                if (res.data.success == 1) {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
                else {
                    this.setState({
                        response: res.data.Msg,
                        modelIsOpenForResponse: true
                    })
                    //console.log(res);
                }
            }
                .bind(this))
            .catch(function (error) {
                //console.log(error);
            });
    };



    componentDidMount() {
        const server = window.$name;
        ////console.log(server);
        this.setState({ server: server })
        //console.log(this.state.formAction);
        Axios.post('//' + window.$name + '/controller/GetUserForTableController.php',
        )
            .then((res) => {
                this.setState({ UserData: res.data });
                //this.setState({articles:res.data});
                //console.log(this.state.UserData);

                var dataTable = $('#table').DataTable({
                    responsive: true,
                    "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        //debugger;
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
                    columnDefs: [
                        { "className": "dt-center", "targets": "_all" },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                        { responsivePriority: 3, targets: 0 }
                    ]
                });
                $("#searchbox").on("keyup search input paste cut", function () {
                    dataTable.search(this.value).draw();
                });
            })
            .catch((error) => {
                //console.log(error[0]);
                //console.log(error[1]);
            });

        // //console.log(res)
        // this.setState({name:res.data})
    }

    render() {
        return (
            <>
                <div>
                    {/* <section class="content mt-3">
                        <div class="container d-flex justify-content-center">

                            <div className="card shadow-sm">
                                <div className="card shadow-sm">
                                    <h1 className="card-header text-center text-uppercase h1" id="card_header">View User</h1>
                                    */}
                    <div id="ss-uad-article-01">
                        <div className="row" id="ss-uad-article-02">
                            <div className="col-4" style={{ display: "flex", alignItems: "center" }}>
                                <p id="ss-uad-admin-02">View User</p>
                            </div>
                            <div className="col-4"></div>
                            <div class="col-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div class="input-group">
                                    <i id="ss-sad-login-011" class="fa fa-search" ></i>
                                    <input class="form-control" type="text" id="searchbox" placeholder="Search" />
                                </div>
                            </div>
                        </div>
                        <div className="card-body" id="card_body">
                            <div className="container-fluid" >
                                <center>
                                    <table id="table" className="display hover dt[-head|-body]-justify" style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "20%" }}>#</th>
                                                <th style={{ width: "30%" }}>User Name</th>
                                                <th style={{ width: "20%" }}>Name</th>
                                                <th style={{ width: "20%" }}>Email</th>
                                                <th style={{ width: "20%" }}>Role</th>
                                                <th style={{ width: "20%" }}>Subject</th>
                                                <th style={{ width: "20%" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.UserData.map((data) =>
                                                <tr>
                                                    <td style={{ width: "20%" }}></td>
                                                    <td style={{ width: "30%" }}>{data.user_name}</td>
                                                    <td style={{ width: "20%" }}>{data.firstname + " " + data.lastname}</td>
                                                    <td style={{ width: "20%" }}>{data.email}</td>
                                                    <td style={{ width: "20%" }}>{data.role_name}</td>
                                                    <td style={{ width: "40%" }}>{data.subject_name}</td>
                                                    <td style={{ width: "20%" }}>
                                                        <div className="btn-group">
                                                            <button type="button" class="btn-lg  responsive_btn" id="Inactive" title="Inactive" value="Inactive" onClick={() => this.InactiveUserModel(data)} ><i class="fas fa-eye"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                {/* </section> */}
                {/* </div> */}
                <Modal isOpen={this.state.modelIsOpenForInactive} className="popup-modal-content-subscribe" overlayClassName="popup-modal" onRequestClose={() => this.setState({ modelIsOpenForInactive: false })}>
                    <span class="closed" type="button" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} onClick={() => this.setState({ modelIsOpenForInactive: false })}>&times;</span>
                    <div className="card-header" id="ss-uad-article-10" style={{ justifyContent: "flex-start", paddingLeft: "5%" }}>
                        {/* <i class="fas fa-comment-alt mr-2" id="ss-uad-article-11"></i> */}
                        <span id="ss-uad-article-12" >Alert</span>
                    </div>
                    <div class="card-body">
                        <p id="assign-popup-box-msg">Are you Sure want to inactive the User?</p>
                        {/* <h1>Approve</h1> */}
                        <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button id="ss-uad-article-09" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" onClick={this.InactiveUser}>Done</button>
                            <button id="ss-uad-article-08" style={{ height: "50px", lineHeight: "initial", padding: "initial", width: "110px" }} className="btn btn-primary mr-2 col-5" onClick={() => this.setState({ modelIsOpenForInactive: false })}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <span class="closed" onClick={() => window.location.reload()}>&times;</span>
                    <h4>{this.state.response}</h4>
                    <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() => window.location.reload()}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
                </Modal> */}
                <Modal isOpen={this.state.modelIsOpenForResponse} className="popup-modal-content-success" overlayClassName="popup-modal" onRequestClose={() => window.location.reload()}>
                    <span class="closed" style={{ fontSize: "32px", position: "absolute", zIndex: "1", right: "3%", height: "6.7vh", lineHeight: "6.7vh" }} type="button" onClick={() => window.location.reload()}>&times;</span>
                    <div className="row" style={{ height: "100%", margin: "0px" }}>
                        <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className="popup-circle-check">
                                <i class="fas fa-check" style={{ color: "#6FCF97", fontSize: "x-large" }}></i>
                            </div>
                        </div>
                        <div className="col-9" style={{ paddingTop: "6px" }}>
                            <p id="popup-title">Success</p>
                            <p id="popup-content">{this.state.response}</p>
                            {/* <p id="popup-content">Sucessfully approved and assigined to a reviewer and an email alert has been sent to other users.</p> */}
                        </div>
                    </div>

                </Modal>
            </>
        );
    }
}