import { React, Axios, Link,validator, Redirect, Modal } from "../Headers"

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      RedirectState: false,
      server: '',
      modelIsOpenForResponse: false,
      response: '',
      redirection: false,
      role_id: "",
      errors: {},
    }
  }
  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    let user_name = this.user_name.value;
    let password = this.password.value;

    if (!validator.isAlphanumeric(user_name)) {
      formIsValid = false;
      errors["user_name"] = "Please Enter User Name";
    }
    if (!validator.isStrongPassword(password)) {
      formIsValid = false;
      errors["password"] = "minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  componentDidMount() {
    const server = window.$name;
    //console.log(server);
    this.setState({ server: server })
  }
  // static contextType = AppContext;
  insertUser = (event) => {
    event.preventDefault();
    event.persist();
    if (this.handleValidation()) {
    Axios.post('//' + this.state.server + '/controller/UserLogController.php',
      {
        user_name: this.user_name.value,
        password: this.password.value,
      })
      .then(function (res) {
        //console.log(res);
        if (res.data.valid == true) {
          // alert(res.data.value.Msg);
          // this.setState({Redirectstate:true});
          //console.log(res);
           //console.log(res.data.value.Users[0]['role']);
          this.setState({ role_id: res.data.value.Users[0]['role'] });
          localStorage.setItem('user', res.data.value.Users[0]['user_id']);
          localStorage.setItem('role', res.data.value.Users[0]['role']);
          localStorage.setItem('user_firstname', res.data.value.Users[0]['firstname']);
          localStorage.setItem('user_lastname', res.data.value.Users[0]['lastname']);
          localStorage.setItem('priority', res.data.value.Users[0]['priority'])
          this.setState({ Redirectstate: true });
          //console.log( res.data.value.Users[0]['priority']);
        }
        else if (res.data.valid == false) {
          alert(res.data.value.Msg);
          // this.setState({response:res.data.value.Msg,modelIsOpenForResponse:true,redirection:false});
        }
        else {
          alert(res);
          //console.log(res);
        }
      }
        .bind(this))
      .catch(function (error) {
        //console.log(error);
      });
    }
  }
  render() {
    if (this.state.Redirectstate == true && this.state.role_id == "1") {
      return <Redirect to={'/user_dashboard'} />
    }
    if (this.state.Redirectstate == true && this.state.role_id == "2") {
      return <Redirect to={'/manuscript/Reviewer/pending'}
      />
    }
    if (this.state.Redirectstate == true && this.state.role_id == "3") {
      return <Redirect to={'/manuscript/editor/pending'} />
    }
    return (

      <>
        <body>
          <div style={{ backgroundImage: "url('../../uploads/logo-black.png')" }} id="ss-sad-login-001"></div>
          <div className="container-fluid">
            <div className="row" id="ss-sad-login-002">
              <div className="col-7" id="ss-sad-login-003">
                <img src={"../../uploads/banner1.png"} className="ss-sad-login-004" />
                {/* <div style={{width:"inherit",height:"inherit"}}> */}
                <img src={"../../uploads/banner2.png"} className="ss-sad-login-004" />
                {/* <img  src={"../../uploads/logo-with-text-white.png"} style={{width:"inherit",height:"100vh"}}/> */}
                <div id="ss-sad-login-005" style={{ backgroundImage: "url('../../uploads/logo-with-text-white.png')" }}></div>
                {/* </div> */}
              </div>
              <div id="ss-sad-login-006" class="col-5 login-box" >
                {/* <!-- /.login-logo --> */}
                <div id="ss-sad-login-007" class="card" >
                  <div id="ss-sad-login-008" class="card-header text-left">
                    <div class="ss-sad-login-009" >Login</div>
                  </div>
                  <div id="ss-sad-login-010" class="card-body">
                    {/* <p class="login-box-msg">Sign in to start your session</p> */}
                    <form onSubmit={this.insertUser}>
                      <div class="input-group mb-3">
                        <i id="ss-sad-login-011" class="fa fa-user" ></i>
                        <input type="text" name="user_name" ref={(val) => this.user_name = val} class="form-control" id="ss-sad-login-013" placeholder="User Name" required />
                        <span style={{ color: "red" }}>{this.state.errors["user_name"]}</span>
                      </div>
                      <div class="input-group mb-3">
                        <i id="ss-sad-login-012" class="fa fa-lock" ></i>
                        <input type="password" name="password" ref={(val) => this.password = val} class="form-control" id="ss-sad-login-013" placeholder="Password" required />
                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                      </div>
                      <div class="row">
                        <button type="submit" id="ss-sad-login-014" class="btn btn-block">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
        {/* <body class="hold-transition login-page">
          <div class="login-box">
            <div class="card card-outline card-primary" style={{ borderColor: "#242424" }}>
              <div class="card-header text-center">
                <div class="" style={{ fontSize: "2rem" }}><b>Login</b></div>
              </div>
              <div class="card-body">
                <p class="login-box-msg">Sign in to start your session</p>
                <form onSubmit={this.insertUser}>
                  <div class="input-group mb-3">
                    <input type="text" name="user_name" ref={(val) => this.user_name = val} class="form-control" placeholder="User Name" required />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input type="password" name="password" ref={(val) => this.password = val} class="form-control" placeholder="Password" required />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <button type="submit" class="btn btn-block" style={{ backgroundColor: "#84B741", fontWeight: "800" }} >Sign In</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="justify-content-left bottom-nav" id="nui-page-footer">
            <div id="nui-copyright">2021 Copyrights @ <a href="https://www.theexponent.co.in/" target="_blank"> Exponent Innovation</a> | All Rights Reserved | Privacy Policy 
            </div>
            <div id="nui-copyright" style={{ right: "0px", paddingRight: "2vh" }}>Powered by  <a href="https://www.theexponent.co.in/" target="_blank"> The Exponent</a></div>
          </div>
        </body> */}

        {/* <Modal isOpen={this.state.modelIsOpenForResponse} className="modal-content" onRequestClose={() =>this.setState({ Redirectstate: true })}>
        <span class="closed" onClick={() => this.setState({ Redirectstate: true })}>&times;</span>
        <h4>{this.state.response}</h4>
        <button className="btn btn-primary col-sm-2 mr-2 mb-2 popup-ok-btn" onClick={() =>this.setState({ Redirectstate: true })}><i class="fas fa-thumbs-up" style={{ fontSize: "29px" }}></i></button>
      </Modal> */}
        {/* <body >
          <div style={{backgroundImage:"url('../../uploads/logo-black.png')"}} id="ss-sad-login-001"></div>
            <div className="container-fluid">
              <div className="row" id="ss-sad-login-002">
                <div className="col-7" id="ss-sad-login-003">
                 <img  src={"../../uploads/banner1.png"} className="ss-sad-login-004" />
                  <img  src={"../../uploads/banner2.png"} className="ss-sad-login-004" />
                     <div id="ss-sad-login-005" style={{backgroundImage:"url('../../uploads/logo-with-text-white.png')"}}></div>
                     </div>
                       <div id="ss-sad-login-006" class="col-5 login-box" >
                                  <div id="ss-sad-login-007" class="card" >
                                      <div id="ss-sad-login-008" class="card-header text-left"></div>
                                        <div class="ss-sad-login-009"><b>Login</b></div>
                                        </div>
                                            <div id="ss-sad-login-010" class="card-body">
                                              <form onSubmit={this.insertUser}>
                                                <div class="input-group mb-3">
                                                <i id="ss-sad-login-011" class="fa fa-user" ></i>
                                                  <input type="text" name="user_name" ref={(val) => this.user_name = val} class="form-control" id="ss-sad-login-013" placeholder="User Name" required />
                                                </div>  
                                                  <div class="input-group mb-3">
                                                  <i id="ss-sad-login-012" class="fa fa-lock" ></i>
                                                    <input type="password" name="password" ref={(val) => this.password = val} class="form-control" id="ss-sad-login-013" placeholder="Password" required />
                                                    </div>
                                                  <div class="row">
                                                    <button type="submit" id="ss-sad-login-014" class="btn btn-block"  >Sign In</button>
                                                  </div>
                                               </form>
                                             </div>
                                          </div>
                                        </div>
          
                                           
                                      </div>     
  
        </body> */}
      </>
    );
  }
}